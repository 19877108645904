import React, { useCallback, useEffect, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { Memoize } from 'components/widgets';
import { Layers } from 'environment';
import { DrawerContext } from 'hooks';

import { Aside, Overlay } from './Drawer.style';

export enum DrawerIds {
	LiveFilters = 'LIVE_METRIC_FILTERS',
	NavigationMenu = 'NAVIGATION_MENU_DRAWER',
	NotificationsCenter = 'NOTIFICATIONS_CENTER',
	DevicesFilters = 'DEVICES_FILTERS'
}

interface Props {
	children: React.ReactNode;
	component: React.ReactElement;
	id: string;
	left?: boolean;
	zIndex?: number;
}

export function Drawer({ children, component, id, left, zIndex = Layers.drawer }: Props) {
	const [rendered, setRendered] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!open) {
			const id = setTimeout(() => {
				setRendered(false);
			}, 300);

			return () => clearTimeout(id);
		}
	}, [open]);

	useEffect(() => {
		if (rendered) setOpen(true);
	}, [rendered]);

	const closeDrawer = useCallback(
		(getId: (ids: typeof DrawerIds) => string) => {
			const cid = getId(DrawerIds);
			if (cid === id && rendered) setOpen(false);
		},
		[rendered]
	);

	const openDrawer = useCallback(
		(getId: (ids: typeof DrawerIds) => string) => {
			const oid = getId(DrawerIds);
			if (oid === id && !rendered) setRendered(true);
		},
		[rendered]
	);

	const toggleDrawer = useCallback(
		(getId: (ids: typeof DrawerIds) => string) => {
			const tid = getId(DrawerIds);
			if (tid === id) rendered ? closeDrawer(() => tid) : openDrawer(() => tid);
		},
		[rendered]
	);

	function handleOverlayClick() {
		setOpen(false);
	}

	const x = left ? (open ? 0 : -100) : open ? 0 : 100;
	return (
		<DrawerContext.Provider value={{ open, closeDrawer, openDrawer, toggleDrawer }}>
			<Memoize>{children}</Memoize>
			{rendered && (
				<RemoveScroll>
					<Overlay style={{ zIndex }} onClick={handleOverlayClick}>
						<Aside
							style={{
								transform: `translate3d(${x}%, 0px, 0px)`,
								transition: open
									? `transform 0.3s ease-in-out`
									: `transform 0.2s ease-in-out`
							}}
							onClick={e => e.stopPropagation()}
						>
							{component}
						</Aside>
					</Overlay>
				</RemoveScroll>
			)}
		</DrawerContext.Provider>
	);
}

Drawer.Ids = DrawerIds;
