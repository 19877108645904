import React, { useEffect, useState, useCallback } from 'react';

import { Layout } from 'components/displayers';
import { TypeaheadToggle } from 'components/inputs/Typeahead/TypeaheadToggle';
import { Colors } from 'environment';
import { truncateText } from 'helpers';
import { Nullable } from 'types';

import { BaseDropdown, BaseDropdownProps, CommonDropdownProps } from './BaseDropdown';
import { DropdownEmpty } from './DropdownEmpty';
import { DropdownItem } from './DropdownItem';

import { useDropdown } from './useDropdown';
import { Section } from './style';

type SearchSuggestion = string;

interface Props<T> extends BaseDropdownProps<T>, CommonDropdownProps {
  defaultSelectedItem?: Nullable<T>;
  minimal?: boolean;
  name: string;
  suggestions: SearchSuggestion[];
  title: (data: Nullable<T>) => string;
  value: string;
  invalid?: boolean;
  error?: string;
  onKeyDown: (e: React.InputHTMLAttributes<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onOpen?: () => void;
  onClearClick: () => void;
  onInputClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onOutsideClick?: () => void;
  placeholder: string;
  selectDeps?: React.DependencyList;
}

export function SearchDropdown<T>({
  defaultSelectedItem = null,
  disabled = false,
  margin,
  minimal,
  name,
  open = false,
  suggestions,
  title,
  value,
  invalid,
  selected,
  error,
  width,
  onChange,
  onClearClick,
  onInputClick,
  onOutsideClick,
  onSelect,
  onOpen,
  placeholder,
  selectDeps = [],
  ...props
}: Props<T>) {
  const { bounds, isOpen, ref, handleOpenChange, handleToggle } = useDropdown({ disabled, open });
  const [items, setItems] = useState(suggestions);
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

  useEffect(() => {
    setItems(suggestions.filter((d) => d.toLowerCase().includes(value.toLowerCase())));
  }, [suggestions, value]);

  const handleSelect = useCallback((data: T) => {
    setSelectedItem(data);
    onSelect?.(data);
  }, selectDeps);

  useEffect(() => {
    if (isOpen) onOpen && onOpen();
  }, [isOpen]);

  function handleInputClick(e: React.MouseEvent<HTMLInputElement>) {
    onInputClick && onInputClick(e);
    handleToggle();
  }

  function handleClearClick() {
    onClearClick();
    setSelectedItem(null);
    if (isOpen) handleToggle();
  }

  const labelId = `${name}-label`;

  return (
    <Layout.Column className={name} margin={margin} width={width}>
      <BaseDropdown
        {...props}
        error={error}
        bounds={bounds}
        open={isOpen}
        toggleComponent={
          <TypeaheadToggle
            {...props}
            style={{
              minHeight: '4.8rem',
              backgroundColor: 'white',
              borderRadius: '1.1rem',
            }}
            autoComplete="off"
            clearable
            name={name}
            labelId={labelId}
            disabled={disabled}
            placeholder={placeholder}
            toggleRef={ref}
            value={value}
            onChange={onChange}
            onClearClick={handleClearClick}
            onClick={handleInputClick}
          />
        }
        toggleRef={ref}
        selected={(d) => selectedItem === d}
        width={width}
        search
        onOpenChange={handleOpenChange}
        onOutsideClick={handleToggle}
        onSelect={handleSelect}
      >
        {items.length === 0 ? (
          <DropdownEmpty />
        ) : (
          items.map((suggestion, idx) => (
            <DropdownItem
              style={{ borderBottom: 'unset' }}
              fontStyle={{
                fontSize: 1.8,
                lineHeight: 3.6,
                color: Colors.darkest,
                background: Colors.white,
              }}
              data={suggestion}
              key={`${suggestion}-${idx}`}
            >
              {truncateText(suggestion, 35)}
            </DropdownItem>
          ))
        )}
      </BaseDropdown>
    </Layout.Column>
  );
}

SearchDropdown.Empty = DropdownEmpty;
SearchDropdown.Item = DropdownItem;
SearchDropdown.Section = Section;
