import { AdminBiim, AdminBiimInput, AdminBiims, EventBiim, EventStatus, Ratings } from '../../api';
import { AuthRequestConfig } from '../../generics';
import { axios, buildUrl, serializeInput } from '../../utils';

import { Biims } from './types';

const endpoints = {
	event: (eventId: string, refresh = false) => `/event/${eventId}?refresh=${refresh}`,
	events: () => `/event`,
	eventsAdmin: () => `/admin/event`,
	eventsAdminById: (id: number) => `/admin/event/${id}`,
	eventRating: (eventId: string) => `/event-rating/${eventId}`,
};

export default (token?: AuthRequestConfig) => ({
  getBiims: async function () {
    const url = buildUrl(endpoints.events());
    const { data } = await axios.get<Biims>(url);
    return data;
  },
  getBiimsAsAdmin: async function () {
    const url = buildUrl(endpoints.eventsAdmin());
    const accessToken = token ? token.accessToken : '';
    const { data } = await axios.get<AdminBiims>(url, { token: accessToken });
    return data;
  },
  updateBiimAsAdmin: async function (id: number, biimInput: AdminBiimInput) {
    const url = buildUrl(endpoints.eventsAdminById(id));
    const params = serializeInput(biimInput);
    const accessToken = token ? token.accessToken : '';
    const { data } = await axios.put<AdminBiim>(url, params, { token: accessToken });
    return data;
  },
  getEvent: async function (eventId: string, refresh?: boolean) {
    const url = buildUrl(endpoints.event(eventId, refresh));
    const { data } = await axios.get<EventBiim>(url);
    return data;
  },
  updateStatusEvent: async function (eventId: string, status: EventStatus) {
    const url = buildUrl(endpoints.event(eventId));
    const params = serializeInput({ status });
    const { data } = await axios.put<EventBiim>(url, params);
    return data;
  },
  postRatingEvent: async function (eventId: string, rating: Ratings) {
    const url = buildUrl(endpoints.eventRating(eventId));
    const params = serializeInput({ rating });
    const { data } = await axios.post<Ratings>(url, params);
    return data;
  },
});
