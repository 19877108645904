import styled from 'styled-components/macro';
import { MediaQueries } from 'environment';
import { Icon as BaseIcon } from 'components/icons';
import { PlacementProps } from 'types';

interface BaseProps {
  icon?: boolean;
  inactive?: boolean;
}

export interface StyleProps {
  margin?: PlacementProps;
  narrow?: boolean;
  width?: number | string;
  compact?: boolean;
}

export interface VariantButtonProps {
  color?: string;
  backgroundColor?: string;
  badgeColor?: string;
  boxShadow?: string;
  hoverColor?: string;
}

export interface TextStyleProps {
  fontSize?: number;
  fontWeight?: string | number;
}

type ButtonProps = BaseProps & StyleProps & VariantButtonProps & TextStyleProps;

export const Button = styled.button<ButtonProps>`
  border-radius: 1.1rem;
  background: ${({ backgroundColor }) => backgroundColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border: ${({ backgroundColor }) => `0.1rem solid ${backgroundColor}` ?? 'unset'};
  color: ${({ color }) => color};
  height: ${({ compact }) => (compact ? '4rem' : '5.1rem')};
  min-height: ${({ compact }) => (compact ? '4rem' : '5.1rem')};
  min-width: ${({ narrow = false }) => (narrow ? '19.2rem' : '23.2rem')};
  margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
  margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
  margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
  margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
  outline: none;
  padding: 0 2.4rem;
  padding-left: ${({ icon }) => icon && '2rem'};
  position: relative;
  pointer-events: ${({ inactive }) => (inactive ? 'none' : 'auto')};
  transition: 0.2s;
  width: ${({ width }) =>
    width !== undefined ? (typeof width === 'string' ? width : `${width}rem`) : 'auto'};

  :disabled {
    background-color: black;
    border-color: black;
    box-shadow: none;
  }

  :not(:disabled) {
    cursor: pointer;

    :focus,
    :hover {
      background-color: ${({ hoverColor }) => hoverColor};
      border-color: ${({ hoverColor }) => hoverColor};
    }
  }

  span {
    justify-content: ${({ icon }) => !icon && 'center'};
  }

  @media ${MediaQueries.phone} {
    min-width: auto;
  }
`;

export const BaseIconButton = styled.button<ButtonProps>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: ${({ backgroundColor }) => `0.1rem solid ${backgroundColor}`};
  border-radius: 3rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: ${({ boxShadow }) => boxShadow};
  color: ${({ color }) => color};
  margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
  margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
  margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
  margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
  height: 4.8rem;
  min-height: 4.8rem;
  min-width: 5rem;
  outline: none;
  pointer-events: auto;
  text-transform: uppercase;
  transition: 0.2s;

  :not(:disabled) {
    cursor: pointer;

    :focus,
    :hover {
      background-color: ${({ hoverColor }) => hoverColor};
      border-color: ${({ hoverColor }) => hoverColor};
    }
  }
`;

export const Content = styled.span<TextStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}rem` : '1.8rem')};
  font-weight: ${({ fontWeight }) => fontWeight ?? 'inherit'};
  line-height: 5px;
  font-family: Comfortaa;
  letter-spacing: 1.404px;

  @media ${MediaQueries.phone} {
    font-size: clamp(1.4rem, 3vw, 1.8rem);
  }
`;

export const Icon = styled(BaseIcon)`
  margin-right: 1.6rem;
`;

export const Badge = styled.span<VariantButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ badgeColor }) => badgeColor};
  border-radius: 50%;
  color: ${({ backgroundColor }) => backgroundColor};
  font-size: 1.6rem;
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  top: -0.6rem;
  right: -0.6rem;
`;

export const Floating = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  cursor: default;
`;
