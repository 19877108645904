import Bowser from 'bowser';
import styled from 'styled-components';
import { MediaQueries } from '../../../../environment';
const browser = Bowser.getParser(window.navigator.userAgent);

const NoPermissionMarquee = () => {
  return (
    <>
      {location.pathname.split('/')[2] !== 'recording' && (
        <NoPermissionBlock>
          BiiM requires access to your camera and microphone.
          {browser.getBrowserName() !== 'Safari' && (
            <span style={{ fontSize: '15px', color: 'white' }}>
              Click the camera blocked icon
              <img
                alt="icon"
                src={
                  'https://www.gstatic.com/meet/ic_blocked_camera_dark_f401bc8ec538ede48315b75286c1511b.svg'
                }
                style={{ display: 'inline' }}
              />
              in your browsers address bar.
            </span>
          )}
          {browser.getBrowserName() === 'Safari' && (
            <span style={{ fontSize: '15px', color: 'white', marginLeft: '5px' }}>
              <a
                rel="noreferrer"
                href="https://support.apple.com/en-gb/guide/safari/ibrwe2159f50/15.1/mac/12.0"
                target="_blank"
              >
                More Info
              </a>
            </span>
          )}
        </NoPermissionBlock>
      )}
    </>
  );
};

export default NoPermissionMarquee;

export const NoPermissionBlock = styled.div`
  font-size: 16px;
  color: #ffffff;
  font-family: Comfortaa;
  max-width: 100%;
  margin: 70px 10px 0 10px;
  background-color: rgb(0 0 0 / 63%);
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  z-index: 900;
  img {
    padding: 0 5px;
  }
  span {
    display: block;
    margin-top: 5px;
  }
  @media ${MediaQueries.phone} {
  }
`;
