import React from 'react';
import { EventStatus, EventType } from '../../../biim-api';
import { useState } from 'react';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { ContentLoader } from '../../../components/loaders';
import { Alerts } from '../../../components/layout';

import { getMeetingFromDB } from '../../../utils/api';

import { Container } from '../commonStyles.style';

import {
  useEffectOnce,
  useEventContext,
  useEventStatusContext,
  useNavbarColorContext,
} from '../../../hooks';

import { DevicePermissionPrompt } from '../../../components/widgets/Device';
import { useLocation } from 'react-router';

export function GuestDeviceSetup() {
  const [eventLoading, setLoading] = useState(false);
  const location = useLocation();
  const { eventData: data } = useEventContext();
  const [meetingId] = useState(data.uuidEvent);
  const meetingManager = useMeetingManager();
  const { type, switchColor } = useNavbarColorContext();
  const { setStatus } = useEventStatusContext();

  useEffectOnce(() => {
    if (type === 'dark') switchColor('colored');
  });

  const clickedJoinMeeting = async () => {
    setLoading(true);
    const title = meetingId.trim();

    // Fetch the Meeting via AWS AppSync - if it exists, then the meeting has already
    // been created, and you just need to join it - you don't need to create a new meeting
    const meetingResponse: any = await getMeetingFromDB(title);
    const meetingJson = meetingResponse.data.getMeeting;

    if (meetingJson) {
      try {
        await meetingManager.start();
        setLoading(false);

        if (data.eventType === EventType.Live || data.eventType === EventType.LiveMeet) {
          if (data.status === EventStatus.MeetInProgress) {
            setStatus(EventStatus.MeetInProgress);
          } else setStatus(EventStatus.LiveInProgress);
        }

        if (data.eventType === EventType.Meet) {
          setStatus(EventStatus.MeetInProgress);
        }
      } catch (error) {
        setLoading(false);
        Alerts.error(
          'There is a problem with the live! Please try again later or contact administrators!'
        );
        console.log(error);
      }
    } else {
      setLoading(false);
      Alerts.info('Meeting not started! Please try again in a few minutes!');
    }
  };

  return (
    <ContentLoader loading={eventLoading}>
      <Container>
        {location.pathname.split('/')[2] !== 'recording' ? (
          <DevicePermissionPrompt
            open={true}
            closePreview={clickedJoinMeeting}
            onSubmit={clickedJoinMeeting}
            type={data.userType}
          />
        ) : (
          <>
            {data.eventType === EventType.Meet
              ? setStatus(EventStatus.MeetInProgress)
              : setStatus(EventStatus.LiveInProgress)}
          </>
        )}
      </Container>
    </ContentLoader>
  );
}
