import { useCallback } from 'react';

import { useDispatch } from 'hooks';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils';

import { ActionType, updateBiimEventUuid } from 'store/events';

export function useUpdateLiveBiimEventUuid(): OperationResult<
	null,
	(idEvent: number, newUuid: string) => void
> {
	const dispatch = useDispatch();
	const [{ loading, error }] = useActivity(ActionType.UPDATE_LIVE_BIIM_EVENT_UUID);

	const handler = useCallback(
		(idEvent: number, newUuid: string) => {
			dispatch(updateBiimEventUuid(idEvent, newUuid));
		},
		[dispatch]
	);

	return [{ loading, error, data: null }, handler];
}
