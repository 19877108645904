import styled from 'styled-components';

import { Icon } from 'components/icons';
import { Images } from 'environment';

import { Image } from './Guest.styles';

import { EndTitle } from '../commonStyles.style';

interface Props {
	title?: string;
}

export function GuestTransitEvent({ title } : Props) {
	return (
		<Container>
			<Image src={Images.FinishBg} />
			<Content>
				<EndTitle style={{ textAlign: 'center' }}>
					{title}
				</EndTitle>
				<Icon type={(t) => t.Logo} color="undefined" size={12} />
			</Content>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	padding: clamp(1rem, 7vw, 20rem);
`;

const Content = styled.div`
	flex: 1;
	display: flex;
	z-index: 1;
	justify-content: center;
	align-self: center;
	height: 100%;
	flex-direction: column;
	align-items: center;
`;
