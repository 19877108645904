import { Colors, Layers } from 'environment';
import styled from 'styled-components/macro';

interface Props {
  bounds?: ClientRect;
  width?: number | string;
}

export const SearchMenu = styled.div<Props>`
  background-color: ${Colors.white};
  border-bottom-left-radius: 1.1rem;
  border-bottom-right-radius: 1.1rem;
  overflow-x: hidden;
  position: absolute;
  top: 39px !important;
  width: ${({ width }) =>
    width !== undefined ? (typeof width === 'string' ? width : `${width}rem`) : 'auto'};
  z-index: ${Layers.floater};

  .horizontal-track {
    display: none;
  }

  [role='option'] {
    margin: 1rem;
    width: calc(100% - 2rem);
    border-radius: 0.4rem;
    transform: none;
    padding: 0.25rem;

    :hover,
    :focus {
      background: ${Colors.gray};
    }

    span {
      color: ${Colors.darkest};
      text-align: left;
      display: block;
    }
  }
`;
