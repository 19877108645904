import { EventStatus, factory } from 'biim-api';
import { DeviceLabels, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';

import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useAppState } from 'app';
import { CountdownToEvent } from 'components/displayers';
import { Alerts } from 'components/layout';
import { ContentLoader } from 'components/loaders';

import { SecondaryButton } from 'components/widgets';
import { userColorName } from 'consts';
import { formatDate } from 'helpers';
import {
  useNavbarColorContext,
  useEffectOnce,
  useEventContext,
  useEventStatusContext,
} from 'hooks';
import {
  addAttendeeToDB,
  addMeetingToDB,
  createMeeting,
  getMeetingFromDB,
  joinMeeting,
} from 'utils/api';

import {
  Subtitle,
  CreatorName,
  DateContainer,
  DateTitle,
  Image,
  BiimContainer,
  BiimTitle,
  Block,
  Container,
  CountdownContainer,
  Heading,
} from '../creator/Creator.styles';
import {
  Content,
  Logo,
  LogoContainer,
  BackgroundGradient,
  BackgroundGradientLeft,
  BackgroundGradientRight,
  Button,
} from '../commonStyles.style';

export function ModeratorBeforeEvent() {
  const [eventLoading, setEventLoading] = useState(false);

  const { eventData: data } = useEventContext();
  const [meetingId] = useState(data.uuidEvent);
  const [attendeeName] = useState(data.creatorName ?? data.userType);

  const api = factory();

  const { setStatus } = useEventStatusContext();
  const { setLocalUser } = useAppState();
  const { pathname } = useLocation();
  const meetingManager = useMeetingManager();
  const { type, switchColor } = useNavbarColorContext();

  const uuid = pathname.split('/')[1];

  useEffectOnce(() => {
    if (type === 'dark') switchColor('colored');
  });

  const clickedJoinMeeting = async () => {
    setEventLoading(true);
    const title = meetingId.trim();
    const name = `${attendeeName}#${data.userType}`;
    setLocalUser({ name, color: userColorName });

    // Fetch the Meeting via AWS AppSync - if it exists, then the meeting has already
    // been created, and you just need to join it - you don't need to create a new meeting
    const meetingResponse: any = await getMeetingFromDB(title);
    const meetingJson = meetingResponse.data.getMeeting;
    const options = {
      deviceLabels: DeviceLabels.AudioAndVideo,
    };

    try {
      if (meetingJson) {
        const meetingData = JSON.parse(meetingJson.data);
        const joinInfo = await joinMeeting(meetingData.MeetingId, name);
        await addAttendeeToDB(joinInfo.Attendee.AttendeeId, name);

        const configuration = new MeetingSessionConfiguration(
          {
            MeetingId: meetingData.MeetingId,
            MediaPlacement: meetingData.MediaPlacement,
          },
          {
            ExternalUserId: joinInfo.Attendee.ExternalUserId,
            AttendeeId: joinInfo.Attendee.AttendeeId,
            JoinToken: joinInfo.Attendee.JoinToken,
          }
        );

        await meetingManager.join(configuration, options);
      } else {
        // const region = await getNearestMediaRegion();
        const joinInfo = await createMeeting(title, name, 'eu-west-2');
        await addMeetingToDB(title, joinInfo.Meeting.MeetingId, JSON.stringify(joinInfo.Meeting));
        await addAttendeeToDB(joinInfo.Attendee.AttendeeId, name);

        const configuration = new MeetingSessionConfiguration(
          {
            MeetingId: joinInfo.Meeting.MeetingId,
            MediaPlacement: joinInfo.Meeting.MediaPlacement,
          },
          {
            ExternalUserId: joinInfo.Attendee.ExternalUserId,
            AttendeeId: joinInfo.Attendee.AttendeeId,
            JoinToken: joinInfo.Attendee.JoinToken,
          }
        );

        await meetingManager.join(configuration, options);
      }
    } catch (error) {
      console.log({ error });
      setEventLoading(false);
      setStatus(EventStatus.Finished);
      return await api.event().updateStatusEvent(uuid, EventStatus.Finished);
    }

    // At this point you can let users setup their devices, or start the session immediately
    setStatus(EventStatus.DeviceSetup);
    // await meetingManager.start();
    setEventLoading(false);
  };

  async function handleCancelBiim() {
    await api.event().updateStatusEvent(uuid, EventStatus.Cancelled);
    Alerts.info(`biim ${data.title} was cancelled!`);
  }

  async function handleFinishBiim() {
    await api.event().updateStatusEvent(uuid, EventStatus.Finished);
    Alerts.info(`biim ${data.title} was cancelled!`);
  }

  const { DO_MMMM_YYYY_12H, EUROPE_LONDON_DATE } = formatDate(data.availabilityDate);

  return (
    <ContentLoader loading={eventLoading}>
      <Container>
        <BackgroundGradientLeft />
        <BackgroundGradientRight />
        <BackgroundGradient style={{ left: 0 }} />
        <Content style={{ display: 'contents' }}>
          <Block align={{ baseline: true }}>
            <LogoContainer>
              <Logo color="undefined" type={(t) => t.Logo} size={30} />
            </LogoContainer>
            <Subtitle>presents</Subtitle>
            <CreatorName>{data.creatorName}</CreatorName>
            <BiimContainer>
              <BiimTitle>{data.title}</BiimTitle>
            </BiimContainer>
            <DateContainer>
              <DateTitle>{DO_MMMM_YYYY_12H}</DateTitle>
            </DateContainer>
            <Heading>*Close all other conferencing tools before using BIIM</Heading>
          </Block>
          <Block align={{ end: true }}>
            <CountdownContainer>
              <CountdownToEvent date={EUROPE_LONDON_DATE} />
            </CountdownContainer>
            <Button margin={{ bottom: 1.6 }} onClick={clickedJoinMeeting}>
              {data.status === EventStatus.LiveInProgress ||
              data.status === EventStatus.MeetInProgress
                ? 'Join biim'
                : 'Start biim'}
            </Button>
            <SButton
              color="rgba(255,255,255,0.4)"
              margin={{ bottom: 1.6 }}
              icon={(t) => t.GrayMail}
              iconSize={2}
              onClick={() => {
                window.open('mailto:help@biim.tv');
              }}
            >
              Contact support
            </SButton>
            {data.status === EventStatus.LiveInProgress ||
            data.status === EventStatus.MeetInProgress ? (
              <SButton
                color="rgba(255,255,255,0.4)"
                icon={(t) => t.Clear}
                iconSize={2}
                onClick={handleFinishBiim}
              >
                Finish biim
              </SButton>
            ) : (
              <SButton
                color="rgba(255,255,255,0.4)"
                icon={(t) => t.Clear}
                iconSize={2}
                onClick={handleCancelBiim}
              >
                Cancel biim
              </SButton>
            )}
          </Block>
          <Image src={data.images[0].src} />
        </Content>
      </Container>
    </ContentLoader>
  );
}

const SButton = styled(SecondaryButton)`
  width: clamp(28rem, 74vw, 37.5rem);
`;
