import { Colors } from 'environment';
import { useParams } from 'react-router';
import styled from 'styled-components';

export function PastBiimPage() {
  const { uuid } = useParams();

  return (
    <Container>
      <video controls>
        <source src={`${process.env.REACT_APP_CLOUDFRONT_URL}/${uuid}.mp4`} type="video/mp4" />
        Sorry, your browser does not support embedded videos.
      </video>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  width: 100%;
  background: ${Colors.darkest};
  justify-content: center;
  overflow-x: hidden;
  margin: 3rem auto;
  text-align: center;
  padding: 0 30px;
  video {
    width: 100%;
    max-width: 1280px;
    height: auto;
  }
`;
