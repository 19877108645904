import { AdminBiim, AdminBiims, Biims, EventBiim, EventStatus, Ratings } from 'biim-api';
import { Action } from 'redux';

export interface State {
	biims: Biims;
	adminBiims: AdminBiims;
	live: { biim: EventBiim; attendeeId: string };
}

export enum ActionType {
  GET_BIIMS = 'event/biims/GET_BIIMS',
  GET_ADMIN_BIIMS = 'event/biims/GET_ADMIN_BIIMS',
  UPDATE_BIIM = 'event/biims/UPDATE_BIIM',
  UPDATE_BIIM_STATUS = 'event/biims/UPDATE_BIIM_STATUS',
  GET_LIVE_BIIM = 'event/live/GET_LIVE_BIIM',
  UPDATE_LIVE_BIIM_EVENT_UUID = 'event/live/GET_LIVE_BIIM_EVENT_UUID',
  UPDATE_LIVE_BIIM_STATUS = 'event/live/UPDATE_LIVE_BIIM_STATUS',
  UPDATE_LIVE_BIIM_ATTENDEE_ID = 'event/live/UPDATE_LIVE_BIIM_ATTENDEE_ID',
  POST_RATING_BIIM = 'event/biims/POST_RATING_BIIM',
}

export interface GetBiimsAction extends Action {
	type: ActionType.GET_BIIMS;
	payload: { biims: Biims };
}

export interface GetAdminBiimsAction extends Action {
	type: ActionType.GET_ADMIN_BIIMS;
	payload: { biims: AdminBiims };
}

export interface UpdateBiimAction extends Action {
	type: ActionType.UPDATE_BIIM;
	payload: { biim: AdminBiim };
}

export interface UpdateBiimStatusAction extends Action {
	type: ActionType.UPDATE_BIIM_STATUS;
	payload: { uuidEvent: string; status: EventStatus };
}

export interface UpdateLiveBiimStatusAction extends Action {
	type: ActionType.UPDATE_LIVE_BIIM_STATUS;
	payload: { status: EventStatus };
}

export interface GetLiveBiimAction extends Action {
	type: ActionType.GET_LIVE_BIIM;
	payload: { data: EventBiim };
}

export interface UpdateLiveBiimEventUuidAction extends Action {
	type: ActionType.UPDATE_LIVE_BIIM_EVENT_UUID;
	payload: { idEvent: number; newUuid: string };
}

export interface UpdateLiveBiimAttendeeIdAction extends Action {
	type: ActionType.UPDATE_LIVE_BIIM_ATTENDEE_ID;
	payload: { attendeeId: string };
}

export interface PostEndBiimRatingAction extends Action {
  type: ActionType.POST_RATING_BIIM;
  payload: { eventId: string; rating: Ratings };
}

export type Actions =
  | GetBiimsAction
  | GetAdminBiimsAction
  | UpdateBiimAction
  | UpdateBiimStatusAction
  | GetLiveBiimAction
  | UpdateLiveBiimEventUuidAction
  | UpdateLiveBiimStatusAction
  | UpdateLiveBiimAttendeeIdAction
  | PostEndBiimRatingAction;
