import { useCallback } from 'react';

import { useDispatch, useSelector } from 'hooks';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils';

import { ActionType, selectAttendeeId, updateBiimAttendeeId } from 'store/events';

export function useUpdateLiveBiimAttendeeId(): OperationResult<
	{ attendeeId: string },
	(attendeeId: string) => void
> {
	const dispatch = useDispatch();
	const [{ loading, error }] = useActivity(ActionType.UPDATE_LIVE_BIIM_EVENT_UUID);

	const data = useSelector((state) => selectAttendeeId(state.event));

	const handler = useCallback(
		(attendeeId: string) => {
			dispatch(updateBiimAttendeeId(attendeeId));
		},
		[dispatch]
	);

	return [{ loading, error, data: { attendeeId: data } }, handler];
}
