import styled from 'styled-components';
import { Colors, MediaQueries } from 'environment';

interface Props {
	small?: boolean;
}

export const Container = styled.div<Props>`
	color: ${Colors.white};
	width: ${({ small }) => (small ? '11.7rem' : '32.5rem')};
	height: ${({ small }) => (small ? '7rem' : '17rem')};
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	
	@media ${MediaQueries.phoneMax}{
		height: inherit;
        padding-top: 1rem;
	}
`;

export const EventInProgress = styled.h4`
	color: ${Colors.white};
	margin-bottom: 2rem;
`;

export const Title = styled.p<Props>`
	height: ${({ small }) => (small ? '3.4rem' : '6rem')};
	text-align: center;
	padding-top: 1rem;
	font-family: Comfortaa;
	font-style: normal;
	font-weight: normal;
	font-size: ${({ small }) => (small ? '10px' : '25px')};
	line-height: 14px;
	letter-spacing: 2.73px;
	color: ${Colors.lightBlue};
	
	@media ${MediaQueries.phoneMax}{
		 height: inherit;
          padding-bottom: 1.5rem;
        }
`;

export const Timer = styled.h1<Props>`
	height: ${({ small }) => (small ? undefined : '4rem')};
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-family: Comfortaa;
	font-style: normal;
	font-weight: normal;
	font-size: ${({ small }) => (small ? '24px' : '76px')};
	line-height: 14px;
	/* or 18% */

	letter-spacing: 2.73px;
	color: ${Colors.white};

	p {
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: ${({ small }) => (small ? '24px' : '76px')};
		line-height: 14px;
		/* or 18% */

		letter-spacing: 2.73px;
		color: ${Colors.pink};
	}
`;

export const DayContainer = styled.div<Props>`
	display: flex;
	justify-content: space-between;
	padding-left: 1rem;
	padding-top: ${({ small }) => (small ? undefined : '1rem')};
	padding-right: 1rem;
	
	@media ${MediaQueries.tabletMax}{
		margin: 0 auto;
        max-width: 300px;
	}
	@media ${MediaQueries.phoneMax}{
		margin: 0 auto;
        max-width: 300px;
}
`;

export const Day = styled.h4<Props>`
	opacity: 0.4;
	text-transform: uppercase;
	font-size: ${({ small }) => (small ? '10px' : '25px')};
`;

export const QueueContainer = styled.div`
	height: 10rem;
	width: 27rem;
	display: flex;
	align-items: center;
	padding-top: 2rem;
	justify-content: space-between;
`;

export const QueueBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const QueueBoxText = styled.span`
	padding: 1.5rem 1rem;
	background: black;
	font-family: Comfortaa;
	font-style: normal;
	font-weight: normal;
	color: white;
	font-size: 2rem;
	text-align: center;
`;

export const QueueBoxLabel = styled.span`
	color: white;
	font-family: Comfortaa;
	font-style: normal;
	font-weight: normal;
	margin-top: 1rem;
	font-size: 1.3rem;
`;
