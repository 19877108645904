// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import {
  Heading,
  PreviewVideo,
  QualitySelection,
  CameraSelection,
  Label,
} from 'amazon-chime-sdk-component-library-react';

import { title, StyledInputGroup } from '../DeviceSelection.styled';
// import Webcam from 'react-webcam';

const CameraDevices =  () => {

  return (
    <div>
      <Heading tag="h2" level={6} css={title}>
        Video
      </Heading>
      <StyledInputGroup>
        <CameraSelection />
      </StyledInputGroup>
      <StyledInputGroup>
        <QualitySelection />
      </StyledInputGroup>
      <Label style={{ display: 'block', marginBottom: '.5rem' }}>Video preview</Label>
      {/* <Webcam style={{ height: '100%', width: '70%'}}/> */}
      <PreviewVideo/>
    </div>
  );
};

export default CameraDevices;
