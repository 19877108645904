import styled, { css } from 'styled-components/macro';

interface Props {
	size: number;
}

export const Container = styled.div<Props>`
	display: flex;
	align-items: center;
	height: 4rem;
	width: 4rem;
	padding: 0 0.5rem;
	position: relative;

	${({ size }) => css`
		::after {
			content: '';
			background-color: white;
			border-radius: 0.1rem;
			height: 35%;
			pointer-events: none;
			position: absolute;
			width: ${1.5 * size}rem;
		}
	`}
`;

export const Box = styled.div`
	background-color: white;
	border: 0.3rem solid black;
	border-left: none;
	height: 30%;
	width: 50%;
`;
