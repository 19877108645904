import Countdown from 'react-countdown';
import moment from 'moment-timezone';
import { addLeadingZeros } from 'helpers';
import { TIME_ZONE, EUROPE_LONDON } from 'consts';
import { RunTime, RunTimeAdmin, RunTimeOver, RunTimeOverAdmin } from './eventRuntime.style';
import { useNavbarUIContext } from 'hooks';
import { useEffect } from 'react';

interface Props {
  hours: any;
  minutes?: any;
  seconds?: any;
  completed?: boolean;
}

interface RuntimeProps {
  runtime: any;
}

export const EventRuntime = ({ runtime }: RuntimeProps) => {
  const { userType } = useNavbarUIContext();
 // const Completionist = () => <RunTimeOver style={{ color: 'white' }}>Sheduled time is over</RunTimeOver>;
  const Completionist = () =><> {userType === 'guest' ? (
      <RunTimeOver style={{ color: 'white' }}>Sheduled time is over</RunTimeOver>
  ) : (
      <RunTimeOverAdmin style={{ color: 'white' }}>Sheduled time is over</RunTimeOverAdmin>
  )}</>;

  const europeNow = () => {
    const TimeZone = moment.tz(new Date(), EUROPE_LONDON).format('z');
    return TimeZone === TIME_ZONE.BST
      ? moment.tz(new Date(), EUROPE_LONDON).valueOf()
      : moment.tz(new Date(), TIME_ZONE.GMT).valueOf();
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }: Props) => {
    // console.dir( data);
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {

     // Render a countdown
      return (
        <>
          {userType === 'guest' ? (
            <RunTime>
              {addLeadingZeros(String(hours))} : {addLeadingZeros(String(minutes))} :{' '}
              {addLeadingZeros(String(seconds))}
            </RunTime>
          ) : (
            <RunTimeAdmin>
              {addLeadingZeros(String(hours))} : {addLeadingZeros(String(minutes))} :{' '}
              {addLeadingZeros(String(seconds))}
            </RunTimeAdmin>
          )}
        </>
      );
    }
  };
  return <Countdown date={runtime} now={europeNow} renderer={renderer} />;
};


