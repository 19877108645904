import { AdminBiim, AdminBiimInput, AdminBiims, Biims, EventBiim, EventStatus, Ratings } from 'biim-api';
import { ActionCreator } from 'redux';
import { v4 as uuidv4 } from 'uuid';

import { Alerts } from 'components/layout';
import { Thunk } from 'store/types';
import { beginActivity, endActivity, setError } from 'store/ui/activities';

import {
  ActionType,
  GetBiimsAction,
  GetAdminBiimsAction,
  UpdateBiimAction,
  UpdateBiimStatusAction,
  PostEndBiimRatingAction,
} from './types';
import {
  GetLiveBiimAction,
  UpdateLiveBiimAttendeeIdAction,
  UpdateLiveBiimEventUuidAction,
  UpdateLiveBiimStatusAction,
} from '.';

const getBiimsAction: ActionCreator<GetBiimsAction> = (biims: Biims) => ({
	type: ActionType.GET_BIIMS,
	payload: { biims },
});

export const getBiims = (): Thunk => async (dispatch, _, context) => {
	const activityId = uuidv4();

	try {
		dispatch(beginActivity({ type: ActionType.GET_BIIMS, uuid: activityId }));
		const data = await context.api.event().getBiims();
		dispatch(getBiimsAction(data));
	} catch (e) {
		// @ts-ignore
		Alerts.error(e.message);
		dispatch(
			setError({
				type: ActionType.GET_BIIMS,
				// @ts-ignore
				message: e.message,
				uuid: uuidv4(),
			})
		);
	} finally {
		dispatch(endActivity({ uuid: activityId }));
	}
};

const getLiveBiimAction: ActionCreator<GetLiveBiimAction> = (data: EventBiim) => ({
	type: ActionType.GET_LIVE_BIIM,
	payload: { data },
});

export const getLiveBiim =
	(eventUuid: string): Thunk =>
	async (dispatch, _, context) => {
		const activityId = uuidv4();

		try {
			dispatch(beginActivity({ type: ActionType.GET_LIVE_BIIM, uuid: activityId }));
			const data = await context.api.event().getEvent(eventUuid);
			dispatch(getLiveBiimAction(data));
		} catch (e) {
			// @ts-ignore
			Alerts.error('Link not valid!');
			dispatch(
				setError({
					type: ActionType.GET_LIVE_BIIM,
					// @ts-ignore
					message: e.message,
					uuid: uuidv4(),
				})
			);
		} finally {
			dispatch(endActivity({ uuid: activityId }));
		}
	};

const getAdminBiimsAction: ActionCreator<GetAdminBiimsAction> = (biims: AdminBiims) => ({
	type: ActionType.GET_ADMIN_BIIMS,
	payload: { biims },
});

export const getAdminBiims = (): Thunk => async (dispatch, getState, context) => {
	const activityId = uuidv4();

	try {
		dispatch(beginActivity({ type: ActionType.GET_ADMIN_BIIMS, uuid: activityId }));

		const { session } = getState().auth;

		if (session) {
			const data = await context.api.event({ accessToken: session.token }).getBiimsAsAdmin();
			dispatch(getAdminBiimsAction(data));
		}
	} catch (e) {
		// @ts-ignore
		Alerts.error(e.message);
		dispatch(
			setError({
				type: ActionType.GET_ADMIN_BIIMS,
				// @ts-ignore
				message: e.message,
				uuid: uuidv4(),
			})
		);
	} finally {
		dispatch(endActivity({ uuid: activityId }));
	}
};

const updatBiimAction: ActionCreator<UpdateBiimAction> = (biim: AdminBiim) => ({
	type: ActionType.UPDATE_BIIM,
	payload: { biim },
});

export const updateBiim =
	(id: number, biim: AdminBiimInput): Thunk =>
	async (dispatch, getState, context) => {
		const activityId = uuidv4();

		try {
			dispatch(beginActivity({ type: ActionType.UPDATE_BIIM, uuid: activityId }));

			const { session } = getState().auth;

			if (session) {
				const data = await context.api
					.event({ accessToken: session.token })
					.updateBiimAsAdmin(id, biim);
				dispatch(updatBiimAction(data));
			}
		} catch (e) {
			// @ts-ignore
			Alerts.error(e.message);
			dispatch(
				setError({
					type: ActionType.UPDATE_BIIM,
					// @ts-ignore
					message: e.message,
					uuid: uuidv4(),
				})
			);
		} finally {
			dispatch(endActivity({ uuid: activityId }));
		}
	};

const updatBiimStatusAction: ActionCreator<UpdateBiimStatusAction> = (
	uuidEvent: string,
	status: EventStatus
) => ({
	type: ActionType.UPDATE_BIIM_STATUS,
	payload: { uuidEvent, status },
});

export const updateBiimStatus =
	(uuidEvent: string, status: EventStatus): Thunk =>
	async (dispatch) => {
		const activityId = uuidv4();

		try {
			dispatch(beginActivity({ type: ActionType.UPDATE_BIIM_STATUS, uuid: activityId }));

			dispatch(updatBiimStatusAction(uuidEvent, status));
		} catch (e) {
			// @ts-ignore
			Alerts.error(e.message);
			dispatch(
				setError({
					type: ActionType.UPDATE_BIIM_STATUS,
					// @ts-ignore
					message: e.message,
					uuid: uuidv4(),
				})
			);
		} finally {
			dispatch(endActivity({ uuid: activityId }));
		}
	};

const updatLiveBiimStatusAction: ActionCreator<UpdateLiveBiimStatusAction> = (
	status: EventStatus
) => ({
	type: ActionType.UPDATE_LIVE_BIIM_STATUS,
	payload: { status },
});

export const updateLiveBiimStatus =
	(status: EventStatus): Thunk =>
	async (dispatch) => {
		const activityId = uuidv4();

		try {
			dispatch(beginActivity({ type: ActionType.UPDATE_LIVE_BIIM_STATUS, uuid: activityId }));

			dispatch(updatLiveBiimStatusAction(status));
		} catch (e) {
			// @ts-ignore
			Alerts.error(e.message);
			dispatch(
				setError({
					type: ActionType.UPDATE_LIVE_BIIM_STATUS,
					// @ts-ignore
					message: e.message,
					uuid: uuidv4(),
				})
			);
		} finally {
			dispatch(endActivity({ uuid: activityId }));
		}
	};

const updatBiimEventUuuidAction: ActionCreator<UpdateLiveBiimEventUuidAction> = (
	idEvent: number,
	newUuid: string
) => ({
	type: ActionType.UPDATE_LIVE_BIIM_EVENT_UUID,
	payload: { idEvent, newUuid },
});

export const updateBiimEventUuid =
	(idEvent: number, newUuid: string): Thunk =>
	async (dispatch) => {
		const activityId = uuidv4();

		try {
			dispatch(
				beginActivity({ type: ActionType.UPDATE_LIVE_BIIM_EVENT_UUID, uuid: activityId })
			);

			dispatch(updatBiimEventUuuidAction(idEvent, newUuid));
		} catch (e) {
			// @ts-ignore
			Alerts.error(e.message);
			dispatch(
				setError({
					type: ActionType.UPDATE_LIVE_BIIM_EVENT_UUID,
					// @ts-ignore
					message: e.message,
					uuid: uuidv4(),
				})
			);
		} finally {
			dispatch(endActivity({ uuid: activityId }));
		}
	};

const updatBiimAttendeeIdAction: ActionCreator<UpdateLiveBiimAttendeeIdAction> = (
	attendeeId: string
) => ({
	type: ActionType.UPDATE_LIVE_BIIM_ATTENDEE_ID,
	payload: { attendeeId },
});

export const updateBiimAttendeeId =
	(attendeeId: string): Thunk =>
	async (dispatch) => {
		const activityId = uuidv4();

		try {
			dispatch(
				beginActivity({ type: ActionType.UPDATE_LIVE_BIIM_ATTENDEE_ID, uuid: activityId })
			);

			dispatch(updatBiimAttendeeIdAction(attendeeId));
		} catch (e) {
			// @ts-ignore
			Alerts.error(e.message);
			dispatch(
				setError({
					type: ActionType.UPDATE_LIVE_BIIM_ATTENDEE_ID,
					// @ts-ignore
					message: e.message,
					uuid: uuidv4(),
				})
			);
		} finally {
			dispatch(endActivity({ uuid: activityId }));
		}
	};
	
const postBiimRatingAction: ActionCreator<PostEndBiimRatingAction> = (
  eventId: string,
  rating: Ratings
) => ({
  type: ActionType.POST_RATING_BIIM,
  payload: { eventId, rating },
});

export const postBiimRating =
  (idEvent: string, rating: Ratings): Thunk =>
  async (dispatch, getState, context)  => {
    const activityId = uuidv4();
	
    try {
     	dispatch(beginActivity({ type: ActionType.POST_RATING_BIIM, uuid: activityId }));
		
		await context.api.event().postRatingEvent(idEvent, rating);
		
      	dispatch(postBiimRatingAction(idEvent, rating));
    } catch (e) {
      // @ts-ignore
	  Alerts.error(e.message);

      dispatch(
        setError({
          type: ActionType.POST_RATING_BIIM,
          // @ts-ignore
          message: e.message,
          uuid: uuidv4(),
        })
      );
    } finally {
      dispatch(endActivity({ uuid: activityId }));
    }
};
