import produce from 'immer';

import initialState from './initialState';
import { ActionType, Actions, State } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionType.GET_BIIMS: {
			const { biims } = action.payload;

			return produce(state, (draft) => {
				if (biims) biims.forEach((biim) => draft.biims.push(biim));
			});
		}

		case ActionType.GET_ADMIN_BIIMS: {
			const { biims } = action.payload;

			return produce(state, (draft) => {
				if (biims)
					biims.forEach((biim) => {
						if (!draft.adminBiims.find((b) => biim.id === b.id)) {
							draft.adminBiims.push(biim);
						}
					});
			});
		}

		case ActionType.UPDATE_BIIM: {
			const { biim } = action.payload;

			const convert = [biim];

			return produce(state, (draft) => {
				draft.adminBiims = draft.adminBiims.map(
					(b) => convert.find((c) => c.id === b.id) || b
				);
			});
		}

		case ActionType.UPDATE_BIIM_STATUS: {
			const { uuidEvent, status } = action.payload;

			return produce(state, (draft) => {
				const biimIndex = draft.adminBiims.findIndex(
					(biim) => biim.uuidEvent === uuidEvent
				);

				draft.adminBiims[biimIndex] = { ...draft.adminBiims[biimIndex], status };
			});
		}

		case ActionType.GET_LIVE_BIIM: {
			const { data } = action.payload;

			return produce(state, (draft) => {
				draft.live.biim = data;
			});
		}

		case ActionType.UPDATE_LIVE_BIIM_EVENT_UUID: {
			const { idEvent, newUuid } = action.payload;

			return produce(state, (draft) => {
				if (draft.live.biim.id === idEvent)
					draft.live.biim = { ...draft.live.biim, uuidEvent: newUuid };
			});
		}

		case ActionType.UPDATE_LIVE_BIIM_STATUS: {
			const { status } = action.payload;

			return produce(state, (draft) => {
				draft.live.biim = { ...draft.live.biim, status };
			});
		}

		case ActionType.UPDATE_LIVE_BIIM_ATTENDEE_ID: {
			const { attendeeId } = action.payload;

			return produce(state, (draft) => {
				draft.live.attendeeId = attendeeId;
			});
		}

		default: {
			return state;
		}
	}
};
