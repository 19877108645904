/**
 * An accessible dialog or "modal" window.
 *
 * @see WAI-ARIA https://www.w3.org/TR/wai-aria-practices-1.2/#dialog_modal
 */

import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { useSpring } from 'react-spring';
import { isMobile } from 'react-device-detect';

import { Icon, IconType } from 'components/icons';
import { usePrevious } from 'hooks';

import { AnimatedContainer, Content, Dialog, Header, CloseIcon, Overlay } from './BaseModal.style';

function getActionsTopMargin(count: number) {
  if (count > 3) return 20;
  if (count === 3) return 15;
  if (count === 2) return 10;
  return 5;
}

export interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  onUnmount?: () => void;
}

interface Props extends BaseModalProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  icon?: (types: typeof IconType) => string;
  iconColor?: string;
  inlineContentStyles?: any; // maybe consider using https://www.npmjs.com/package/csstype
  closeable?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  footer?: React.ReactNode;
  fullscreen?: boolean;
  height?: number | string;
  width?: number | string;
  onSubmit: () => void;
}

export function BaseModal({
  open = false,
  closeable = true,
  disabled = false,
  hidden = false,
  fullscreen = false,
  children,
  icon,
  footer,
  iconColor,
  title,
  inlineContentStyles = {},
  height,
  width,
  onClose,
  onSubmit,
  onUnmount,
}: Props) {
  const [rendered, setRendered] = useState(open);
  const [visible, setVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (open) {
      setRendered(true);
    } else {
      setRendered(false);
      setVisible(false);
    }
  }, [open]);

  useEffect(() => {
    if (rendered) {
      const timeout = setTimeout(() => setVisible(true), 150);
      return () => clearTimeout(timeout);
    }
  }, [rendered]);

  const prevRendered = usePrevious(rendered);

  useEffect(() => {
    if (onUnmount && prevRendered && !rendered) {
      onUnmount();
    }
  }, [prevRendered, rendered, onUnmount]);

  function close() {
    if (!disabled) {
      setVisible(false);
      setRendered(false);
      onClose();
    }
  }

  function handleCloseClick(e: React.MouseEvent | React.KeyboardEvent) {
    e.stopPropagation();
    close();
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Escape') {
      e.stopPropagation();
      close();
    }

    if (e.key === 'Enter') {
      e.stopPropagation();
      onSubmit();
    }
  }

  function handleScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (e.currentTarget.scrollTop && !scrolled) {
      setScrolled(true);
    }

    if (!e.currentTarget.scrollTop && scrolled) {
      setScrolled(false);
    }
  }

  function renderIcon() {
    return icon ? (
      <div style={{ display: 'flex' }}>
        <Icon type={icon} margin={{ right: 0.8 }} color={iconColor} />
        <h4>{title}</h4>
      </div>
    ) : (
      <h3>{title}</h3>
    );
  }

  const style = useSpring({
    opacity: fullscreen ? 1 : hidden ? 0 : visible ? 1 : 0,
    top: fullscreen ? 0 : visible ? 0 : -20,
    config: {
      duration: 50,
    },
  });

  if (!rendered) {
    return null;
  }

  /**
   * We want to lock the focus inside the dialog, so we prevent
   * any outside clicks that would move the focus. Because of this,
   * we want to stop the propagation of click events inside
   * the dialog so they retain their default behavior.
   */
  return createPortal(
    <FocusLock persistentFocus={true}>
      <RemoveScroll>
        <Overlay
          isMobile={isMobile}
          onMouseDown={(e) => e.preventDefault()}
        >
          <AnimatedContainer fullscreen={fullscreen} style={style} height={height} width={width}>
            <Dialog
              aria-modal="true"
              aria-label={typeof title === 'string' ? title : ''}
              fullscreen={fullscreen}
              role="dialog"
              tabIndex={0}
              onKeyDown={handleKeyDown}
              onMouseDown={(e) => e.stopPropagation()}
            >
              {fullscreen ? (
                children
              ) : (
                <>
                  <Header scrolled={scrolled}>
                    {closeable && (
                      <CloseIcon
                        disabled={disabled}
                        type={(t) => t.Close}
                        onClick={handleCloseClick}
                      />
                    )}
                    {renderIcon()}
                  </Header>
                  <Content style={inlineContentStyles} onScroll={handleScroll}>
                    {children}
                  </Content>
                  {footer}
                </>
              )}
            </Dialog>
          </AnimatedContainer>
        </Overlay>
      </RemoveScroll>
    </FocusLock>,
    document.getElementById('root')!
  );
}

BaseModal.getActionsTopMargin = getActionsTopMargin;
