export const Colors = {
	transparent: 'transparent',
	white: '#fff',
	black: 'black',
	gradient: 'linear-gradient(91.14deg, #4DC2E6 2.11%, #FF47B1 98.1%)',
	lightBlue: '#00E2F4',
	pink: '#FF47B1',
	darkest: '#0F1119',
	darker: '#1C1E25',
	mustard: '#BFA969',
	darkestBrown: '#2C2D31',
	darkerBrown: '#4A4A4A',
	gray: '#A4A4A4',
	ivory: '#E3E3E3',
	blue: '#4BB4E0',
	bluegreen: '#3C99A5',
	purpleblue: '#5176EB',
	purplepink: '#B376C4',
	purple: '#8C8DD0',
	red:'#f36d6d',
};
