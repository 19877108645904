import { EventStatus } from 'biim-api';
import { createContext, useContext } from 'react';

interface EventStatusProps {
	status: EventStatus;
	setStatus: (status: EventStatus) => void;
}

export const EventStatusContext = createContext<EventStatusProps>({
	status: EventStatus.Before,
	setStatus: () => null,
});

export function useEventStatusContext() {
	const context = useContext(EventStatusContext);
	return context;
}
