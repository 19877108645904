/**
 * An accessbile button component.
 */

import React from 'react';

import { IconType } from 'components/icons';
import { QuantumLoader } from 'components/loaders';

import {
	Badge,
	Button,
	Content,
	Floating,
	Icon,
	StyleProps,
	VariantButtonProps,
	TextStyleProps,
} from './BaseButton.style';

export interface BaseButtonProps extends StyleProps {
	children?: string;
	badge?: number;
	className?: string;
	disabled?: boolean;
	loading?: boolean;
	type?: 'button' | 'submit';
	iconSize?: number;
	icon?: (types: typeof IconType) => string;
	onClick?: () => void;
}

export type ButtonProps = BaseButtonProps & VariantButtonProps & TextStyleProps;

export function BaseButton({
	badge,
	children,
	className,
	disabled = false,
	compact = false,
	loading,
	type = 'button',
	icon,
	iconSize,
	onClick,
	fontSize,
	fontWeight,
	...styleProps
}: ButtonProps) {
	return (
		<Button
			{...styleProps}
			compact={compact}
			aria-disabled={disabled}
			className={className}
			disabled={disabled}
			icon={!!icon}
			inactive={loading}
			role="button"
			type={type}
			onClick={onClick}
		>
			{loading && (
				<Floating>
					<QuantumLoader color={styleProps.color} size={3} />
				</Floating>
			)}

			<Content
				style={{
					opacity: loading ? 0 : 1,
				}}
				fontSize={fontSize}
				fontWeight={fontWeight}
			>
				{icon && <Icon color={styleProps.color} type={icon} size={iconSize} />}
				{children}
				{!!badge && <Badge {...styleProps}>{badge}</Badge>}
			</Content>
		</Button>
	);
}
