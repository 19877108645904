import styled from 'styled-components';

import { Colors } from 'environment';
import { PlacementProps } from 'types';

export interface StyleProps {
  margin?: PlacementProps;
}

export const Container = styled.div<StyleProps>`
  display: block;
  width: 100%;
  margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : 0)};
  margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : 0)};
  margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : 0)};
  margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : 0)};
  cursor: pointer;
`;

export const Content = styled.div`
  background: ${Colors.darkest};
  display: flex;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  padding: 0 1rem;
  height: 7.4rem;
  box-sizing: border-box;
`;

export const Cover = styled.img`
  width: 100%;
  height: 17rem;
  object-fit: cover;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  object-position: top;
`;

export const ProfileImage = styled.img`
  object-fit: cover;
  width: 5.4rem;
  height: 5.4rem;
  border-radius: 5rem;
  margin: 1rem 0;
`;

export const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 2rem 1rem 0;
  width: 100%;
  margin-left: 1rem;
  overflow: hidden;
  box-sizing: border-box;
`;

export const Data = styled.div`
  flex: 1;
  border-left: 5px solid #0c0d15;
  display: flex;
  //flex-direction: column;
  padding-top: 0;
  padding-bottom: 1.5rem;
  text-align: left;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
`;

export const Price = styled.span`
  color: ${Colors.white};
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 20px;
`;

export const InitalStock = styled.p`
  color: ${Colors.gray};
  padding-left: 0.2rem;
  margin: 0 0.1rem 0.5rem;
  box-sizing: border-box;
`;

export const Stock = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: left;
`;

export const Remaining = styled.h4`
  color: ${Colors.white};
  max-height: 5rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Title = styled.span`
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: ${Colors.white};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
`;

export const Subtitle = styled.span`
  font-family: Comfortaa;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: ${Colors.white};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
`;

export const Date = styled.span`
  color: ${Colors.gray};
`;
