import { State } from './types';

const initialState: State = {
  biims: [],
  adminBiims: [],
  live: {
    biim: {
      availabilityDate: '',
      creatorEmail: '',
      creatorImage: '',
      creatorName: '',
      creatorYoutubePlaylistId: '',
      creatorSpotifyPlaylistId: '',
      creatorWaitingRoomBackgroundMusicTrackUrl: '',
      creatorThankYouVideoUrl: '',
      creatorThankYouMessage: '',
      creatorTippingProductId: '',
      description: '',
      id: 0,
      eventType: 1,
      images: [],
      moderatorEmail: '',
      permalink: '',
      price: 0,
      relatedProducts: [],
      runningTime: '',
      status: 1,
      stockInitial: 0,
      stockRemaining: 0,
      title: '',
      userName: '',
      userType: '',
      uuidEvent: '',
      uuidGuest: '',
      wooCreatorId: '',
      wooProductId: 0,
    },
    attendeeId: '',
  },
};

export default initialState;
