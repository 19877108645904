import React, { useEffect, useState } from "react";

type Props = {
    delay: number;
    children: React.ReactNode;
};

const Expire = ({ delay, children }:Props) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, delay);
  }, [delay]);

  return visible ? <div>{children}</div> : <div />;
};

export default Expire;