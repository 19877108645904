import { Layers } from 'environment';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { MediaQueries } from 'environment';

interface ContainerProps {
  color: string;
}

export const Container = styled.nav<ContainerProps>`
  background: ${({ color }) => color};
  box-shadow: 0px -13px 37px #4dc2e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height:7rem;
  padding-left: 3.3rem;
  position: sticky;
  top: 0;
  z-index: ${Layers.menu.drawer};
`;

export const Logo = styled.img`
  position: absolute;
  height: 4rem;
  width: 3rem;
  cursor: pointer;
`;

export const Center = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Link = styled(NavLink)`
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s, font-weight 0.2s;
  position: relative;
  min-width: 15rem;
  padding: 0 4rem;

  &.active {
    color: pink;
    font-weight: 600;

    ::after {
      content: '';
      width: 100%;
      height: 0.4rem;
      border-radius: 0.2rem;
      background-color: lime;
      position: absolute;
      bottom: -3.8rem;
      left: 0;
      pointer-events: none;
    }
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 3rem;
`;

export const Input = styled.input`
  width: 8rem;
`;

export const Customer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  height: 4.8rem;
  width: 4.8rem;
  transition: background-color 0.2s;

  :focus,
  :hover {
    background-color: pink;
  }

  img {
    height: 100%;
  }
`;


export const SupportIcon = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(165, 223, 241, 1);
  border-radius: 2rem;
  padding: 0.5rem;
  background: linear-gradient(98.14deg, #86c1dc 1.11%, #9147ff 98.1%);
  cursor: pointer;
  border: 2px solid #fa4ab1;
  transition: all 500ms;

  &:hover {
    background: linear-gradient(98.14deg, #86c1dc 1.11%, #ff47cb 98.1%);
    transition: all 500ms;
  }

  span {
    font-weight: normal;
    font-size: 1.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    color: #ffffff;
  }

  @media ${MediaQueries.phoneMax} {
    span{
      display: none;
    }
  }

`;
