import {
  ControlBar,
  ControlBarButton,
  VideoTileGrid,
} from 'amazon-chime-sdk-component-library-react';
import styled from 'styled-components';

import { Icon } from 'components/icons';
import { Colors, MediaQueries, Sizes } from 'environment';
import { PlacementProps } from 'types';

export const ContainerWrapper = styled.div`
  display: block;
  height: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 14.6rem;
  padding-right: 12.5rem;

  @media only screen and (max-width: ${Sizes.uhd}px) {
    padding: unset;
  }
`;

interface AlignProps {
  align?: {
    baseline?: boolean;
    end?: boolean;
  };
  margin?: PlacementProps;
}

export const Block = styled.div<AlignProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ align }) =>
    align?.baseline ? 'baseline' : align?.end ? 'flex-end' : 'center'};
  z-index: 1;
  flex: 1 1 0%;

  @media only screen and (max-width: ${Sizes.uhd}px) {
    align-items: center;
    flex-direction: column;
    margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : 0)};
  }
  @media ${MediaQueries.tabletMax} {
    width: 60%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media ${MediaQueries.phoneMax} {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Subtitle = styled.h5`
  font-size: 2.2rem;
  text-align: center;
  width: 30rem;
  color: ${Colors.white};
  margin-top: 1rem;
  @media ${MediaQueries.phoneMax} {
    font-size: 1.6rem;
    margin-top: 1rem;
  }
`;

export const CreatorName = styled.h1`
  font-size: 5rem;
  height: 6rem;
  color: ${Colors.white};
  margin-top: 3.5rem;
  max-width: 50rem;
  height: auto;

  @media only screen and (max-width: ${Sizes.uhd}px) {
    text-align: center;
  }
  @media ${MediaQueries.tabletMax} {
    font-size: 3rem;
  }
  @media ${MediaQueries.phoneMax} {
    font-size: 2.2rem;
  }
`;

export const BiimContainer = styled.div`
  border: 0.2rem solid ${Colors.gray};
  padding: 0.5rem;
  margin-top: 2rem;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  height: auto;
  max-width: 41rem;
`;

export const BiimTitle = styled.h1`
  color: ${Colors.white};
  font-size: 2.5rem;
  text-align: center;

  @media ${MediaQueries.tabletMax} {
    font-size: 1rem;
  }
  @media ${MediaQueries.phoneMax} {
    font-size: 1rem;
  }
`;

export const DateContainer = styled.div`
  margin-top: 3.4rem;
  padding: 1.4rem;
  width: 32rem;
  background: ${Colors.black};
  @media ${MediaQueries.tabletMax} {
    margin-top: 2rem;
  }
  @media ${MediaQueries.phoneMax} {
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
  }
`;

export const DateTitle = styled.h6`
  text-transform: uppercase;
  letter-spacing: 1.248px;
  text-align: center;
  color: ${Colors.white};
  @media ${MediaQueries.tabletMax} {
    font-size: 1.5rem;
  }
  @media ${MediaQueries.phoneMax} {
    font-size: 1.2rem;
  }
`;

export const Image = styled.div<{ src: string }>`
  position: absolute;
  background: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  animation: fade-in 2s;
`;

export const LiveControlBar = styled(ControlBar)`
  position: absolute;
  bottom: 11.4rem;
  right: 0;
`;

export const Video = styled(VideoTileGrid)<{ chatVisibility: boolean; isMobile: boolean }>`
  position: relative;
  background: ${Colors.darkest};
  transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  bottom: 0;
  margin: 0;

  @media ${MediaQueries.phone} {
    bottom: 0;
  }

  .ch-video {
    margin: 0;
    // height: 100vh;
    //width: 100vw;
    border-radius: 0;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  }
`;

export const MeetVideo = styled(VideoTileGrid)<{ chatVisibility: boolean; isMobile: boolean }>`
  position: relative;
  background: ${Colors.darkest};
  transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  bottom: 0;
  margin: 0;

  @media ${MediaQueries.phone} {
    bottom: 0;
  }

  .ch-video {
    margin: 0;
    //height: 100vh;
    //width: 100vw;
    border-radius: 0;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  }
`;

export const MeetVideoWrap = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  height: calc(100% - 6rem);
  @media ${MediaQueries.phone} {
    height: calc(100% - 8rem);
  }
`;

export const BarControlDesktop = styled(ControlBar)<{ chatVisibility: boolean }>`
  position: absolute;
  background: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  height: 6rem;
  padding-right: 2rem;
  padding-left: 2rem;
  margin: auto auto 0;

  .ch-control-bar-item {
    margin-right: 2rem;
  }

  .ch-control-bar-item-iconButton {
    color: white;

    :focus,
    :hover {
      background: unset;
      box-shadow: unset;
    }
  }

  .ch-control-bar-item-caret {
    color: white;
    width: 3rem;
    height: 3rem;
    border: unset;
    background-color: transparent;

    :focus,
    :hover {
      background: unset;
      box-shadow: unset;
    }
  }

  .ch-control-bar-item-iconButton {
    //margin-right: 3rem;

    .ch-icon {
      width: 3.5rem;
      height: 3.5rem;

      :hover {
        width: 4rem;
        height: 4rem;
      }
    }

    :focus,
    :hover {
      box-shadow: unset;
      border: unset;
      background: unset;
    }
  }

  @media ${MediaQueries.phone} {
    top: auto;
    bottom: 0;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
    height: 4rem;
    width: 100%;
    margin: 0;
    box-shadow: 0px 2px 40px rgba(241, 78, 180, 0.4);

    .ch-control-bar-item-caret {
      color: white;
      width: 2rem;
      height: 2rem;
      border: unset;
      background-color: transparent;

      :focus,
      :hover {
        background: unset;
        box-shadow: unset;
      }

      :hover {
        width: 2rem;
        height: 2rem;
      }
    }

    .ch-control-bar-item-iconButton {
      //margin-right: 3rem;

      .ch-icon {
        width: 3rem;
        height: 3rem;

        :hover {
          width: 3rem;
          height: 3rem;
        }
      }

      :focus,
      :hover {
        box-shadow: unset;
        border: unset;
        background: unset;
      }
    }
  }

  // @media screen and (orientation: landscape) and (max-width: ${Sizes.lmd - 1}px) {
  //   bottom: ${({ chatVisibility }) => (chatVisibility ? '6rem' : '57vh')};
  // }
`;

export const BarControlMobile = styled(ControlBar)<{ chatVisibility: boolean; isMobile: boolean }>`
  position: absolute;
  background: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  height: 6rem;
  padding-right: 2rem;
  margin: auto auto 0;

  .ch-control-bar-item {
    margin-right: 2rem;
  }

  .ch-control-bar-item-iconButton {
    color: white;

    :focus,
    :hover {
      background: unset;
      box-shadow: unset;
    }
  }

  .ch-control-bar-item-caret {
    color: white;
    width: 3rem;
    height: 3rem;
    border: unset;
    background-color: transparent;

    :focus,
    :hover {
      background: unset;
      box-shadow: unset;
    }
  }

  .ch-control-bar-item-iconButton {
    .ch-icon {
      width: 3.5rem;
      height: 3.5rem;

      :hover {
        width: 4rem;
        height: 4rem;
      }
    }

    :focus,
    :hover {
      box-shadow: unset;
      border: unset;
      background: unset;
    }
  }

  @media ${MediaQueries.phone} {
    top: ${({ chatVisibility }) => (chatVisibility ? 'unset' : 'unset')};
    bottom: 0rem;
    height: 4rem;
    width: 100%;
    left: 0;
    padding-right: 0;
    box-shadow: 0px 2px 40px rgba(241, 78, 180, 0.4);

    @media screen and (orientation: landscape) {
      // left: ${({ isMobile }) => isMobile && '4rem'};
      // bottom: ${({ isMobile }) => isMobile && '5%'};
      // top: ${({ isMobile }) => isMobile && 'unset'};
    }

    .ch-control-bar-item-caret {
      color: white;
      width: 2rem;
      height: 2rem;
      border: unset;
      background-color: transparent;

      :focus,
      :hover {
        background: unset;
        box-shadow: unset;
      }

      :hover {
        width: 2rem;
        height: 2rem;
      }
    }

    .ch-control-bar-item-iconButton {
      //margin-right: 3rem;

      .ch-icon {
        width: 3rem;
        height: 3rem;

        :hover {
          width: 3rem;
          height: 3rem;
        }
      }

      :focus,
      :hover {
        box-shadow: unset;
        border: unset;
        background: unset;
      }
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;
  margin-top: 4.5rem;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding-left: 14.6rem;
  padding-right: 12.5rem;

  @media only screen and (max-width: ${Sizes.uhd}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const EventDescriptionContainer = styled.div`
  z-index: 1;
  margin-bottom: 4rem;
  max-height: 30rem;
  width: 37.5rem;
`;

export const EventTitleDescription = styled.h3`
  color: ${Colors.white};
  margin-bottom: 1rem;
`;
export const EventDescription = styled.p`
  color: ${Colors.white};
`;

export const Related = styled.div`
  display: flex;
  width: 100%;
  height: 28rem;
  overflow-x: auto;
  padding: 1.5rem 2rem;
  z-index: 1;

  ::-webkit-scrollbar {
    background: transparent;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
  }
`;

export const LogoContainer = styled.div`
  width: 38.1rem;
  position: relative;
  display: flex;
  margin-bottom: 1rem;

  @media ${MediaQueries.tabletMax} {
    width: 100%;
    height: inherit !important;
  }
  @media only screen and (max-width: ${Sizes.uhd}px) {
    justify-content: center;
  }
  @media ${MediaQueries.phoneMax} {
    width: 100%;
    height: inherit !important;
  }
`;

export const Logo = styled(Icon)`
  top: 1%;
  position: relative;
  > svg {
    height: inherit;
  }

  @media ${MediaQueries.tabletMax} {
    > svg {
      width: 17rem;
      margin: 0 auto;
    }
  }
  @media ${MediaQueries.phoneMax} {
    > svg {
      width: 9rem;
      margin: 0 auto;
    }
  }
  @media ${MediaQueries.biggerPhone} {
    > svg {
      width: 12rem;
      margin: 0 auto;
    }
  }
`;

export const LiveDot = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 3rem;
  background: red;
`;

export const LiveTitle = styled.h6`
  color: white;
  margin-left: 1rem;
`;

export const LiveAnnouncement = styled.div`
  position: absolute;
  top: 4rem;
  left: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const QueueVideoContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const QueueVideo = styled.video<{
  isMobile: boolean;
  screenOrientation?: OrientationType;
}>`
  width: 163rem;
  top: -15rem;
  background-size: cover;
  left: ${({ isMobile }) => (isMobile ? '41%' : '50%')};
  transform: translate(-51%, 0);
  animation: fade-in 10s;
  position: absolute;

  @media only screen and (max-width: 400px) {
    left: ${({ screenOrientation }) =>
      screenOrientation === 'portrait-primary' || screenOrientation === 'portrait-secondary'
        ? '40%'
        : '49%'};
  }

  @media screen and (orientation: landscape) {
    left: ${({ isMobile }) => isMobile && '49%'};
  }
`;

export const QueueDateContainer = styled.div`
  background: black;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0rem;
  @media ${MediaQueries.tabletMax} {
    padding: 1.2rem;
  }
`;

export const QueueDate = styled.p`
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1.248px;
  color: ${Colors.white};
  @media ${MediaQueries.tabletMax} {
    font-size: 1.2rem;
  }
`;

export const QueueContent = styled.div`
  z-index: 1;
  position: absolute;
  width: 100%;
  padding-left: clamp(0rem, 1vw, 7rem);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  button {
    max-width: max-content;
  }

  @media only screen and (max-width: 500px) {
    right: 0;
  }
`;

export const QueueContentTitle = styled.p`
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: clamp(2.8rem, 6vw, 3rem);
  line-height: normal;
  color: white;
  @media ${MediaQueries.tabletMax} {
    font-size: 2rem;
  }
`;

export const QueueCreatorContent = styled.div`
  display: flex;
  margin-top: 1rem;
  @media ${MediaQueries.tabletMax} {
    display: block;
  }
`;

export const QueueCreatorImage = styled.img`
  width: 7rem;
  height: 8rem;
  object-fit: cover;
  animation: fade-in 2s;
  @media ${MediaQueries.tabletMax} {
    display: none;
  }
`;

export const QueueCreator = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  max-width: 30rem;
  margin: 0 clamp(0.5rem, 3vw, 2rem);
  margin-bottom: 1rem;
`;

export const QueueCreatorTitle = styled.span`
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  line-height: 2rem;
  font-size: 1.8rem;
  color: ${Colors.white};
  width: 100%;
  text-align: left;
  @media ${MediaQueries.tabletMax} {
    text-align: center;
  }
`;

export const QueueCreatorSubtitleContainer = styled.div`
  border: 0.2rem solid ${Colors.gray};
  padding: 0.5rem;
  margin-top: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  height: auto;
`;

export const QueueCreatorSubtitle = styled.span`
  font-family: Comfortaa;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: ${Colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
`;

export const ControlBarWrap = styled.div`
  display: flex;
  width: 11rem;
  justify-content: space-between;
  margin-bottom: unset;
  @media ${MediaQueries.phoneMax} {
    width: 90%;
  }
`;
export const ControlBarWrapMeet = styled.div`
  display: flex;
  width: 32rem;
  justify-content: space-between;
  margin-bottom: unset;
  @media ${MediaQueries.phoneMax} {
    width: 90%;
  }
`;

export const ControlBarWrapLive = styled.div`
  display: flex;
  width: 11rem;
  justify-content: space-between;
  margin-bottom: unset;
  @media ${MediaQueries.phoneMax} {
    width: 90%;
    justify-content: center;
  }
`;

export const PermissionWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  button {
    pointer-events: none;
    opacity: 0.4;
  }
  @media ${MediaQueries.phoneMax} {
    width: auto;
  }
`;

export const PermissionWrapperMeet = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  button {
    pointer-events: none;
    opacity: 0.4;
  }
  @media ${MediaQueries.phoneMax} {
    width: 100%;
  }
`;
export const PermissionContainer = styled.div`
  display: flex;
  width: calc(100% - 8rem);
  justify-content: space-between;
  @media ${MediaQueries.phoneMax} {
  }
`;

export const DisableButtonWrapper = styled.div`
  button {
    pointer-events: none;
    opacity: 0.4;
  }
`;

// waiting room youtube, info and spotifiy styles
export const CreatorMsg = styled.div`
  position: absolute;
  z-index: 100;
  top: 8rem;
  left: 3rem;
  background: rgba(15, 17, 25, 0.7);
  border-radius: 1.5rem;
  font-family: Comfortaa;
  color: white;
  padding: 2rem;
  font-size: 1.5rem;
  text-align: center;
  border: 2px solid #c2a1ec;
  width: 30rem;
  max-width: 100%;
  @media ${MediaQueries.smallerPhone} {
    left: inherit;
    right: 2rem;
    width: 44%;
    //margin: 0 auto;
    padding: 0.5rem;
    top: 1rem;
    font-size: 1rem;
  }
`;

export const SubHeading = styled.div`
  font-family: Comfortaa;
  color: white;
  font-size: 1.1rem;
`;
export const Heading = styled.div`
  font-family: Comfortaa;
  color: white;
  font-size: 2rem;
  @media ${MediaQueries.smallerPhone} {
    font-size: 1.2rem;
  }
`;

export const CreatorMsgVideo = styled.div`
  position: absolute;
  z-index: 100;
  top: 20rem;
  left: 3rem;
  background: rgba(15, 17, 25, 0.7);
  border-radius: 1.5rem;
  font-family: Comfortaa;
  color: white;
  padding: 2rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  //border: 2px solid #c2a1ec;
  cursor: pointer;
  animation-name: glow;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  width: 30rem;
  max-width: 100%;

  svg {
    margin-right: 1rem;
  }

  @keyframes glow {
    from {
      box-shadow: 0px 0px 5px #fff, 0px 0px 5px #d91b1b;
    }
    to {
      box-shadow: 0px 0px 20px #fff, 0px 0px 20px #614ad3;
    }
  }
  @media ${MediaQueries.smallerPhone} {
    background: inherit;
    position: absolute;
    top: 8.6rem;
    left: 12.2rem;
    padding: inherit;
    width: auto;
    animation: none;
    svg{
      width: 2.5rem;
    }
  }
`;

export const CreatorMsgVideoText = styled.div`
  @media ${MediaQueries.smallerPhone} {
    display: none;
  }
`;

export const CreatorYoutube = styled.div`
  position: absolute;
  z-index: 100;
  top: 32rem;
  left: 3rem;
  width: 30rem;
  max-width: 100%;
  background: rgba(15, 17, 25, 0.7);
  border-radius: 1.5rem;
  font-family: Comfortaa;
  color: white;
  padding: 2rem;
  font-size: 1.5rem;
  @media ${MediaQueries.smallerPhone} {
    position: absolute;
    top: 8.6rem;
    left: 3rem;
    background: transparent;
    padding: inherit;
    width: auto;
  }
`;
export const CreatorYoutubeInfo = styled.div`
  @media ${MediaQueries.smallerPhone} {
    display: none;
  }
`;

export const IconsWrap = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media ${MediaQueries.smallerPhone} {
    svg{
      width: 3rem;
    }
  }
`;

export const BackgroundMusicWrap = styled.div`
  position: absolute;
  z-index: 100;
  top: 1rem;
  left: 3rem;
  display: flex;
`;

export const BgMusicLabel = styled.div`
  p{
    color: ${Colors.white};
    padding:0.5rem;
  }
` ;

export const ModalContent = styled.div`
  span {
    font-family: Comfortaa;
    font-size: 1.4rem;
    color: white;
    text-align: center;
    display: block;
    padding: 1rem;
    max-width: 60rem;
    margin: 0 auto;
  }
`;

export const ContentYoutube = styled.div<{ isOpenYoutube: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: ${({ isOpenYoutube }) => (isOpenYoutube ? '0rem' : '-70rem')};
  width: 52.5rem;
  height: fit-content;
  top: 2rem;
  background: rgba(15, 17, 25, 0.7);
  z-index: 10;
  border-radius: 2.2rem;
  padding: 5rem 3rem;
  transition: right 1s;

  @media ${MediaQueries.smallerPhone} {
    max-width: 100%;
    width: 100%;
    top: 8rem;
    padding: 4rem 2rem;
  }
`;

export const CloseSidePannel = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const ContentSpotify = styled.div<{ isOpenSpotify: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: ${({ isOpenSpotify }) => (isOpenSpotify ? '0rem' : '-70rem')};
  width: 52.5rem;
  height: fit-content;
  top: 2rem;
  background: rgba(15, 17, 25, 0.7);
  z-index: 10;
  border-radius: 2.2rem;
  padding: 5rem 3rem;
  transition: right 1s;

  @media ${MediaQueries.smallerPhone} {
    max-width: 100%;
    width: 100%;
    top: 8rem;
  }
`;
export const ButtonTip =`

`;

