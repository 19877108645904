import { EventStatus, EventType } from 'biim-api';
import {
  PrimaryButton,
  Roster,
  RosterCell,
  RosterGroup,
  useMeetingManager,
  useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ChatInput } from 'components/inputs';
import { Icon } from 'components/icons';
import { Colors, MediaQueries } from 'environment';
import { onUpdateAttendee } from 'graphql/subscriptions';
import {
  useEffectOnce,
  useEventContext,
  useEventStatusContext,
  useMediaQuery,
  useNavbarUIContext,
} from 'hooks';
import { getAttendeeFromDB, updateAttendeeToDB } from 'utils/api';
import { useRealitimeSubscribeChatState } from './RealtimeSubscribeChatProvider';
import {
  ChatContent,
  Container,
  Expand,
  InputContent,
  ButtonContainer,
  ChatContainer,
  UsersContainer,
  ExpandMobile,
  BubbleContainer,
  Bubble,
  ButtonText,
  Button,
  ButtonBox,
  ContainerWrapper,
  ScrollWrapper,
  ScrollTop,
  PrimaryBtn,
  PrimaryBtnMute,
  SwitchBox,
  TipBtn,
  TipBtnContainer,
  TipSendBtn,
  TipInputWrapper,
  ScrollWrapperTips,
} from './Chat.style';
import { CloseIcon } from '../../components/widgets/Modal/BaseModal.style';
import Switch from 'react-switch';
import axios from 'axios';
import { RemoveLastDirectoryPartOf, Storage } from 'helpers';

export interface ChatProps {
  attendeeId: string;
  attendeeName: string;
  userType: string;
  eventType: EventType;
  visible: boolean;
  onChangeVisibility: (isVisible: boolean) => void;
  onChangeStatus?: (status: EventStatus) => void;
  onMuteAttendee?: (mute: boolean) => void;
}

enum SelectedView {
  Chat,
  Guests,
  Admins,
  Tips,
}

export const ChatView = ({
  attendeeId,
  visible,
  attendeeName,
  userType,
  onChangeVisibility,
  onChangeStatus,
  onMuteAttendee,
}: ChatProps) => {
  const { roster } = useRosterState();
  const [selectedView, setSelectedView] = useState<SelectedView>(SelectedView.Chat);
  const [chatMessage, setChatMessage] = useState('');
  const [adminChatMessage, setAdminChatMessage] = useState('');
  const [tipAmountMessage, setTipAmountMessage] = useState('');
  const [isChatAllowed, setIsChatAllowed] = useState(true);
  const attendees = Object.values(roster);
  const attendeeList: any[] = [];
  const isPhone = useMediaQuery(MediaQueries.phone);
  const xsPhone = useMediaQuery(MediaQueries.xsPhone);
  const phoneMax = useMediaQuery(MediaQueries.phoneMax);
  const { guestView, guestChat } = useNavbarUIContext();
  const {
    chatData,
    sendChatData,
    userPreasence,
    adminChatData,
    sendAdminChatData,
    sendMuteAllAttendeeData,
    muteAllAttendeeData,
    tipData,
    sendTipData,
  } = useRealitimeSubscribeChatState();
  const CHAT_MAX_LENGTH = 250;
  const TIP_MAX_LENGTH = 3;
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const meetingManager = useMeetingManager();
  const [muteAttendees, setMuteAttendees] = useState(false);
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [tipAmount, setTipAmount] = useState(0);
  const { status } = useEventStatusContext();
  const { eventData: data } = useEventContext();
  const [recordingStatus, setRecordingStatus] = useState(false);
  const [recordingStopped, setRecordingSopped] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const startTimerMuteAttendees = () => {
    setTimer(
      setInterval(() => {
        sendMuteAllAttendeeData('MUTE');
        setCount((prevCount) => prevCount + 1);
      }, 1000)
    );
  };

  const stopTimerMuteAttendees = () => {
    if (timer) {
      sendMuteAllAttendeeData('UNMUTE');
      clearInterval(timer);
      setTimer(null);
    }
  };

  const sendTip = (tipAmount: string) => {
    const current = localStorage.getItem(
      `tip-${data.uuidEvent}-${String(new Date().toLocaleDateString('en-GB'))}`
    );
    const currentTipAmount = Number(current) + Number(tipAmount);
    localStorage.setItem(
      `tip-${data.uuidEvent}-${String(new Date().toLocaleDateString('en-GB'))}`,
      `${String(currentTipAmount)}`
    );
    sendTipData(`Has sent £ ${tipAmount}`);
  };

  const startRecording = async () => {
    const recordingData = await axios.post(
      `${process.env.REACT_APP_RECORDING_URL}?recordingAction=start&meetingURL=${encodeURIComponent(
        RemoveLastDirectoryPartOf(location.href)
      )}%2F${data.uuidGuest}`,
      {}
    );
    Storage.set((k) => k.RecordingTaskId, recordingData.data.data);
  };

  const stopRecording = async () => {
    const localTaskId = Storage.get((k) => k.RecordingTaskId);
    await axios.post(
      `${process.env.REACT_APP_RECORDING_URL}?recordingAction=stop&taskId=${localTaskId}`,
      {}
    );
    Storage.remove((k) => k.RecordingTaskId);
    Storage.set((k) => k.RecordingStopped, 'true');
    setRecordingSopped(true);
  };

  attendees.map((attendee: any) => {
    const attendeeIndex = attendeeList.findIndex(
      (att) => att.chimeAttendeeId === attendee.chimeAttendeeId
    );
    if (attendeeIndex === -1) {
      attendeeList.push(attendee);
    }

    return attendeeList;
  });

  async function graphSocketConnect() {
    // @ts-ignore
    const subs = await API.graphql(
      graphqlOperation(onUpdateAttendee, { attendeeId })
      // @ts-ignore
    ).subscribe({
      // @ts-ignore
      next: (ev) => {
        if (ev.value.data.onUpdateAttendee.chatPermissions === 'notAllowed')
          setIsChatAllowed(false);
        if (ev.value.data.onUpdateAttendee.chatPermissions === 'allowed') setIsChatAllowed(true);
        if (ev.value.data.onUpdateAttendee.meetingPermissions === 'notAllowed')
          onChangeStatus && onChangeStatus(EventStatus.Finished);
      },
    });

    return subs;
  }

  useEffect(() => {
    graphSocketConnect();
  }, []);

  useEffect(() => {
    if (visible === false && (chatData.length !== 0 || tipData.length !== 0)) {
      setUnreadMessages(true);
    } else {
      setUnreadMessages(false);
    }
  }, [chatData, tipData]);

  useEffect(() => {
    if (visible) {
      setUnreadMessages(false);
    }
  }, [visible]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      if (recordingStatus) {
        startRecording();
      } else {
        stopRecording();
      }
    }
  }, [recordingStatus]);

  useEffect(() => {
    if (guestChat === 'Guests') {
      setSelectedView(SelectedView.Guests);
      if (visible === false) {
        onChangeVisibility(true);
      }
    }
    if (guestChat === 'Tips') {
      setSelectedView(SelectedView.Tips);
      if (visible === false) {
        onChangeVisibility(true);
      }
    }
  }, [guestView]);

  useEffect(() => {
    if (muteAttendees === true) startTimerMuteAttendees();
    else stopTimerMuteAttendees();
  }, [muteAttendees]);

  useEffect(() => {
    if (
      meetingManager &&
      meetingManager.audioVideo &&
      muteAllAttendeeData.data === 'MUTE' &&
      userType === 'guest'
    ) {
      meetingManager.audioVideo.realtimeMuteLocalAudio();
      onMuteAttendee && onMuteAttendee(true);
    } else if (
      meetingManager &&
      meetingManager.audioVideo &&
      muteAllAttendeeData.data === 'UNMUTE' &&
      userType === 'guest'
    ) {
      onMuteAttendee && onMuteAttendee(false);
    }

    if (meetingManager && meetingManager.audioVideo && muteAllAttendeeData.data === 'MUTE') {
      setMuteAttendees(true);
    } else if (
      meetingManager &&
      meetingManager.audioVideo &&
      muteAllAttendeeData.data === 'UNMUTE'
    ) {
      setMuteAttendees(false);
    }
  }, [muteAllAttendeeData]);

  async function getUserPermissions() {
    const attendeeResponse: any = await getAttendeeFromDB(attendeeId);
    if (
      attendeeResponse.data.getAttendee !== null &&
      attendeeResponse.data.getAttendee.chatPermissions === 'notAllowed'
    ) {
      setIsChatAllowed(false);
    }
  }

  useEffectOnce(() => {
    getUserPermissions();
  });

  async function removeAttendeeFromMeeting(selectedAttendeeId: string) {
    const attendeeResponse: any = await getAttendeeFromDB(selectedAttendeeId);

    await updateAttendeeToDB(
      selectedAttendeeId,
      attendeeResponse.data.getAttendee.chatPermissions,
      'notAllowed'
    );
  }

  async function muteUnmuteAttendee(selectedAttendeeId: string) {
    const attendeeResponse: any = await getAttendeeFromDB(selectedAttendeeId);
    const attendeeIndex = attendeeList.findIndex(
      (att) => att.chimeAttendeeId === selectedAttendeeId
    );

    const permission: any =
      attendeeResponse.data.getAttendee.chatPermissions === 'notAllowed'
        ? await updateAttendeeToDB(
            selectedAttendeeId,
            'allowed',
            attendeeResponse.data.getAttendee.meetingPermissions
          )
        : await updateAttendeeToDB(
            selectedAttendeeId,
            'notAllowed',
            attendeeResponse.data.getAttendee.meetingPermissions
          );

    attendeeList[attendeeIndex].chatPermissions =
      permission.data.updateAttendeeGraphQL.chatPermissions;
    forceUpdate();
  }

  const userName = (extName: any, isChat: boolean) => {
    let modifiedName = '',
      role = '',
      name = '';

    if (isChat) {
      role = extName.split('#')[1];
      name = extName.split('#')[0];
    } else {
      role = extName.split('#')[2];
      name = extName.split('#')[1];
    }

    switch (role) {
      case 'creator':
        modifiedName = `${name} - ( ${role} 🔰🔰 )`;
        break;
      case 'moderator':
        modifiedName = `${name} - ( ${role} 🔰 )`;
        break;
      case 'guest':
        modifiedName = `${name} - ( 👤 )`;
        break;
      default:
        break;
    }

    return modifiedName;
  };

  const attendeeRoster = attendeeList.map((c: any) => {
    const Menu = () => (
      <>
        <div
          style={{ padding: '.5rem 1rem', cursor: 'pointer' }}
          onClick={(e) => {
            muteUnmuteAttendee(c.chimeAttendeeId);
            e.preventDefault();
          }}
        >
          {c.chatPermissions === undefined || c.chatPermissions === 'allowed' ? 'Block' : 'Unblock'}
        </div>
        <div
          style={{ padding: '.5rem 1rem', cursor: 'pointer' }}
          onClick={(e) => {
            removeAttendeeFromMeeting(c.chimeAttendeeId);
            e.preventDefault();
          }}
        >
          Kick user
        </div>
      </>
    );

    return (
      <RosterCell
        key={c.externalUserId}
        name={userName(c.externalUserId, false)}
        muted={
          userType !== 'guest' &&
          attendeeName.split(' ')[0] !== c.externalUserId.split('#')[1] &&
          true
        }
        poorConnection
        menu={
          userType !== 'guest' &&
          attendeeName.split(' ')[0] !== c.externalUserId.split('#')[1] && <Menu />
        }
        css={`
          margin-bottom: 1rem;
          background: transparent;
          width: 100%;
          padding: 0;
          .ch-menu {
            background: transparent;
            &:focus {
              border: 0.03125rem solid #ffffff;
              box-shadow: none;
            }

            .ch-icon {
              height: inherit;
              width: inherit;
              svg {
                color: #ffffff;
                fill: #ffffff;
                width: 20px;
              }
            }
          }
          .ch-mic {
            svg {
              color: #ffffff;
              fill: #ffffff;
              width: 20px;
            }
          }
          .ch-name {
            font-family: Comfortaa;
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            white-space: nowrap;
            padding-left: 18px;
            color: white;
            width: 100%;
          }
        `}
      />
    );
  });

  const attendeeItems = [];
  const chatArr = [...chatData, ...tipData];
  const sortedChatArr = chatArr.sort((a, b) => a.createdDate - b.createdDate);
  for (const c of sortedChatArr) {
    const senderName = userName(c.senderName, true);
    const text = c.data;
    const time = new Date(c.createdDate).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    const timeKey = new Date(c.createdDate).toLocaleTimeString();
    const nameColor = c.senderColor;
    attendeeItems.push(
      <BubbleContainer timestamp={time} key={timeKey + senderName + uuidv4()}>
        <Bubble
          nameColor={nameColor}
          key={time + senderName}
          variant={attendeeId === senderName ? 'outgoing' : 'incoming'}
          senderName={`${senderName}:`}
        >
          {text}
        </Bubble>
      </BubbleContainer>
    );
  }

  const adminChatItems = [];
  for (const c of adminChatData) {
    const senderName = userName(c.senderName, true);
    const text = c.data;
    const time = new Date(c.createdDate).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    const timeKey = new Date(c.createdDate).toLocaleTimeString();
    const nameColor = c.senderColor;
    adminChatItems.push(
      <BubbleContainer timestamp={time} key={timeKey + senderName + uuidv4()}>
        <Bubble
          nameColor={nameColor}
          key={time + senderName}
          variant={attendeeId === senderName ? 'outgoing' : 'incoming'}
          senderName={`${senderName}:`}
        >
          {text}
        </Bubble>
      </BubbleContainer>
    );
  }

  const tipItems = [];
  for (const c of tipData) {
    const senderName = userName(c.senderName, true);
    const text = c.data;
    const time = new Date(c.createdDate).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    const timeKey = new Date(c.createdDate).toLocaleTimeString();
    const nameColor = c.senderColor;
    tipItems.push(
      <BubbleContainer timestamp={time} key={timeKey + senderName + uuidv4()}>
        <Bubble
          nameColor={nameColor}
          key={time + senderName}
          variant={attendeeId === senderName ? 'outgoing' : 'incoming'}
          senderName={`${senderName}:`}
        >
          {text}
        </Bubble>
      </BubbleContainer>
    );
  }

  const messageEl = useRef(null);
  const adminChat = useRef(null);
  const tip = useRef(null);

  useEffect(() => {
    if (messageEl.current) {
      // @ts-ignore
      messageEl.current.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }

    if (adminChat.current) {
      // @ts-ignore
      messageEl.current.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }

    if (tip.current) {
      // @ts-ignore
      messageEl.current.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, []);

  return (
    <>
      {location.pathname.split('/')[2] !== 'recording' && (
        <ContainerWrapper>
          <Container expand={visible}>
            <CloseIcon
              type={(k) => k.Close}
              color="#000000"
              active={visible}
              size={3}
              onClick={() => {
                onChangeVisibility(!visible);
                setSelectedView(SelectedView.Chat);
              }}
              style={{
                marginLeft: '0',
                position: 'absolute',
                right: '1rem',
                top: '1rem',
                height: '3rem',
                width: '3rem',
                background: 'rgb(0 0 0 / 70%)',
                padding: '1rem 0 1rem 0',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '11',
                cursor: 'pointer',
              }}
            />
            {userType !== 'guest' && status === EventStatus.MeetInProgress && (
              <div>
                {phoneMax ? (
                  <SwitchBox>
                    <label>Mute All</label>
                    <Switch
                      onChange={() => setMuteAttendees(!muteAttendees)}
                      checked={muteAttendees}
                      offColor="#888"
                      onColor="#fa4ab1"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={18}
                      width={35}
                      handleDiameter={12}
                    />
                  </SwitchBox>
                ) : (
                  <SwitchBox>
                    <label>Mute All</label>
                    <Switch
                      onChange={() => setMuteAttendees(!muteAttendees)}
                      checked={muteAttendees}
                      offColor="#888"
                      onColor="#fa4ab1"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={18}
                      width={35}
                      handleDiameter={12}
                    />
                  </SwitchBox>
                )}
              </div>
            )}
            {/* {userType !== 'guest' && status === EventStatus.LiveInProgress && (
              <div>
                {phoneMax ? (
                  <SwitchBox>
                    <label>Start Recording</label>
                    <Switch
                      onChange={() => setRecordingStatus(!recordingStatus)}
                      checked={recordingStatus}
                      disabled={recordingStopped}
                      offColor="#888"
                      onColor="#fa4ab1"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={18}
                      width={35}
                      handleDiameter={12}
                    />
                  </SwitchBox>
                ) : (
                  <SwitchBox>
                    <label>start Recording</label>
                    <Switch
                      onChange={() => setRecordingStatus(!recordingStatus)}
                      checked={recordingStatus}
                      disabled={recordingStopped}
                      offColor="#888"
                      onColor="#fa4ab1"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={18}
                      width={35}
                      handleDiameter={12}
                    />
                  </SwitchBox>
                )}
              </div>
            )} */}
            {!isPhone && (
              <Expand>
                <Icon
                  type={(k) => (unreadMessages ? k.ChatDesktopUnread : k.ChatDesktop)}
                  color="#FF47B1"
                  size={7}
                  onClick={() => {
                    onChangeVisibility(!visible);
                    setSelectedView(SelectedView.Chat);
                  }}
                  style={{
                    marginLeft: '-7rem',
                    background: 'rgba(15, 17, 25, 0.8)',
                    padding: '1rem 0 1rem 0',
                    borderTopLeftRadius: '1rem',
                    borderBottomLeftRadius: '1rem',
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                  }}
                />
              </Expand>
            )}

            <ButtonBox>
              <ButtonContainer
                onClick={() => {
                  setSelectedView(SelectedView.Chat);
                }}
              >
                <Button
                  selected={selectedView === SelectedView.Chat}
                  style={{
                    left: xsPhone ? '0rem' : isPhone ? '3.5rem' : '-2.7rem',
                    width: xsPhone ? '6rem' : isPhone ? '9rem' : '13rem',
                  }}
                />
                <ButtonText
                  selected={selectedView === SelectedView.Chat}
                  style={{
                    left: xsPhone ? '1.5rem' : isPhone ? '6rem' : '1rem',
                    right: xsPhone ? 0 : isPhone ? 'inherit' : '0',
                  }}
                >
                  chat
                </ButtonText>
              </ButtonContainer>

              <ButtonContainer
                onClick={() => {
                  setSelectedView(SelectedView.Guests);
                }}
              >
                <Button
                  style={{
                    left: xsPhone ? '0rem' : isPhone ? '1.5rem' : '-5.4rem',
                    width: xsPhone ? '7rem' : isPhone ? '12rem' : '15rem',
                  }}
                  selected={selectedView === SelectedView.Guests}
                />
                <ButtonText
                  style={{
                    left: xsPhone ? '1.5rem' : isPhone ? '5rem' : '-1rem',
                    right: xsPhone ? 0 : isPhone ? 'inherit' : '0',
                  }}
                  selected={selectedView === SelectedView.Guests}
                >
                  guests
                </ButtonText>
              </ButtonContainer>
              <ButtonContainer
                onClick={() => {
                  setSelectedView(SelectedView.Tips);
                }}
              >
                <Button
                  style={{
                    left: xsPhone ? '0rem' : isPhone ? '-0.5rem' : '-8rem',
                    width: xsPhone ? '5rem' : isPhone ? '10rem' : '13rem',
                  }}
                  selected={selectedView === SelectedView.Tips}
                />
                <ButtonText
                  style={{
                    left: xsPhone ? '1rem' : isPhone ? '3rem' : '-3rem',
                    right: xsPhone ? 0 : isPhone ? 'inherit' : '0',
                  }}
                  selected={selectedView === SelectedView.Tips}
                >
                  tips
                </ButtonText>
              </ButtonContainer>

              {userType !== 'guest' && (
                <ButtonContainer
                  onClick={() => {
                    setSelectedView(SelectedView.Admins);
                  }}
                >
                  <Button
                    style={{
                      left: xsPhone ? '0rem' : isPhone ? '-2.5rem' : '-10.5rem',
                      width: xsPhone ? '7rem' : isPhone ? '12rem' : '15rem',
                    }}
                    selected={selectedView === SelectedView.Admins}
                  />
                  <ButtonText
                    style={{
                      left: xsPhone ? '1rem' : isPhone ? '0rem' : '-7rem',
                      right: xsPhone ? 0 : isPhone ? 'inherit' : 'inherit',
                    }}
                    selected={selectedView === SelectedView.Admins}
                  >
                    admins
                  </ButtonText>
                </ButtonContainer>
              )}
            </ButtonBox>
            <ChatContainer isVisible={selectedView === SelectedView.Chat}>
              <ScrollWrapper>
                <ScrollTop />
                <div id="scroll-container">
                  <div>
                    <ChatContent ref={messageEl}>{attendeeItems}</ChatContent>
                  </div>
                </div>
              </ScrollWrapper>
              {isChatAllowed && (
                <InputContent>
                  <ChatInput
                    //@ts-ignore
                    onChange={(e) => setChatMessage(e.target.value)}
                    value={chatMessage}
                    placeholder="Send a message"
                    type="text"
                    name="chat"
                    maxLength={CHAT_MAX_LENGTH}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && chatMessage.length !== 0) {
                        setChatMessage('');
                        sendChatData(chatMessage);
                        e.preventDefault();
                      }
                    }}
                    style={{
                      resize: 'vertical',
                      color: 'white',
                      background: 'rgb(0 0 0 / 23%)',
                      borderRadius: '4px',
                      fontFamily: 'Comfortaa',
                    }}
                  />

                  <PrimaryButton
                    css={PrimaryBtn}
                    label={`Send (${CHAT_MAX_LENGTH - chatMessage.length})`}
                    disabled={chatMessage.length === 0}
                    onClick={() => {
                      setChatMessage('');
                      sendChatData(chatMessage);
                    }}
                  />
                </InputContent>
              )}
            </ChatContainer>
            <ChatContainer isVisible={selectedView === SelectedView.Tips}>
              <ScrollWrapperTips
                style={userType !== 'guest' ? { height: 'calc(100% - 3rem)' } : {}}
              >
                <ScrollTop />
                <div id="scroll-container">
                  <div>
                    <ChatContent ref={tip}>{tipItems}</ChatContent>
                  </div>
                </div>
              </ScrollWrapperTips>
              {userType === 'guest' && (
                <InputContent>
                  <h3>Tip now pay later</h3>
                  <h4>Select amount</h4>
                  <TipBtnContainer>
                    <PrimaryButton
                      css={TipBtn}
                      label={`£ 1`}
                      onClick={() => {
                        sendTip('1');
                      }}
                    />
                    <PrimaryButton
                      css={TipBtn}
                      label={`£ 2.50`}
                      onClick={() => {
                        sendTip('2');
                      }}
                    />
                    <PrimaryButton
                      css={TipBtn}
                      label={`£ 5`}
                      onClick={() => {
                        sendTip('5');
                      }}
                    />
                    <PrimaryButton
                      css={TipBtn}
                      label={`£ 10`}
                      onClick={() => {
                        sendTip('10');
                      }}
                    />
                  </TipBtnContainer>
                  <h4>Or enter amount</h4>
                  <TipInputWrapper>
                    <ChatInput
                      onChange={(e) => setTipAmountMessage(e.target.value)}
                      value={tipAmountMessage}
                      placeholder="Custom amount"
                      type="number"
                      name="tip"
                      min={0}
                      max={100}
                      maxLength={TIP_MAX_LENGTH}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && tipAmountMessage.length !== 0) {
                          sendTip(tipAmountMessage);
                          setTipAmountMessage('');
                          e.preventDefault();
                        }
                      }}
                      style={{
                        resize: 'vertical',
                        color: 'white',
                        background: 'rgb(0 0 0 / 23%)',
                        borderRadius: '4px',
                        fontFamily: 'Comfortaa',
                        width: '100%',
                      }}
                    />
                  </TipInputWrapper>

                  <PrimaryButton
                    css={TipSendBtn}
                    label={(tipAmountMessage && '£ ') + tipAmountMessage + ' Send Tip'}
                    disabled={tipAmountMessage.length === 0}
                    onClick={() => {
                      sendTip(tipAmountMessage);
                      setTipAmountMessage('');
                    }}
                  />
                </InputContent>
              )}
            </ChatContainer>
            <UsersContainer isVisible={selectedView === SelectedView.Guests}>
              <Roster css="background: transparent; border:unset; box-shadow: unset; width: 100%;">
                <RosterGroup css="div {background: transparent; width: 100%;}">
                  {attendeeRoster}
                </RosterGroup>
              </Roster>
            </UsersContainer>
            {userType !== 'guest' && (
              <ChatContainer isVisible={selectedView === SelectedView.Admins}>
                <ScrollWrapper>
                  <ScrollTop />
                  <div id="scroll-container">
                    <div>
                      <ChatContent ref={adminChat}>{adminChatItems}</ChatContent>
                    </div>
                  </div>
                </ScrollWrapper>
                {isChatAllowed && (
                  <InputContent>
                    <ChatInput
                      //@ts-ignore
                      onChange={(e) => setAdminChatMessage(e.target.value)}
                      value={adminChatMessage}
                      placeholder={`Chat with ${userType === 'creator' ? 'moderator' : 'creator'}`}
                      type="text"
                      name="chat"
                      maxLength={CHAT_MAX_LENGTH}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && adminChatMessage.length !== 0) {
                          setAdminChatMessage('');
                          sendAdminChatData(adminChatMessage);
                          e.preventDefault();
                        }
                      }}
                      style={{
                        resize: 'vertical',
                        color: 'white',
                        background: 'rgb(0 0 0 / 23%)',
                        borderRadius: '4px',
                        fontFamily: 'Comfortaa',
                      }}
                    />

                    <PrimaryButton
                      css={PrimaryBtn}
                      label={`Send (${CHAT_MAX_LENGTH - adminChatMessage.length})`}
                      disabled={adminChatMessage.length === 0}
                      onClick={() => {
                        setAdminChatMessage('');
                        sendAdminChatData(adminChatMessage);
                      }}
                    />
                  </InputContent>
                )}
              </ChatContainer>
            )}
          </Container>
          {isPhone && (
            <ExpandMobile>
              <Icon
                type={(k) => (unreadMessages ? k.ChatMobileUnread : k.ChatMobile)}
                color="#000000"
                active={visible}
                size={2.2}
                onClick={() => {
                  onChangeVisibility(!visible);
                  setSelectedView(SelectedView.Chat);
                }}
                style={{
                  marginLeft: '0',
                  position: 'absolute',
                  right: '1rem',
                  bottom: '5rem',
                  height: '4rem',
                  width: '4rem',
                  background: 'linear-gradient(91.14deg,#4DC2E6 2.11%,#FF47B1 98.1%)',
                  padding: '1rem 0 1rem 0',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '11',
                  border: '1px solid #fff',
                }}
              />
            </ExpandMobile>
          )}
        </ContainerWrapper>
      )}
    </>
  );
};
