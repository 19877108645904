import styled from 'styled-components/macro';
import { Icon as DefaultIcon } from 'components/icons';
import { Colors, MediaQueries } from 'environment';

export const Style = styled.div`
	.Toastify__toast-container {
		@media ${MediaQueries.phone} {
			padding: 0 1rem;
		}

		.Toastify__toast {
			display: flex;
			border-radius: 4px;
			padding: 1rem;
			transition: background 0.2s;

			@media ${MediaQueries.phone} {
				margin-top: 1rem;
			}

			&-body {
				font-family: 'Open Sans', sans-serif;
				color: ${Colors.white};
				font-size: 1.6rem;
				margin: 0;

				svg {
					fill: ${Colors.white};
				}
			}

			&--error {
				background: rgba(120, 0, 0, 1);
			}

			&--success {
				background: green;
			}

			&--info {
				background: rgba(0, 0, 0, 0.8);
			}
		}
	}
`;

export const Icon = styled(DefaultIcon)`
	height: fit-content;
	color: ${Colors.white};
	right: -0.6rem;
	top: -0.6rem;
`;
