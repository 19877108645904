import { formatDate } from 'helpers';

import {
	Container,
	Content,
	Cover,
	Data,
	Date,
	InitalStock,
	Left,
	Price,
	Remaining,
	Stock,
	StyleProps,
	Title,
	ProfileImage,
	Subtitle,
} from './BiimCard.style';

interface CardProps {
	creatorName: string;
	title: string;
	availability: string;
	imgSrc: string;
	profileImgSrc: string;
	price: number;
	stock: number;
	permalink?: string;
	remainingStock: number;
	onClick?: () => void;
}

type Props = CardProps & StyleProps;

export function BiimCard({
	creatorName,
	title,
	availability,
	imgSrc,
	profileImgSrc,
	permalink,
	price,
	stock,
	remainingStock,
	margin,
	onClick,
}: Props) {
	function openInNewTab(url?: string) {
		if (url) {
			const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
			if (newWindow) newWindow.opener = null;
		}
	}

	const { DD_MM_YYYY } = formatDate(availability)

	return (
		<Container margin={margin} onClick={onClick ? onClick : () => openInNewTab(permalink)}>
			<Cover src={imgSrc} />
			<Content>
				<ProfileImage src={profileImgSrc} />
				<Left>
					<Title>{creatorName}</Title>
					<Subtitle>{title}</Subtitle>
					<Date>{DD_MM_YYYY}</Date>
				</Left>

			</Content>
			<Data>
				<Price>£{parseFloat(`${price}`).toFixed(2)}</Price>
				<Stock>
					<Remaining>{remainingStock}</Remaining>
					<InitalStock>/</InitalStock>
					<InitalStock>{stock}</InitalStock>
				</Stock>
			</Data>
		</Container>
	);
}
