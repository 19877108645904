import { combineReducers } from 'redux';

import { State as Auth, reducer as auth, ActionType as AuthActionType } from './auth';

import { State as Event, reducer as event } from './events';

import { State as Activities, reducer as activities } from './ui/activities';

import { ApplicationActions } from './types';

export interface ApplicationState {
	auth: Auth;
	event: Event;
	ui: {
		activities: Activities;
	};
}

const appReducer = combineReducers<ApplicationState>({
	auth,
	event,
	ui: combineReducers({
		activities
	})
});

function rootReducer(state: ApplicationState | undefined, action: ApplicationActions) {
	// function resetState() {}

	if (state) {
		if (action.type === AuthActionType.LOGOUT) {
			// resetState();
		}
	}

	return appReducer(state, action);
}

export default rootReducer;
