import styled from 'styled-components';

export const SmallText = styled.small`
  color: rgba(0, 0, 0, 0.5);
`;

export const StyledCard = styled.div`
  .ch-body {
    margin-bottom: 1rem;
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
  }
  .ch-header {
    font-size: 1.5rem;
  }
  .ch-title {
    font-size: 1.25rem;
    margin-top: 1rem;
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
  }
  .ch-description {
    margin: 1rem 0 1rem 0;
  }
`;
