import styled from 'styled-components/macro';
import { LayoutStyleProps, LayoutFlexProps } from 'types';

export interface BlockProps extends LayoutStyleProps, LayoutFlexProps {
	direction?: 'column' | 'row';
	position?: 'relative' | 'absolute' | 'fixed';
}

export const Block = styled.div<BlockProps>`
	display: flex;
	flex-direction: ${({ direction }) => direction};
	justify-content: ${({ justify }) => justify};
	align-items: ${({ align }) => align};
	margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
	margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
	margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
	margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
	padding-top: ${({ padding }) => (padding?.top ? `${padding.top}rem` : undefined)};
	padding-bottom: ${({ padding }) => (padding?.bottom ? `${padding.bottom}rem` : undefined)};
	padding-left: ${({ padding }) => (padding?.left ? `${padding.left}rem` : undefined)};
	padding-right: ${({ padding }) => (padding?.right ? `${padding.right}rem` : undefined)};
	position: ${({ position }) => position};
	height: ${({ height }) =>
		height !== undefined ? (typeof height === 'string' ? height : `${height}rem`) : 'auto'};
	width: ${({ width = '100%' }) =>
		width !== undefined ? (typeof width === 'string' ? width : `${width}rem`) : 'auto'};
`;
