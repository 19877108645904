import { EventStatus, EventType, factory } from 'biim-api';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ContentLoader } from 'components/loaders';

import {
  useNavbarColorContext,
  useEffectOnce,
  useEventContext,
  useEventStatusContext
} from 'hooks';

import { ContainerCreatorDevice } from './Creator.styles';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { DevicePermissionPrompt } from 'components/widgets/Device';

export function CreatorDeviceSetup() {
  const [eventLoading, setEventLoading] = useState(false);
  const meetingManager = useMeetingManager();
  const { eventData: data } = useEventContext();
  const { setStatus } = useEventStatusContext();
  const api = factory();

  const { pathname } = useLocation();
  const { type, switchColor } = useNavbarColorContext();

  const uuid = pathname.split('/')[1];

  useEffectOnce(() => {
    if (type === 'dark') switchColor('colored');
  });

  const clickedJoinMeeting = async () => {
    setEventLoading(true);
    await meetingManager.start();
    if (data.eventType === EventType.Live || data.eventType === EventType.LiveMeet) {
      if (data.status === EventStatus.MeetInProgress) {
        setStatus(EventStatus.MeetInProgress);
        await api.event().updateStatusEvent(uuid, EventStatus.MeetInProgress);
      } else {
        setStatus(EventStatus.LiveInProgress);
        await api.event().updateStatusEvent(uuid, EventStatus.LiveInProgress);
      }
    }

    if (data.eventType === EventType.Meet) {
      setStatus(EventStatus.MeetInProgress);
      await api.event().updateStatusEvent(uuid, EventStatus.MeetInProgress);
    }
  };

  return (
    <ContentLoader loading={eventLoading}>
      <ContainerCreatorDevice>
        <DevicePermissionPrompt
          open={true}
          closePreview={clickedJoinMeeting}
          onSubmit={clickedJoinMeeting}
          type={data.userType}
        />
      </ContainerCreatorDevice>
    </ContentLoader>
  );
}
