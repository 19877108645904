import { ChatBubble, ChatBubbleContainer } from 'amazon-chime-sdk-component-library-react';
import { Icon } from 'components/icons';
import { Colors, MediaQueries, Sizes } from 'environment';
import styled from 'styled-components';
import { PlacementProps } from 'types';

interface Props {
  expand: boolean;
}

export const ContainerWrapper = styled.div`
  display: block;
`;
export const Container = styled.div<Props>`
  position: absolute;
  right: ${({ expand }) => (expand ? '2rem' : '-53rem')};
  transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  top: 4rem;
  z-index: 1;
  height: calc(100% - 14rem);
  margin: auto;
  width: 53rem;
  padding-left: 3rem;
  border-radius: 1rem;
  background: rgba(15, 17, 25, 0.8);

  @media ${MediaQueries.phone} {
    position: absolute;
    right: ${({ expand }) => (expand ? 0 : '-200vw')};
    left:0;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
    top: 6rem;
    z-index: 100;
    height: calc(100% - 20rem);
    margin: auto;
  //  width: calc(100vw - 10rem);
    width:90vw;
    padding-left: 0;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    background: rgba(15, 17, 25, 0.8);
  }
`;

export const ChatContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  width: 90%;
  height: 85%;
  z-index: ${({ isVisible }) => (isVisible ? '1' : '-1')};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};

  @media ${MediaQueries.phone} {
    width: 100%;
    height: calc(100% - 8rem);
  }
`;

export const InvisibileContainer = styled.div<{ isVisible: boolean }>`
  overflow: hidden;
  z-index: ${({ isVisible }) => (isVisible ? '1' : '-1')};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
`;

export const UsersContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  width: 90%;
  height: calc(100% - 10rem);
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: ${({ isVisible }) => (isVisible ? '1' : '-1')};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};

  ::-webkit-scrollbar {
    background: transparent;
    height: 7px;
  }

  @media ${MediaQueries.phone} {
    width: 100%;
    height: calc(100% - 10rem);
    padding: 0 1.5rem;
  }

  @media screen and (orientation: landscape) and (max-width: ${Sizes.lmd - 1}px) {
    height: 22vh;
  }
`;

export const ButtonContainer = styled.div<{ align?: PlacementProps }>`
  position: relative;
  top: ${({ align }) => (align?.top ? `${align.top}rem` : undefined)};
  bottom: ${({ align }) => (align?.bottom ? `${align.bottom}rem` : undefined)};
  left: ${({ align }) => (align?.right ? `${align.right}rem` : undefined)};
  right: ${({ align }) => (align?.left ? `${align.left}rem` : undefined)};

  @media ${MediaQueries.phone} {
    //right: 0;
    //left: 1.5rem;
  }
`;

export const ButtonBox = styled.div`
  position: relative;
  margin-top: clamp(2rem, 3vh, 4rem);
  margin-bottom: clamp(2rem, 3vh, 4rem);
  margin-right: 2rem;
  display: flex;
  justify-content: flex-start;

  @media ${MediaQueries.phone} {
    justify-content: center;
    margin-bottom: 1rem;
    margin-right: unset;
    margin-top: 5rem;
  }
`;

export const Button = styled.div<{ selected: boolean }>`
  position: relative;
  width: 130.5px;
  height: 24px;
  background: ${({ selected }) => (selected ? 'rgb(0 0 0 / 23%)' : 'rgb(0 0 0 / 23%)')};
  box-shadow: ${({ selected }) =>
    selected
      ? '0px 0px 6px rgb(0 0 0 / 23%), inset 0px 2px 6px rgba(0, 0, 0, 0.25)'
      : '0px 2px 6px rgb(0 0 0 / 23%), inset 0px 2px 6px rgba(255, 255, 255, 0.25)'};
  transform: skewY(-40deg) rotate(40deg);

  @media ${MediaQueries. xsPhone} {
    transform: none;
  }
  
`;

export const ButtonText = styled.p<{ selected: boolean }>`
  position: absolute;
  top: 1.3rem;
  right: 3rem;
  z-index: 1;
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 0px;
  color: ${({ selected }) => (selected ? '#ff47b1' : 'rgba(255,255,255,1)')};

  @media ${MediaQueries.phone} {
    font-size: 16px;
  }
  @media ${MediaQueries. xsPhone} {
    font-size: 1.3rem;
  }
`;

export const ScrollWrapper = styled.div`
  height: calc(100% - 15rem);
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
`;

export const ScrollWrapperTips = styled.div`
  height: calc(100% - 25rem);
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
`;

export const ScrollTop = styled.div`
  flex: 1 1 0%;
`;

export const ChatContent = styled.div`
  height: calc(100% - 10rem);
  overflow-y: scroll;
  font-family: Comfortaa;

  ::-webkit-scrollbar {
    background: transparent;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
  }

  @media ${MediaQueries.phone} {
    padding: 0 1rem;
    height: 100%;
    font-family: Comfortaa;
  }
`;

export const BubbleContainer = styled(ChatBubbleContainer)`
  background-color: unset;
  margin-bottom: 0.5rem;
  margin-left: unset;
  align-items: baseline;
  flex-direction: row-reverse;
  justify-content: flex-end;

  .ch-timestamp {
    color: #efefef;
    padding-right: unset;
  }

  div:nth-child(2) {
    margin-left: unset;
  }
`;

export const Bubble = styled(ChatBubble)<{ nameColor?: string }>`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  background: unset;
  box-shadow: unset;

  .ch-sender-name {
    color: ${({ nameColor }) => (nameColor ? nameColor : '#F46700')};
    font-family: Comfortaa;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
  }

  div:nth-child(2) {
    color: #efefef;
    font-size: 12px;
    font-family: Comfortaa;
    font-style: normal;
    font-weight: 300;
  }
`;

export const InputContent = styled.div`
 // width: calc(100% - 4rem);
  margin-left: 0;
  display: flex;
  flex-direction: column;
  background-color: #fa4ab130;
  padding: 2rem;
  border-radius: 1.5rem;
  h3{
    font-size: 1.8rem;
    font-family: Comfortaa;
    color: ${Colors.white};
    margin: 1rem 0;
  }
  h4{
    font-size: 1.5rem;
    font-family: Comfortaa;
    color: ${Colors.white};
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }

  @media ${MediaQueries.phone} {
    width: calc(100% - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom:1rem;
  }
`;

export const Expand = styled.div`
  position: absolute;
  left: 0;
  align-self: center;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const SwitchBox = styled.div`
  right: 50rem;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  border: unset;
  color: #fff;
  position: fixed;
  top: 21px;
  border: none;
  display: flex;
  align-items: center;
  label{
    margin: 0 1rem;
    font-size: 1.4rem;
    font-family: Comfortaa,sans-serif;
    font-weight: 600;
  }
  @media ${MediaQueries.phoneMax} {
   // right: 20rem;
  }
  @media ${MediaQueries.smPhone} {
    right: 7rem;
    top: inherit;
    bottom: 13rem;
  }
`;

export const ExpandIcon = styled(Icon)<{ expanded: boolean }>`
  transform: ${({ expanded }) => expanded && `rotate(0.5turn)`};
`;

export const ExpandMobile = styled.div`
  //position: absolute;
  //left: 0;
  //align-self: center;
  //display: flex;
  //align-items: center;
  height: 100%;
`;




export const PrimaryBtn = `
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom:1rem;
  align-self: flex-end;
  border: unset;
  border-radius: 4px;
  background: ${Colors.pink};
  color: ${Colors.white};
  padding: 1.4rem 2rem;
  font-family: Comfortaa,sans-serif;

  :hover {
    background:${Colors.purplepink};
    border: unset;
    box-shadow: unset;
    color: ${Colors.white};
  }

  :active {
    background:rgb(255 71 177 / 23%);
    border: unset;
    box-shadow: unset;
    color: ${Colors.white};
  }

  :focus {
    background:rgb(255 71 177 / 23%);
    border: unset;
    box-shadow: unset;
    color: ${Colors.white};
  }

  :disabled {
    background: ${Colors.pink};
    border: unset;
    box-shadow: unset;
    color: ${Colors.white};
  }
  span {
    font-size: 1.4rem;
  }
`;

export const TipSendBtn = `
  margin-left: 19rem;
  align-self: flex-start;
  border: unset;
  border-radius: 4px;
  background: ${Colors.pink};
  color: ${Colors.white};
  padding: 1.4rem 2rem;
  margin-top:-4.6rem;
  cursor:pointer;
  font-family: Comfortaa,sans-serif;
  :hover {
    background:${Colors.purplepink};
    border: unset;
    box-shadow: unset;
    color: ${Colors.white};
  }

  :active {
    background:rgb(255 71 177 / 23%);
    border: unset;
    box-shadow: unset;
    color: ${Colors.white};
  }

  :focus {
    background:rgb(255 71 177 / 23%);
    border: unset;
    box-shadow: unset;
    color: ${Colors.white};
  }

  :disabled {
    background: ${Colors.pink};
    border: unset;
    box-shadow: unset;
    color: ${Colors.white};
  }
  span {
    font-size: 1.4rem;
  }
   @media ${MediaQueries.phoneMax} {
    width: 100%;
    display: block;
    align-self: end;
    position: relative;
    margin: 0.5rem 0 0;
  }
  @media ${MediaQueries.xsPhone} {
    width: 100%;
    display: block;
    align-self: end;
    position: relative;
    margin: 0.5rem 0 0;
  }
   @media ${MediaQueries.tabletMax} {
     margin-left: 26rem;
  }
  
`;

export const TipInputWrapper = styled.div`
  width: 40%;
  margin-top:0rem;

  @media ${MediaQueries.phoneMax} {
    width: 100%;
    
    
  }
  @media ${MediaQueries.xsPhone} {
    width: 100%;
   
}


`;




export const TipBtnContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
`;

export const TipBtn = `
  border: unset;
  border-radius: 4px;
  background: ${Colors.purplepink};
  color: ${Colors.white};
  background:rgb(255 71 177 / 23%);
  box-shadow: 0px 2px 6px rgb(0 0 0 / 23%), inset 0px 2px 6px rgba(255, 71, 177, 0.8);
  padding: 1.1rem 1rem;
  width:25%;
  

  :hover {
    background: ${Colors.pink};
    border: unset;
    box-shadow: unset;
    color: ${Colors.white};
  }

  :active {
    background: ${Colors.pink};
    border: unset;
    box-shadow: unset;
    color: ${Colors.white};
  }

  :focus {
    background: ${Colors.pink};
    border: unset;
    box-shadow: unset;
    color: ${Colors.white};
  }

  :disabled {
    background: rgb(0 0 0 / 23%);
    border: unset;
    box-shadow: unset;
    color: ${Colors.lightBlue};
  }

  span {
    font-size: 1.6rem;
        @media ${MediaQueries.phoneMax} {
        font-size: 1.4rem;
        }
  }
  
`;

export const PrimaryBtnMute = `
  right:44rem;
  align-self: flex-end;
  border: unset;
  border-radius: 4px;
  background: ${Colors.darkerBrown};
  color: ${Colors.white};
  padding: 1rem 2rem;
  position:fixed;
  top:17px;
  border:none;

  :hover {
    background: ${Colors.darkestBrown};
    border: unset;
    box-shadow: unset;
    border:none;
  }
  :focus {
    background: ${Colors.darkestBrown};
    border: unset;
    box-shadow: unset;
    border:none;
  }
  :focus:hover {
    border: unset;
    box-shadow: unset;
    border:none;
  }
  span {
    font-size: 1.2rem;
  }
`;
