import React from 'react';

import { CustomLink, Text, StyleProps } from './Link.style';

export interface LinkProps {
	children: React.ReactNode;
	className?: string;
	disabled?: boolean;
	margin?: StyleProps['$margin'];
	to?: string;
	weight?: StyleProps['$weight'];
	underline?: boolean;
	onClick?: () => void;
}

export function Link({
	children,
	className,
	disabled = false,
	underline = false,
	margin,
	to,
	weight,
	onClick,
}: LinkProps) {
	if (to) {
		return (
			<CustomLink
				className={className}
				$disabled={disabled}
				draggable={false}
				$margin={margin}
				to={to}
				$weight={weight}
				$underline={underline}
			>
				{children}
			</CustomLink>
		);
	}

	return (
		<Text
			className={className}
			$disabled={disabled}
			draggable={false}
			$margin={margin}
			tabIndex={0}
			$weight={weight}
			$underline={underline}
			onClick={onClick}
		>
			{children}
		</Text>
	);
}
