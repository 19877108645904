import styled, { css } from 'styled-components/macro';
import { PlacementProps } from 'types';

interface Props {
	align?: 'center';
	color?: string;
	margin?: PlacementProps;
	size?: number;
	weight?: number;
	uppercase?: boolean;
	indicator?: string;
}

export const H2 = styled.h2<Props>`
	color: ${({ color }) => color};
	font-size: ${({ size }) => (size ? `${size}rem` : undefined)};
	font-weight: ${({ weight }) => weight || 700};
	margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
	margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
	margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
	margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
	text-align: ${({ align }) => align};
	text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
`;

export const H3 = styled.h3<Props>`
	color: ${({ color }) => color};
	font-size: ${({ size }) => (size ? `${size}rem` : undefined)};
	font-weight: ${({ weight }) => weight || 600};
	margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
	margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
	margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
	margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
	text-align: ${({ align }) => align};
	text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
`;

export const H4 = styled.h4<Props>`
	color: ${({ color }) => color};
	font-size: ${({ size }) => (size ? `${size}rem` : undefined)};
	font-weight: ${({ weight }) => weight || 700};
	margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
	margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
	margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
	margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
	text-align: ${({ align }) => align};
	text-transform: ${({ uppercase }) => uppercase && 'uppercase'};

	${({ indicator }) =>
		indicator &&
		css`
			left: 2rem;
			position: relative;

			::before {
				content: '';
				position: absolute;
				background-color: ${indicator};
				left: -2rem;
				border-radius: 0.2rem;
				height: 3.2rem;
				width: 0.4rem;
			}
		`}
`;

export const H5 = styled.h5<Props>`
	color: ${({ color }) => color};
	font-size: ${({ size }) => (size ? `${size}rem` : undefined)};
	font-weight: ${({ weight }) => weight || 700};
	margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
	margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
	margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
	margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
	text-align: ${({ align }) => align};
	text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
`;

export const H6 = styled.h6<Props>`
	color: ${({ color }) => color};
	font-size: ${({ size }) => (size ? `${size}rem` : undefined)};
	font-weight: ${({ weight }) => weight};
	margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
	margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
	margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
	margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
	text-align: ${({ align }) => align};
	text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
`;

export const P = styled.p<Props>`
	color: ${({ color }) => color};
	font-size: ${({ size }) => (size ? `${size}rem` : undefined)};
	font-weight: ${({ weight }) => weight || 400};
	margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
	margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
	margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
	margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
	text-align: ${({ align }) => align};
	text-transform: ${({ uppercase }) => uppercase && 'uppercase'};

	${({ indicator }) =>
		indicator &&
		css`
			left: 2rem;
			position: relative;

			::before {
				content: '';
				position: absolute;
				background-color: ${indicator};
				left: -2rem;
				border-radius: 0.2rem;
				height: 2.4rem;
				width: 0.4rem;
			}
		`}
`;

export const Span = styled.span<Props>`
	color: ${({ color }) => color};
	font-size: ${({ size }) => (size ? `${size}rem` : undefined)};
	font-weight: ${({ weight }) => weight};
	margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
	margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
	margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
	margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
	text-align: ${({ align }) => align};
	text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
`;
