import React from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { usePlatformContext } from 'hooks';

import { Link, LinkProps } from './Link';

interface StyleProps {
	$tv: boolean;
}

export const StyledLink = styled(Link)<StyleProps>`
	display: block;
	font-weight: unset;

	& > div {
		box-shadow: gray;
		transition: box-shadow 0.2s, border-color 0.2s;
	}

	:hover {
		& > div {
			box-shadow: gray;
		}
	}

	${({ $tv }) =>
		$tv &&
		css`
			& > div {
				border: 2px solid black;

				:focus,
				:hover {
					border-color: green;
				}
			}
		`}
`;

export function CardLink({ to, ...props }: LinkProps) {
	const location = useLocation();
	const { isTv } = usePlatformContext();

	return <StyledLink {...props} to={`${to}${location.search}`} $tv={isTv} />;
}
