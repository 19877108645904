import { useNavigate } from 'react-router-dom';

export function useNavigation() {
	const navigator = useNavigate();

	const routes = {
		root: '/',
		login: '/login',
		admin: '/admin',
		biimEvent: (eventId?: string) => `/${eventId}`,
	};

	function navigate(pathname: string) {
		navigator(pathname);
	}

	function replace(pathname: string) {
		navigator(pathname);
	}

	return {
		routes,
		navigate,
		replace,
	};
}
