import React from 'react';
import { Container } from './HamburgerMenu.style';

interface Props {
	isOpen: boolean;
	ref: React.RefObject<HTMLDivElement>;
	onClick: () => void;
}

export const HamburgerMenu = React.forwardRef<HTMLDivElement, Props>(function (
	{ isOpen, onClick },
	ref
) {
	return (
		<Container
			ref={ref}
			id="menuToggle"
			checked={isOpen}
			onClick={(e) => {
				onClick();
				e.preventDefault();
			}}
		>
			<span />
			<span />
			<span />
		</Container>
	);
});
