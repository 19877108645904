import { Colors, Layers, MediaQueries } from 'environment';
import styled from 'styled-components/macro';

interface Props {
  bounds?: ClientRect;
  width?: number | string;
}

export const Menu = styled.div<Props>`
  background-color: ${Colors.darkest};
  border-radius: 1.1rem;
  box-shadow: 0px 0px 18px #55c2e6;
  overflow-x: hidden;
  padding: 4.9rem 0;
  position: absolute;
  animation: menu-reveal-left 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  width: ${({ width }) =>
    width !== undefined ? (typeof width === 'string' ? width : `${width}rem`) : 'auto'};
  z-index: ${Layers.floater};

  .horizontal-track {
    display: none;
  }

  @media ${MediaQueries.phone} {
    animation: menu-reveal-top 0.3s cubic-bezier(0.22, 1, 0.36, 1);
    border-radius: 0;
  }

  @keyframes menu-reveal-left {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes menu-reveal-top {
    from {
      transform: translateY(-30%);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
