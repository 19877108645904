import React, { useContext, useState, ReactNode } from 'react';

type Props = {
	children: ReactNode;
};

type User = { name: string; color: string };

interface AppStateValue {
	localUser: User;
	setLocalUser: (user: User) => void;
}

const AppStateContext = React.createContext<AppStateValue | null>(null);

export function useAppState(): AppStateValue {
	const state = useContext(AppStateContext);

	if (!state) {
		throw new Error('useAppState must be used within AppStateProvider');
	}

	return state;
}

export function AppStateProvider({ children }: Props) {
	const [localUser, setLocalName] = useState<User>({ name: '', color: '' });

	const setLocalUser = (name: User) => {
		setLocalName(name);
	};

	const providerValue = {
		localUser,
		setLocalUser,
	};

	return <AppStateContext.Provider value={providerValue}>{children}</AppStateContext.Provider>;
}
