import { Icons } from 'environment';

export enum IconType {
  Logo = 'logo',
  ShortLogo = 'short-logo',
  Close = 'close',
  Clear = 'clear',
  Settings = 'settings',
  Share = 'share',
  Calendar = 'calendar',
  Profile = 'profile',
  TeardropEmpty = 'teardrop-empty',
  TearDrop = 'teardrop',
  ArrowLeft = 'arrow-left',
  ArrowUp = 'arrow-up',
  ArrowDown = 'arrow-down',
  InfoEvent = 'info-event',
  Message = 'message',
  MessageMute = 'message-mute',
  Mail = 'mail',
  GrayMail = 'gray-mail',
  ChatDesktop = 'chat-desktop',
  ChatMobile = 'chat-mobile',
  FilmPlay = 'film-play',
  YoutubeIcon = 'youtube-icon',
  SpotifyIcon = 'spotify-icon',
  EnlargeIcon = 'enlarge-icon',
  QuestionMark = 'question-mark',
  Mic = 'mic',
  MicMute = 'mic-mute',
  Talk = 'talk',
  TalkStop = 'talk-stop',
  Speaker = 'speaker',
  SpeakerMute = 'speaker-mute',
  UsersTalk = 'users-talk',
  UsersTalkStop = 'users-talk-stop',
  TipIcon = 'tip-icon',
  ChatDesktopUnread = 'chat-desktop-unread',
  ChatMobileUnread = 'chat-mobile-unread',
}

export function parseIconType(type?: string) {
  switch (type) {
    case IconType.Logo:
      return Icons.Logo;
    case IconType.ShortLogo:
      return Icons.ShortLogo;
    case IconType.Close:
      return Icons.Close;
    case IconType.Clear:
      return Icons.Clear;
    case IconType.Settings:
      return Icons.Settings;
    case IconType.Profile:
      return Icons.Profile;
    case IconType.Share:
      return Icons.Share;
    case IconType.Calendar:
      return Icons.Calendar;
    case IconType.TeardropEmpty:
      return Icons.TeardropEmpty;
    case IconType.TearDrop:
      return Icons.Teardrop;
    case IconType.ArrowLeft:
      return Icons.ArrowLeft;
    case IconType.ArrowUp:
      return Icons.ArrowUp;
    case IconType.ArrowDown:
      return Icons.ArrowDown;
    case IconType.InfoEvent:
      return Icons.InfoEvent;
    case IconType.Message:
      return Icons.Message;
    case IconType.MessageMute:
      return Icons.MessageMute;
    case IconType.Mail:
      return Icons.Mail;
    case IconType.GrayMail:
      return Icons.GrayMail;
    case IconType.ChatDesktop:
      return Icons.ChatDesktop;
    case IconType.ChatMobile:
      return Icons.ChatMobile;
    case IconType.FilmPlay:
      return Icons.FilmPlay;
    case IconType.YoutubeIcon:
      return Icons.YoutubeIcon;
    case IconType.SpotifyIcon:
      return Icons.SpotifyIcon;
    case IconType.EnlargeIcon:
      return Icons.EnlargeIcon;
    case IconType.QuestionMark:
      return Icons.QuestionMark;
    case IconType.Mic:
      return Icons.Mic;
    case IconType.MicMute:
      return Icons.MicMute;
    case IconType.Talk:
      return Icons.Talk;
    case IconType.UsersTalk:
      return Icons.UsersTalk;
    case IconType.TalkStop:
      return Icons.TalkStop;
    case IconType.Speaker:
      return Icons.Speaker;
    case IconType.SpeakerMute:
      return Icons.SpeakerMute;
    case IconType.UsersTalkStop:
      return Icons.UsersTalkStop;
    case IconType.TipIcon:
      return Icons.TipIcon;
    case IconType.ChatDesktopUnread:
      return Icons.ChatDesktopUnread;
    case IconType.ChatMobileUnread:
      return Icons.ChatMobileUnread;
    default:
      return Icons.ShortLogo;
  }
}
