import { Ratings } from 'biim-api';
import { useCallback } from 'react';

import { useDispatch } from 'hooks';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils';

import { ActionType, postBiimRating } from 'store/events';

export function usePostBiimRatings(): OperationResult<
  null,
  (uuidEvent: string, rating: Ratings) => void
    > {

  const dispatch = useDispatch();
  const [{ loading, error }] = useActivity(ActionType.POST_RATING_BIIM);
  
  const handler = useCallback(
    (uuidEvent: string, rating: Ratings) => {
          dispatch(postBiimRating(uuidEvent, rating));
            console.log(uuidEvent, rating, 'useHook');
    },
    [dispatch]
  );
 
  return [{ loading, error, data: null }, handler];
}
