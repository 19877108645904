import initialState from './initialState';
import { ActionType, Actions, State } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionType.LOGIN: {
			return {
				...state,
				session: action.payload,
			};
		}

		case ActionType.REFRESH_TOKEN: {
			const { access } = action.payload;

			if (state.session) {
				return {
					...state,
					session: {
						...state.session,
						token: access,
					},
				};
			}

			return state;
		}

		case ActionType.LOGOUT: {
			return {
				...initialState,
				session: null,
			};
		}

		default: {
			return state;
		}
	}
};
