import { User, UserInput } from '../../api';
import { AuthRequestConfig } from '../../generics';
import { axios, buildUrl, serializeInput } from '../../utils';

const endpoints = {
	getUser: () => `/user`,
	getSpecificUser: (userId: number) => `/user/${userId}`,
	createUser: () => '/user',
	updateUser: (id: number) => `/user/${id}`
};

export default ({ accessToken }: AuthRequestConfig) => ({
	getUser: async function(userId: number) {
		const url = buildUrl(endpoints.getUser());
		const params = serializeInput({ 'user-id': userId });
		const { data } = await axios.get<User>(url, { params, token: accessToken });
		return data;
	},
	createUser: async function(input: UserInput) {
		const url = buildUrl(endpoints.createUser());
		const { data } = await axios.post(url, input, { token: accessToken });
		return data;
	},
	updateUser: async function(id: number, input: UserInput) {
		const url = buildUrl(endpoints.updateUser(id));
		const { data } = await axios.put(url, input, { token: accessToken });
		return data;
	},
	getUserById: async function(userId: number) {
		const url = buildUrl(endpoints.getSpecificUser(userId));
		const { data } = await axios.get(url, { token: accessToken });
		return data;
	}
});
