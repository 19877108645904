import styled from 'styled-components/macro';
import { Layers } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 4rem;
	width: 4rem;
`;

interface BarProps {
	open: boolean;
}

export const Bar = styled.span<BarProps>`
	background: black;
	border-radius: 2rem;
	height: 0.4rem;
	position: relative;
	transition: opacity 0.2s, transform 0.4s;
	width: 75%;
	z-index: ${Layers.menu.icon};

	:first-of-type {
		margin-bottom: 0.6rem;
		transform: ${({ open }) => open && 'rotateZ(45deg) translate3d(8.5px, 8px, 0)'};
	}

	:nth-of-type(2) {
		opacity: ${({ open }) => (open ? 0 : 1)};
	}

	:last-of-type {
		margin-top: 0.6rem;
		transform: ${({ open }) => open && 'rotateZ(-45deg) translate3d(6px, -6px, 0)'};
	}
`;
