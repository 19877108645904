import { AdminBiims } from 'biim-api';
import { useCallback } from 'react';

import { useDispatch, useSelector } from 'hooks';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils';
import { ActionType, getAdminBiims, selectAllAdminBiims } from 'store/events';

export function useGetAdminBiimsEvent(): OperationResult<AdminBiims, () => void> {
	const dispatch = useDispatch();
	const [{ loading, error }] = useActivity(ActionType.GET_ADMIN_BIIMS);

	const data = useSelector((state) => selectAllAdminBiims(state.event));

	const handler = useCallback(() => {
		dispatch(getAdminBiims());
	}, [dispatch]);

	return [{ loading, error, data }, handler];
}
