// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styled from 'styled-components';

export const title = `
  text-transform: uppercase;
  font-size: 1.2rem !important;
  margin-bottom: 1.3rem;
  color: #fff;
`;

export const heading = `
  text-transform: uppercase;
  font-size: 1.5rem !important;
  margin-bottom: 1.3rem;
  color: #fff;
`;

export const StyledPreviewGroup = styled.div`
  margin-bottom: 2.5rem;
`;

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  height: auto;
  padding: 1rem 0 3rem 0;
  color: #fff;
  > * {
    flex-basis: 50%;
  }

  label {
    color: #ffffff !important;
    font-size: 1.2rem;
    line-height: 1.43;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 2.5rem 0 6rem 0;

    > * {
      flex-basis: 50%;
    }
    label {
      color: #ffffff !important;
      font-size: 1.5rem;
      line-height: 1.43;
    }

    @media (max-height: 800px) {
      padding: 2rem 0 2rem;
    }
  }
`;

export const StyledAudioGroup = styled.div`
  padding: 0 3rem 0 0;
  border-right: 0.125rem solid #e6e6e6;

  @media (max-width: 900px) {
    padding: 2rem 1rem 2rem 0;
  }
`;

export const StyledVideoGroup = styled.div`
  padding: 0 0 0 3rem;

  @media (max-width: 900px) {
    padding: 2rem 0rem 2rem 1rem;
  }
`;

export const StyledInputGroup = styled.div`
  margin-bottom: 1.5rem;
`;

export const StyledLayout = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: 2rem;
  margin: auto;

  @media (max-width: 768px) {
    border-right: unset;
    align-items: unset;
    justify-content: unset;
  }
`;