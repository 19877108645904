import likeIcon from './like.svg';
import loveIcon from './love.svg';
import hahaIcon from './haha.svg';
import wowIcon from './wow.svg';
import sadIcon from './sad.svg';
import clapIcon from './clap.svg';

export const Icons = {
    likeIcon,
    loveIcon,
    hahaIcon,
    wowIcon,
    sadIcon,
    clapIcon
}