import styled from 'styled-components';
import {MediaQueries} from "../../environment";


export const ReactionBar = styled.div`
  position: absolute;
  left: 2rem;
  bottom: 10px;
  .reaction-bar-icon{
    width: 2rem;
    height:2rem;
  }
   @media ${MediaQueries.phone} {
     bottom: 4rem;
     width: 100%;
     left: 0;
   }
  @media ${MediaQueries.phone} {
    .reaction-bar-icon{
      width: 2rem;
      height: 2rem;
    }
  }
`;
