import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import { CountdownToEvent, BiimCard } from 'components/displayers';
import { PrimaryButton } from 'components/widgets';
import { formatDate } from 'helpers';
import {
  useEffectOnce,
  useEventContext,
  useGetRelatedBiims,
  useMediaQuery,
  useNavbarColorContext,
} from 'hooks';
import WaitingAnimation from '../../../components/loaders/WaitingAnimation';
import BgMusicPlay from '../../../components/loaders/BgMusic/BgMusic';
import { BaseModal } from 'components/widgets';
import {
  ContainerWrapper,
  Container,
  QueueDate,
  QueueContent,
  QueueContentTitle,
  QueueDateContainer,
  QueueCreatorImage,
  QueueCreatorContent,
  QueueCreatorTitle,
  QueueCreatorSubtitle,
  QueueCreator,
  QueueCreatorSubtitleContainer,
  Logo,
  LogoContainer,
  ContentSpotify,
  CloseSidePannel,
  ContentYoutube,
  ModalContent,
  BackgroundMusicWrap,
  IconsWrap,
  CreatorYoutubeInfo,
  CreatorYoutube,
  CreatorMsgVideoText,
  CreatorMsgVideo,
  SubHeading,
  Heading,
  CreatorMsg,
} from './Guest.styles';
import { Related, RelatedTitle, RelatedWrapper } from '../commonStyles.style';
import { EventInfo } from './info';
import { MediaQueries } from '../../../environment';
import styled from 'styled-components';
import { Icon } from '../../../components/icons';

export function GuestBeforeEventQueue() {
  const { eventData: data } = useEventContext();
  const relatedBiims = useGetRelatedBiims(data.relatedProducts);
  const { DO_MMMM_YYYY_24H, EUROPE_LONDON_DATE } = formatDate(data.availabilityDate);
  const { type, switchColor } = useNavbarColorContext();
  const [minutesToGo, setMinutesToGo] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);
  const [isOpenYoutube, setOpen] = useState(false);
  const [isOpenSpotify, setOpenSpotify] = useState(false);
  const [isMusicStart, setIsMusicStart] = useState(true);

  useEffectOnce(() => {
    if (type === 'dark') switchColor('colored');
  });

  function openInNewTab(url?: string) {
    if (url) {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  }

  const diffMinutes = () => {
    const d2 = new Date(data.availabilityDate);
    const d1 = new Date();

    const diffMs = +d2 - +d1;
    const diffMins = Math.floor(diffMs / 1000 / 60);
    setMinutesToGo(diffMins);
    return diffMins;
  };

  useEffect(() => {
    diffMinutes();
    const interal = setInterval(() => {
      diffMinutes();
    }, 60000);

    return () => {
      clearInterval(interal);
    };
  }, []);

  const onClickYoutube = () => {
    setOpen(true);
    setOpenSpotify(false);
    setIsMusicStart(false);
  };

  const onClickSpotify = () => {
    setOpen(false);
    setOpenSpotify(true);
    setIsMusicStart(false);
  };

  const onClickCreatorVideo = () => {
    setOpen(false);
    setOpenSpotify(false);
    setIsMusicStart(false);
    setShowModal(true);
  };

  const isSmallerPhone = useMediaQuery(MediaQueries.smallerPhone);

  return (
    <ContainerWrapper>
      <Container>
        <BackgroundMusicWrap>
          <BgMusicPlay
            musicStart={isMusicStart}
            spotifyOpen={isOpenSpotify}
            youtubeOpen={isOpenYoutube}
            handler={setIsMusicStart}
            url={data.creatorWaitingRoomBackgroundMusicTrackUrl}
          />
          <EventInfo
            image={data.images[0].src}
            title={data.title}
            creatorName={data.creatorName}
            info={data.description}
            date={data.availabilityDate}
          />
        </BackgroundMusicWrap>
        {minutesToGo <= 0 && (
          <CreatorMsg>
            <SubHeading>STAY TUNED</SubHeading>
            <Heading>Stream Starting Soon.</Heading>
          </CreatorMsg>
        )}
        <CreatorMsgVideo onClick={onClickCreatorVideo}>
          <Icon type={(t) => t.FilmPlay} color="undefined" />
          <CreatorMsgVideoText>A Message from {data.creatorName}</CreatorMsgVideoText>
        </CreatorMsgVideo>

        <CreatorYoutube>
          <CreatorYoutubeInfo>
            Until session start , I would like to invite you to check my latest videos on Youtube /
            Spotify
          </CreatorYoutubeInfo>
          <IconsWrap>
            <Icon
              type={(t) => t.YoutubeIcon}
              size={4}
              color="undefined"
              onClick={() => onClickYoutube()}
            />
            <Icon
              type={(t) => t.SpotifyIcon}
              size={3}
              color="undefined"
              onClick={() => onClickSpotify()}
              margin={{ left: isSmallerPhone ? 0.5 : 0 }}
            />
          </IconsWrap>
        </CreatorYoutube>

        {/*side pannel youtube*/}

        <ContentYoutube isOpenYoutube={isOpenYoutube}>
          <CloseSidePannel>
            <Icon type={(t) => t.Close} size={4} color="undefined" onClick={() => setOpen(false)} />
          </CloseSidePannel>

          <div className="">
            {isOpenYoutube && (
              <iframe
                width="100%"
                height="445"
                src={`https://www.youtube.com/embed/?listType=playlist&list=${data.creatorYoutubePlaylistId}&autoplay=1&loop=1&playsinline=1&showinfo=1`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </ContentYoutube>
        <ContentSpotify isOpenSpotify={isOpenSpotify}>
          <CloseSidePannel>
            <Icon
              type={(t) => t.Close}
              size={4}
              color="undefined"
              onClick={() => setOpenSpotify(false)}
            />
          </CloseSidePannel>
          <div className="spotify-player">
            {isOpenSpotify && (
              <iframe
                src={`https://open.spotify.com/embed/artist/${data.creatorSpotifyPlaylistId}?utm_source=generator`}
                width="100%"
                height="352"
                frameBorder="0"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            )}
          </div>
        </ContentSpotify>

        <BaseModal
          open={showModal}
          onSubmit={() => null}
          onClose={() => setShowModal(false)}
          title={`- Thank You - `}
        >
          <ModalContent>
            <span>
              {data.creatorThankYouMessage}
              <br />
              <br /> - {data.creatorName} -<br />
            </span>
            <video controls style={{ width: '100%' }}>
              <source src={data.creatorThankYouVideoUrl} type="video/mp4" />
              Sorry, your browser does not support embedded videos.
            </video>
          </ModalContent>
        </BaseModal>

        <QueueContent>
          <LogoContainer>
            <Logo color="undefined" type={(t) => t.Logo} size={30} />
          </LogoContainer>
          <QueueContentTitle>The Event Starts In</QueueContentTitle>
          <CountdownToEvent date={EUROPE_LONDON_DATE} queue />
          <QueueDateContainer>
            <QueueDate>{DO_MMMM_YYYY_24H}</QueueDate>
          </QueueDateContainer>
          <QueueCreatorContent>
            <QueueCreatorImage src={data.creatorImage} />
            <QueueCreator>
              <QueueCreatorTitle>{data.creatorName}</QueueCreatorTitle>
              <QueueCreatorSubtitleContainer>
                <QueueCreatorSubtitle>{data.title}</QueueCreatorSubtitle>
              </QueueCreatorSubtitleContainer>
            </QueueCreator>
          </QueueCreatorContent>
          {minutesToGo > 42 && (
            <Button onClick={() => openInNewTab(data.permalink)}>Buy more Tickets</Button>
          )}
          <Heading>*Close all other conferencing tools before using BIIM</Heading>
          <WaitingAnimation />
        </QueueContent>
      </Container>
      <RelatedWrapper>
        <RelatedTitle style={{ position: 'relative' }}>You might also like</RelatedTitle>
        <Related style={{ position: 'relative' }}>
          {relatedBiims &&
            relatedBiims.map((biim, idx) => {
              return (
                <BiimCard
                  key={`${biim?.wooProductId} - ${idx}`}
                  margin={{ right: 2 }}
                  availability={biim?.availabilityDate ?? ''}
                  creatorName={biim?.creatorName ?? ''}
                  title={biim?.title ?? ''}
                  imgSrc={biim?.images[0].src ?? ''}
                  profileImgSrc={biim?.creatorImage ?? ''}
                  price={biim?.price ?? 0}
                  permalink={biim?.permalink ?? ''}
                  stock={biim?.stockInitial ?? 0}
                  remainingStock={biim?.stockRemaining ?? 0}
                />
              );
            })}
        </Related>
      </RelatedWrapper>
    </ContainerWrapper>
  );
}
const Button = styled(PrimaryButton)`
  width: 32.4rem;
  @media ${MediaQueries.tabletMax} {
    width: 20rem;
    padding: 0 1.4rem;
    min-height: 4rem;
    height: 4rem;
    span {
      font-size: 1.5rem;
    }
  }
`;
