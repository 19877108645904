import { createGlobalStyle } from 'styled-components/macro';

import { MediaQueries } from './sizes';

export const GlobalStyle = createGlobalStyle`
    * {
        font-variant-numeric: proportional-nums;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html {
        font-size: 62.5%;
        overflow: overlay; // temporary fix (this property is deprecated and has other issues but it's a lot better than before)
        @media ${MediaQueries.qhd} {
            font-size: 0.65vw;
        }
    }

    html > body {
        margin: 0;
    }

    h1 {
        font-family: Comfortaa, sans-serif;
        font-size: 3.8rem;
        font-weight: 600;
    }

    h2 {
        font-family: Comfortaa, sans-serif;
        font-size: 3.2rem;
        font-weight: 600;
    }

    h3 {
        font-family: Comfortaa, sans-serif;
        font-size: 2.6rem;
        font-weight: 600;
    }

    h4 {
        font-family: Comfortaa, sans-serif;
        font-size: 2.2rem;
        font-weight: 600;
    }

    h5 {
        font-family: Comfortaa, sans-serif;
        font-size: 1.8rem;
        font-weight: 600;
    }

    h6 {
        font-family: Comfortaa, sans-serif;
        font-weight: 600;
        font-size: 1.4rem;
    }

    p {
        font-family: Helvetica Neue, sans-serif;
        font-size: 1.2rem;
        line-height: 2rem;
    }

    a {
        font-family: Helvetica Neue, sans-serif;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.4rem;
        outline: none;
        text-decoration: none;
        transition: color 0.2s;

        :not(:disabled) {
            :focus,
            :hover {
                color: green;
            }
        }
    }

    label {
        font-family: 'Open Sans', sans-serif;
        font-size: 1.6rem;
    }

    span {
        font-family: 'Open Sans', sans-serif;
        font-size: 1.2rem;
        line-height: 1.6rem;
    }

    text, th, td {
        font-family: 'Open Sans', sans-serif; 
    }

    button {
        font-family: 'Open Sans', sans-serif;
        font-size: 1.6rem;
        font-weight: 600;
    }

    input, textarea {
        font-family: 'Open Sans', sans-serif;
        font-size: 1.6rem;
    }
`;
