import { useLayoutEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface Options {
	wait?: number;
	on?: boolean;
}

export function useMeasure<T extends HTMLElement>({ on = true }: Options = {}): [
	React.RefObject<T>,
	{ left: number; top: number; right: number; bottom: number; width: number; height: number }
] {
	const ref = useRef<T>(null);
	const [bounds, setBounds] = useState({
		left: 0, // same as x
		top: 0, // same as y
		right: 0,
		bottom: 0,
		width: 0,
		height: 0,
	});

	const [ro] = useState(
		() => new ResizeObserver(([entry]: ResizeObserverEntry[]) => setBounds(entry.contentRect))
	);

	useLayoutEffect(() => {
		if (on && ref.current) {
			ro.observe(ref.current);
		}

		return () => ro.disconnect();
	}, [on, ro]);

	return [ref, bounds as DOMRect];
}
