import { Colors } from 'environment';
import React from 'react';

import { BaseButton, ButtonProps } from './BaseButton';

export function SecondaryButton(props: ButtonProps) {
	return (
		<BaseButton
			{...props}
			backgroundColor="rgba(0,0,0,0.8)"
			color={props.color ?? '#DADADA'}
			boxShadow="0px 2px 6px rgba(0, 0, 0, 0.25), inset 0px 2px 6px rgba(255, 255, 255, 0.25)"
			hoverColor={Colors.black}
		/>
	);
}
