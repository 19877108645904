import { Colors } from 'environment';
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
`;

export const Outer = styled.div`
	width: 1.5rem;
	height: 1.5em;
	border-radius: 5rem;
	padding: 2px;
	border: 1px solid ${Colors.lightBlue};
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.7rem;
`;

export const Circle = styled.div`
	width: 0.7rem;
	height: 0.7rem;
	border-radius: 3rem;
	background-color: ${Colors.lightBlue};
`;

export const Title = styled.span<{ selected: boolean }>`
	font-family: 'Comfortaa', sans-serif;
	font-size: 1.2rem;
	font-weight: ${({ selected }) => selected && 600};
	line-height: 2rem;
	color: ${Colors.white};
`;
