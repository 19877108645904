import React from 'react';

import { useDelayedUnmount } from 'hooks';

import { Block, BlockProps } from './Animated.style';

export function Animated() {
	return null;
}

interface HidingBlockProps extends BlockProps {
	children: React.ReactNode;
	visible: boolean;
}

function HidingBlock({ children, visible, ...props }: HidingBlockProps) {
	const rendered = useDelayedUnmount(visible);

	if (!rendered) return null;
	return (
		<Block {...props} style={{}}>
			{children}
		</Block>
	);
}

Animated.Block = Block;
Animated.HidingBlock = HidingBlock;
