import { useEffect, useState } from 'react';

import { BiimCard, Layout } from 'components/displayers';
import { PageContainer } from 'components/layout';
import { SearchButton, SearchDropdown } from 'components/widgets';
import { Colors, MediaQueries } from 'environment';
import { useEffectOnce, useGetAdminBiimsEvent, useGetSearchAdminBiimsEvent } from 'hooks';
import { GridProvider } from 'router/GridProvider';
import { useNavbarColorContext } from 'hooks/context/useNavbarColorContext';

import { CardDataProps, EditBiimModal } from './EditBiimModal';
import {
  SearchBar,
  SearchContent,
  SearchSubtitle,
  SearchTitle,
  Subtitle,
  Title,
  TitleContainer,
} from './BiimsPage.style';

export function BiimsPage() {
  const [search, setSearch] = useState('');
  const [textSearched, setTextSearched] = useState('');
  const [editBiim, setEditBiim] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState<CardDataProps>({
    id: 0,
    img: '',
    price: '',
    stock: '',
    stockLeft: '',
    uuidCreator: '',
    uuidModerator: '',
    title: '',
    uuidGuest: '',
    status: 0,
    eventType: 0,
    permalink: '',
    creatorEmail: '',
    moderatorEmail: '',
    date: '',
  });

  const { type, switchColor } = useNavbarColorContext();

  const [, getBiims] = useGetAdminBiimsEvent();
  const data = useGetSearchAdminBiimsEvent(textSearched);

  useEffectOnce(() => {
    getBiims();
    if (type === 'colored') switchColor('dark');
  });

  useEffect(() => {
    if (data.find((biim) => biim.id === selectedCardData.id)?.status !== selectedCardData.status) {
      setSelectedCardData({
        ...selectedCardData,
        status:
          data.find((biim) => biim.id === selectedCardData.id)?.status ?? selectedCardData.status,
      });
    }
  }, [data]);

  function handleSelectTitle(item: string) {
    setSearch(item.split(' | ')[0]);
    setTextSearched(item.split(' | ')[0]);
  }

  function clearSelected() {
    setSearch('');
    setTextSearched('');
  }

  function handleKeyDown(event: any) {
    return event.key === 'Enter' && setTextSearched(search);
  }

  function handleEditBiim(cardData: CardDataProps) {
    setSelectedCardData(cardData);
    setEditBiim(true);
  }

  function handleOnSearchButton() {
    setTextSearched(search);
  }

  function handleCloseModal() {
    setSelectedCardData({
      id: 0,
      img: '',
      price: '',
      stock: '',
      stockLeft: '',
      status: 0,
      title: '',
      eventType: 0,
      uuidCreator: '',
      uuidModerator: '',
      uuidGuest: '',
      permalink: '',
      creatorEmail: '',
      moderatorEmail: '',
      date: '',
    });
    setEditBiim(false);
  }

  return (
    <PageContainer>
      <TitleContainer>
        <Title>Welcome back, biim admin</Title>
      </TitleContainer>
      <SearchContent>
        <SearchTitle>Create or edit a biim&nbsp;box</SearchTitle>
        <SearchSubtitle>Search for your biim by creator&nbsp;or&nbsp;title</SearchSubtitle>
        <SearchBar>
          <SearchDropdown<string>
            name="Search"
            suggestions={data.map((d) => `${d.title} | ${d.creatorName}`)}
            title={(c) => c || 'Title'}
            onSelect={handleSelectTitle}
            onClearClick={clearSelected}
            value={search}
            onKeyDown={(e) => handleKeyDown(e)}
            margin={{ right: 3, bottom: MediaQueries.phone ? 1.5 : 0 }}
            placeholder="Enter a creator or title..."
            onChange={(e) => setSearch(e.target.value)}
            flex={1}
          />
          <SearchButton
            width={MediaQueries.phone ? 'auto' : 35.1}
            backgroundColor={Colors.white}
            onClick={handleOnSearchButton}
          >
            Search biims
          </SearchButton>
        </SearchBar>
      </SearchContent>
      <Subtitle>All biims</Subtitle>
      <GridProvider>
        <Layout.Grid>
          {data &&
            data.map((biim, idx) => (
              <BiimCard
                key={`${biim?.id}-${idx}`}
                margin={{ right: 0 }}
                availability={biim?.availabilityDate ?? ''}
                creatorName={biim?.creatorName ?? ''}
                title={biim?.title ?? ''}
                imgSrc={biim?.images[0]?.src ?? ''}
                profileImgSrc={biim?.creatorImage ?? ''}
                price={biim?.price ?? 0}
                onClick={() =>
                  handleEditBiim({
                    id: biim?.id,
                    img: biim?.images[0].src,
                    date: biim?.availabilityDate,
                    price: biim?.price.toString(),
                    title: biim?.title,
                    uuidCreator: biim?.uuidCreator,
                    uuidModerator: biim?.uuidModerator,
                    uuidGuest: biim?.uuidGuest,
                    permalink: biim?.permalink,
                    creatorEmail: biim?.creatorEmail,
                    moderatorEmail: biim?.moderatorEmail,
                    status: biim?.status,
                    eventType: biim?.eventType,
                    stock: biim?.stockInitial.toString(),
                    stockLeft: biim.stockRemaining.toString(),
                  })
                }
                stock={biim?.stockInitial ?? 0}
                remainingStock={biim?.stockRemaining ?? 0}
              />
            ))}
        </Layout.Grid>
      </GridProvider>
      <EditBiimModal open={editBiim} onClose={handleCloseModal} cardData={selectedCardData} />
    </PageContainer>
  );
}
