import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Colors } from 'environment';
import { EventManager } from 'features/event';
import {
  EventContext,
  useEffectOnce,
  useGetLiveBiim,
  useNavigation,
  useSocketsContext,
  useUpdateLiveBiimEventUuid,
} from 'hooks';

export function EventPage() {
  const [{ data: eventData, error }, getEventData] = useGetLiveBiim();
  const [, updateBiimUuid] = useUpdateLiveBiimEventUuid();

  const { pathname } = useLocation();
  const { replace } = useNavigation();
  const { socket } = useSocketsContext();

  useEffectOnce(() => {
    if (!eventData.uuidEvent) getEventData(pathname.split('/')[1]);
  });

  if (error) {
    replace('/');
  }

  useEffect(() => {
    socket.on('event_refreshed', ({ idEvent, uuidEvent, _, newUUID }) => {
      console.log('Event_refreshed data', { eventData }, 'socket', uuidEvent);
      updateBiimUuid(idEvent, newUUID);
    });

    return () => {
      socket.off('event_refreshed', (args) => {
        console.log('socket event_refreshed off', { args });
      });
    };
  }, [socket]);

  console.log('render event page');
  return (
    <EventContext.Provider value={{ eventId: pathname.split('/')[1], eventData }}>
      <Container>
        <EventManager />
      </Container>
    </EventContext.Provider>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  background: ${Colors.darkest};
  justify-content: center;
  overflow-x: hidden;
`;
