import { AdminBiimInput } from 'biim-api';
import { useCallback } from 'react';

import { useDispatch } from 'hooks';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils';

import { ActionType, updateBiim } from 'store/events';

export function useUpdateBiim(): OperationResult<null, (id: number, biim: AdminBiimInput) => void> {
	const dispatch = useDispatch();
	const [{ loading, error }] = useActivity(ActionType.UPDATE_BIIM);

	const handler = useCallback(
		(id: number, biim: AdminBiimInput) => {
			dispatch(updateBiim(id, biim));
		},
		[dispatch]
	);

	return [{ loading, error, data: null }, handler];
}
