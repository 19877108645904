import { API, graphqlOperation } from 'aws-amplify';
import {
	createAttendeeGraphQL,
	createMeetingGraphQL,
	deleteAttendeeGraphQL,
	deleteMeetingGraphQL,
	updateAttendeeGraphQL,
} from '../graphql/mutations';
import {
	createChimeMeeting,
	getAttendee,
	endChimeMeeting,
	getMeeting,
	joinChimeMeeting,
	deleteChimeAttendee,
} from '../graphql/queries';

export async function createMeeting(title: string, attendeeName: string, region: string) {
	const joinInfo: any = await API.graphql(
		graphqlOperation(createChimeMeeting, { title, name: attendeeName, region })
	);

	const joinInfoJson = joinInfo.data.createChimeMeeting;
	const joinInfoJsonParse = JSON.parse(joinInfoJson.body);
	return joinInfoJsonParse;
}

export async function joinMeeting(meetingId: string, name: string, uniqueId?: string) {
	const joinInfo: any = await API.graphql(
		graphqlOperation(joinChimeMeeting, { meetingId, name, uniqueId })
	);
	const joinInfoJson = joinInfo.data.joinChimeMeeting;
	const joinInfoJsonParse = JSON.parse(joinInfoJson.body);
	return joinInfoJsonParse;
}

export async function endMeeting(meetingId: string) {
	const endInfo: any = await API.graphql(graphqlOperation(endChimeMeeting, { meetingId }));
	const endInfoJson = endInfo.data.endChimeMeeting;
	await API.graphql(graphqlOperation(deleteMeetingGraphQL, { input: { title: meetingId } }));
	return endInfoJson;
}

export async function deleteAttendee(attendeeId: string, meetingId: string) {
	const deleteInfo: any = await API.graphql(
		graphqlOperation(deleteChimeAttendee, { attendeeId, meetingId })
	);

	await API.graphql(graphqlOperation(deleteAttendeeGraphQL, { input: { attendeeId } }));

	return deleteInfo;
}

export async function addMeetingToDB(title: string, meetingId: string, data: string) {
	await API.graphql(
		graphqlOperation(createMeetingGraphQL, {
			input: { title, meetingId, data },
		})
	);
}

export async function addAttendeeToDB(
	attendeeId: string,
	name: string,
	chatPermissions = 'allowed',
	meetingPermissions = 'allowed'
) {
	await API.graphql(
		graphqlOperation(createAttendeeGraphQL, {
			input: { attendeeId, name, chatPermissions, meetingPermissions },
		})
	);
}

export async function updateAttendeeToDB(
	attendeeId: string,
	chatPermissions?: string,
	meetingPermissions?: string
) {
	const attendeeInfo = await API.graphql(
		graphqlOperation(updateAttendeeGraphQL, {
			input: { attendeeId, chatPermissions, meetingPermissions },
		})
	);
	return attendeeInfo;
}

export async function getMeetingFromDB(title: string) {
	const meetingInfo = await API.graphql(graphqlOperation(getMeeting, { title }));
	return meetingInfo;
}

export async function getAttendeeFromDB(attendeeId: string) {
	const attendeeInfo = await API.graphql(
		graphqlOperation(getAttendee, { attendeeId: attendeeId })
	);
	return attendeeInfo;
}
