import { createContext, useContext } from 'react';

interface UIContextValue {
  runtime: any;
  setRuntime: (eventRunime: string, eventDateTime: string) => void;
  isVideoEnabled: boolean;
  showLiveDot: (isLive: boolean) => void;
  attendeeCount: number;
  setAttendeeCount: (attendees: any) => void;
  isEnabled: boolean;
  setIsEnabled: (enabled: boolean) => void;
  guestView: boolean;
  setGuestView: (enabled: boolean) => void;
  guestChat: string;
  setGuestChat: (chatWindow: any) => void;
  userType: string;
  setUserType: (chatWindow: any) => void;
}

export const NavbarUIContext = createContext<UIContextValue>({
  runtime: null,
  setRuntime: () => null,
  isVideoEnabled: false,
  showLiveDot: () => null,
  attendeeCount: 0,
  setAttendeeCount: () => null,
  isEnabled: false,
  setIsEnabled: () => null,
  guestView: false,
  setGuestView: () => null,
  guestChat: 'Chat',
  setGuestChat: () => null,
  userType: '',
  setUserType: () => null,
});

export function useNavbarUIContext() {
  const context = useContext(NavbarUIContext);
  return context;
}
