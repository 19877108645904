import { axios } from 'biim-api';
import { AxiosError } from 'axios';

import { ErrorCode } from 'consts';
import { api, store } from 'store';
import { logout, refreshToken as refreshTokenState } from 'store/auth';
import { Nullable } from 'types';

async function refresh() {
	const {
		auth: { session },
	} = store.getState();

	if (session) {
		const token = await api.auth({ accessToken: session.token }).refreshToken(session.refresh);
		return token;
	}
}

let queue: Array<{ resolve: (value: any) => void; reject: (reason: any) => void }> = [];

function processQueue(err: Nullable<Error>, token: Nullable<string> = null) {
	queue.forEach((promise) => {
		if (token) {
			promise.resolve(token);
		} else {
			promise.reject(err);
		}
	});

	queue = [];
}

axios.interceptors.response.use(
	(response) => response,
	async (error: AxiosError) => {
		if (error.response) {
			if (error.response.data.hint === ErrorCode.TokenNotValid) {
				const originalRequest = error.config;

				return new Promise((resolve, reject) => {
					refresh()
						.then((token) => {
							if (token) {
								axios.defaults.headers.Authorization = `Bearer ${token}`;
								originalRequest.headers.Authorization = `Bearer ${token}`;
								store.dispatch(refreshTokenState(token));
								resolve(axios(originalRequest));
							}
						})
						.catch((err) => {
							processQueue(err);
							reject(err);
							store.dispatch(logout());
						});
				});
			}
		}

		if (error.response && error.response.data.message) {
			return Promise.reject(new Error(error.response.data.message));
		}

		return Promise.reject(error);
	}
);
