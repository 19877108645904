import styled from 'styled-components';
import { PrimaryButton } from '../index';
import { MediaQueries } from 'environment';

export const Wrapper = styled.section`
  padding: 0.5em;
  //height: 64px;
  .react-simple-star-rating-tooltip {
    font-family: Comfortaa;
    margin-left: 50px !important;
    @media ${MediaQueries.phone} {
      margin-left: 5px !important;
    }
  }

  .rating-tooltip-biim {
    display: inline-block;
    background-color: rgb(28 30 37);
    color: rgb(255, 255, 255);
    margin-left: -81px;
    margin-top: 38px;
    vertical-align: middle;
    border-radius: 5px;
    font-size: 0.9em;
    height: 16px;
  }
`;

export const MainWrapper = styled.section`
  align-items: center;

  .react-simple-star-rating-tooltip {
    background-color: #fff !imporatant;
    font-size: 1em;
  }
`;

export const Description = styled.h4`
    color: #ff47b1;
    font-family: Comfortaa,sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
`;

export const Lable = styled.span`
  font-size: 1.2em;
  font-family: Comfortaa;
  color: #fff;
  display: inline-block;
  width: 100px;
  text-align: left;
  margin-right: 30px;
}
`;

export const RTButton = styled(PrimaryButton)`
    min-height: 3.1rem;
    min-width: 9.2rem;
    height: 3.1rem !important;
    margin: 0 auto;
`;

export const ButtonContainer = styled.div`
  position: relative;
  text-align: center;
  margin-top: 1rem;
`;

export const Center = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;
