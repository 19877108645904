import React, { useEffect, useState } from 'react';

import { PlatformContext } from 'hooks';

interface Props {
  children: React.ReactElement;
}

export function Platform({ children }: Props) {
  const [isDesktop, setIsDesktop] = useState(false);
  // eslint-disable-next-line
  const [isTv] = useState(false);
  // const match = useMatch<RouteParams>({
  // 	'/:workspace/tv',
  // 	end: true,
  // 	caseSensitive: false
  // });
  // eslint-disable-next-line
  // useEffect(() => {
  // 	setIsTv(!!match || navigator.userAgent.includes('BiimTV'));
  // }, [match, navigator.userAgent]);

  useEffect(() => {
    setIsDesktop(!isTouchDevice());
  }, [navigator.userAgent]);

  function isTouchDevice() {
    return 'ontouchstart' in window || 'onmsgesturechange' in window;
  }

  return (
    <PlatformContext.Provider value={{ isDesktop, isTv }}>{children}</PlatformContext.Provider>
  );
}
