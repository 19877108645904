import { useCallback } from 'react';

import { useDispatch, useSelector } from 'hooks';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils';

import { ActionType, getBiims, selectAllBiims } from 'store/events';
import { Biims } from 'biim-api';

export function useGetBiimsEvent(): OperationResult<Biims, () => void> {
	const dispatch = useDispatch();
	const [{ loading, error }] = useActivity(ActionType.GET_BIIMS);

	const data = useSelector((state) => selectAllBiims(state.event));

	const handler = useCallback(() => {
		dispatch(getBiims());
	}, [dispatch]);

	return [{ loading, error, data }, handler];
}
