import { EventStatus, EventType } from 'biim-api';
import { useState } from 'react';
import { DeviceLabels, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import { useAppState } from 'app';
import { ContentLoader } from 'components/loaders';
import { Alerts } from 'components/layout';
import { userColorName } from 'consts';
import { formatDate } from 'helpers';
import { addAttendeeToDB, getAttendeeFromDB, getMeetingFromDB, joinMeeting } from 'utils/api';

import {
  CreatorName,
  DateContainer,
  DateTitle,
  Subtitle,
  Block,
  BiimContainer,
  BiimTitle,
  Logo,
  LogoContainer,
} from './Guest.styles';
import {
  Container,
  Content,
  BackgroundGradient,
  BackgroundGradientLeft,
  BackgroundGradientRight,
  Button,
} from '../commonStyles.style';
import {
  useEffectOnce,
  useEventContext,
  useEventStatusContext,
  useNavbarColorContext,
  useUpdateLiveBiimAttendeeId,
} from 'hooks';

import WaitingAnimation from "../../../components/loaders/WaitingAnimation";

export function GuestBeforeEventStarted() {
  const [eventLoading, setLoading] = useState(false);

  const { eventData: data } = useEventContext();
  const [meetingId] = useState(data.uuidEvent);
  const [attendeeName] = useState(data.userName ?? data.userType);

  const { setLocalUser } = useAppState();
  const meetingManager = useMeetingManager();
  const { type, switchColor } = useNavbarColorContext();
  const [, updateAttendeeId] = useUpdateLiveBiimAttendeeId();
  const { eventId } = useEventContext();
  const { setStatus } = useEventStatusContext();

  useEffectOnce(() => {
    if (type === 'dark') switchColor('colored');
    clickedJoinMeeting();
  });

  const clickedJoinMeeting = async () => {
    setLoading(true);
    const title = meetingId.trim();
    const name = `${attendeeName.trim()}#${data.userType}`;
    setLocalUser({ name, color: userColorName });

    // Fetch the Meeting via AWS AppSync - if it exists, then the meeting has already
    // been created, and you just need to join it - you don't need to create a new meeting
    const meetingResponse: any = await getMeetingFromDB(title);
    const meetingJson = meetingResponse.data.getMeeting;

    if (meetingJson) {
      try {
        const meetingData = JSON.parse(meetingJson.data);

        let joinInfo;
        if (eventId.includes('jsi4')) {
          joinInfo = await joinMeeting(meetingData.MeetingId, name, eventId);
        } else {
          joinInfo = await joinMeeting(meetingData.MeetingId, name);
        }

        const attendeeResponse: any = await getAttendeeFromDB(joinInfo.Attendee.AttendeeId);

        updateAttendeeId(joinInfo.Attendee.AttendeeId);
        const options = {
          deviceLabels:
            data.eventType === EventType.Meet ? DeviceLabels.AudioAndVideo : DeviceLabels.None
        }
        
        const configuration = new MeetingSessionConfiguration(
          {
            MeetingId: meetingData.MeetingId,
            MediaPlacement: meetingData.MediaPlacement,
          },
          {
            ExternalUserId: joinInfo.Attendee.ExternalUserId,
            AttendeeId: joinInfo.Attendee.AttendeeId,
            JoinToken: joinInfo.Attendee.JoinToken,
          }
        );

        if (!attendeeResponse.data.getAttendee) {
          await addAttendeeToDB(joinInfo.Attendee.AttendeeId, name);
          await meetingManager.join(configuration, options);

        } else if (attendeeResponse.data.getAttendee.meetingPermissions === 'notAllowed') {
          return setStatus(EventStatus.Finished);

        } else if (attendeeResponse.data.getAttendee.meetingPermissions === 'allowed') {
          await meetingManager.join(configuration, options);

        }

        setStatus(EventStatus.DeviceSetup);
        
       // await meetingManager.start();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Alerts.error(
          'There is a problem with the live! Please try again later or contact administrators!'
        );
        console.log(error);
      }
    } else {
      setLoading(false);
      Alerts.info('Meeting not started! Please try again in a few minutes!');
    }
  };

  const { DO_MMMM_YYYY_24H } = formatDate(data.availabilityDate)

  return (
    <ContentLoader loading={eventLoading}>
      <Container style={{ justifyContent: 'center' }}>
        <BackgroundGradientLeft />
        <BackgroundGradientRight />
        <BackgroundGradient />
        <Content style={{display: "contents"}}>
          <Block align={{ baseline: true }}>
            <LogoContainer>
              <Logo color="undefined" type={(t) => t.Logo} size={30} />
            </LogoContainer>
            <Subtitle>presents</Subtitle>
            <CreatorName>{data.creatorName}</CreatorName>
            <BiimContainer>
              <BiimTitle>{data.title}</BiimTitle>
            </BiimContainer>
            <DateContainer>
              <DateTitle>
                { DO_MMMM_YYYY_24H }
              </DateTitle>
            </DateContainer>
            <Button margin={{ top: 3 }} color="rgba(255,255,255,0.4)" onClick={clickedJoinMeeting}>
              Join live biim
            </Button>
          </Block>
          {/*<Image src={data.images[0].src} />*/}
        </Content>
      </Container>
      <WaitingAnimation/>
    </ContentLoader>

  );
}
