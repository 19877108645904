import styled from 'styled-components/macro';
import { TextInput } from 'components/inputs';
import { Colors } from 'environment';

export const Container = styled.div`
  box-shadow: inset 0 7px 9px -7px ${Colors.gray};
  display: flex;
  flex: 1;
`;

export const Spacer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  padding: 0.4rem;
`;

export const Input = styled(TextInput)`
  flex: 1;

  input {
    border: none;
    min-height: 3.2rem;
    padding-left: 2rem;
    width: auto;

    ::placeholder {
      color: ${Colors.gray};
    }
  }
`;
