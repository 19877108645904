import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Colors } from 'environment';
import { PlacementProps } from 'types';

export interface StyleProps {
	$margin?: PlacementProps;
	$weight?: number;
}

interface Props extends StyleProps {
	$disabled: boolean;
	$underline: boolean;
}

export const Text = styled.p<Props>`
	color: ${({ $disabled }) => ($disabled ? Colors.darkestBrown : Colors.darkerBrown)};
	cursor: pointer;
	font-weight: ${({ $weight = 700 }) => $weight};
	margin-top: ${({ $margin }) => ($margin?.top ? `${$margin.top}rem` : 0)};
	margin-bottom: ${({ $margin }) => ($margin?.bottom ? `${$margin.bottom}rem` : 0)};
	margin-left: ${({ $margin }) => ($margin?.left ? `${$margin.left}rem` : 0)};
	margin-right: ${({ $margin }) => ($margin?.right ? `${$margin.right}rem` : 0)};
	outline: none;
	pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
	text-transform: ${({ $underline }) => ($underline ? 'underline' : 'none')};
	transition: color 0.2s;
	width: fit-content;

	:focus,
	:hover {
		color: ${({ $disabled }) => !$disabled && Colors.lightBlue};
	}
`;

export const CustomLink = styled(Link)<Props>`
	color: ${({ $disabled }) => ($disabled ? Colors.darkestBrown : Colors.darkerBrown)};
	cursor: pointer;
	font-weight: ${({ $weight = 700 }) => $weight};
	margin-top: ${({ $margin }) => ($margin?.top ? `${$margin.top}rem` : 0)};
	margin-bottom: ${({ $margin }) => ($margin?.bottom ? `${$margin.bottom}rem` : 0)};
	margin-left: ${({ $margin }) => ($margin?.left ? `${$margin.left}rem` : 0)};
	margin-right: ${({ $margin }) => ($margin?.right ? `${$margin.right}rem` : 0)};
	pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
	text-transform: ${({ $underline }) => ($underline ? 'underline' : 'none')};
	transition: color 0.2s;

	:focus,
	:hover {
		color: ${({ $disabled }) => !$disabled && Colors.purplepink};
	}
`;
