export const DEFAULT_DEBOUNCE_TIME = 300;
export const REPORTS_DEBOUNCE_TIME = 400;
export const DEFAULT_ACTIVITY_TIMEOUT = 3000;

export const TIMESTAMP_FORMAT = 'HH:mm';
export const DAY_FORMAT = 'yyyy-LL-dd';
export const CHART_TOOLTIP_TIMESTAMP_FORMAT = 'LLL d, HH:mm';
export const DEVICE_TIMESTAMP_FORMAT = 'LLL dd, yyyy HH:mm';
export const PICKER_DATE_FORMAT = 'dd/LL/yyyy';
export const NOTIFICATION_EVENTS_DAY_FORMAT = 'LLL dd yyyy';
export const NOTIFICATION_EVENT_DATE_FORMAT = 'LLLL d yyyy, HH:mm';
export const NOTIFICATION_HISTORY_TOOLTIP_DATE_FORMAT = 'LLLL dd';

export const DO_MMMM_YYYY_24H = 'Do MMMM YYYY, HH:mm z';
export const DO_MMMM_YYYY_12H = 'Do MMMM YYYY, h:mm z';
export const DD_MM_YYYY = 'DD.MM.YYYY, HH:mm z';

export const SIDEBAR_OFFSET = 64;
export const EUROPE_LONDON = 'Europe/London';

export enum TIME_ZONE {
	GMT = 'GMT',
	BST = 'BST',
}

export enum ErrorCode {
	TokenNotValid = 'refresh_token',
}

export enum DialogType {
  explanation = 'explanation',
  systemDenied = 'systemDenied',
  userDenied = 'userDenied',
  trackError = 'trackError',
}

export const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export function toOrdinal(num: number) {
	const str = num.toString();
	const lastTwoDigits = Math.abs(num % 100);
	const betweenElevenAndThirteen = lastTwoDigits >= 11 && lastTwoDigits <= 13;
	const lastChar = str.charAt(str.length - 1);

	return (
		str +
		(betweenElevenAndThirteen
			? 'th'
			: lastChar === '1'
			? 'st'
			: lastChar === '2'
			? 'nd'
			: lastChar === '3'
			? 'rd'
			: 'th')
	);
}

const nameColor = [
	'#00E2F4',
	'#9F81CA',
	'#8C8DD0',
	'#F14AB2',
	'#7A079D',
	'#2F868D',
	'#5181EB',
	'#A4E38D',
	'#BC00F4',
	'#F4D800',
	'#F46700',
];

export const userColorName = nameColor[Math.floor(Math.random() * nameColor.length)];

export enum WINDOW_METHODS {
	BEFORE_UNLOAD = 'beforeunload',
	UNLOAD = 'unload',
}

export enum ATTENDEE_NOTIFY {
	JOIN = 'join',
	LEFT = 'left',
	JOIN_CLASS = '',
	LEFT_CLASS = 'attendee-leave'
}