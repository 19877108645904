import { EventStatus } from 'biim-api';

import { GuestBeforeEventQueue } from './GuestBeforeEventQueue';
import { GuestBeforeEventStarted } from './GuestBeforeEventStarted';
import { GuestDeviceSetup } from './GuestDeviceSetup';
import { EventManagerContainer } from '../commonStyles.style';

interface StatusProps {
	status: EventStatus;
}

export function GuestBeforeEventManager({ status }: StatusProps) {
	function getPage() {
		switch (status) {
			case EventStatus.LiveInProgress: {
				return <GuestBeforeEventStarted />;
			}
			case EventStatus.MeetInProgress: {
				return <GuestBeforeEventStarted />;
			}
			case EventStatus.DeviceSetup: {
				return <GuestDeviceSetup />;
			}
			default: {
				return <GuestBeforeEventQueue />;
			}
		}
	}

	return <EventManagerContainer>{getPage()}</EventManagerContainer>;
}
