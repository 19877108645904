import React from 'react';
import styled from 'styled-components/macro';

import { Images, MediaQueries, Colors } from 'environment';
import { PlacementProps } from 'types';

interface StyleProps {
  margin?: PlacementProps;
}

const Container = styled.div<StyleProps>`
  background-color: ${Colors.darkest};
  background-image: url(${Images.Tiles});
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
  margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
  margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
  margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
  padding: 4rem;
  width: 100%;

  @media ${MediaQueries.tablet} {
    padding: 4.8rem 9.6354vw;
  }

  @media ${MediaQueries.desktop} {
    padding: 4.8rem 10.625vw;
  }

  @media ${MediaQueries.phone} {
    padding: clamp(2rem, 5vw, 4rem);

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

interface Props {
  children: React.ReactNode;
  margin?: PlacementProps;
}

export const PageContainer = React.forwardRef<HTMLDivElement, Props>(function (
  { children, margin },
  ref
) {
  return (
    <Container margin={margin} ref={ref}>
      {children}
    </Container>
  );
});
