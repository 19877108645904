import React, { useEffect, useState } from 'react';

import { Memoize } from 'components/widgets';
import { MediaQueries } from 'environment';
import { useMediaQuery, usePlatformContext, GridContext, useWindowSize } from 'hooks';
import { WidthsMap } from 'types';

import { Container } from './GridProvider.style';

const CARD_HEIGHT = 245;

// On phones, a card spans 1 column
const PHONE_CARD_SPAN = 1;
// On tablets, a card of size 1 spans 2 columns
const TABLET_CARD_SPAN = 2;
// On desktop, a card of size 1 spans 3 columns
const DESKTOP_CARD_SPAN = 3;

/**
 *
 * @param column Width of a grid column
 * @param gutter Width of the gutter between grid columns
 * @param span Number of columns a card spans over
 */
function computeWidths(column: number, gutter: number, span: number) {
	const widths: WidthsMap = {};
	widths[1] = span * column + (span - 1) * gutter;
	widths[2] = span * 2 * column + (span * 2 - 1) * gutter;
	return widths;
}

interface Props {
	children: React.ReactNode;
}

export function GridProvider({ children }: Props) {
	const { isTv } = usePlatformContext();

	const [columnsPerRow, setColumnsPerRow] = useState(0);
	const [gutter, setGutter] = useState(30);
	const [itemHeight, setItemHeight] = useState(0);
	const [itemWidths, setItemWidths] = useState<WidthsMap>({ 1: 0, 2: 0 });
	const [offset, setOffset] = useState(0);

	const isPhone = useMediaQuery(MediaQueries.phone);
	const isTablet = useMediaQuery(MediaQueries.tablet);
	const isLaptop = useMediaQuery(MediaQueries.laptop);
	const isDesktop = useMediaQuery(MediaQueries.desktop);

	const { height, width, resizing } = useWindowSize();

	useEffect(() => {
		if (isPhone) {
			setColumnsPerRow(1);
			setGutter((4 / 100) * width);
			setItemHeight(CARD_HEIGHT);
			setItemWidths({ 1: width - 30, 2: width - 30 });
			setOffset((6 / 100) * width);
		}
	}, [isPhone, width]);

	useEffect(() => {
		if (isTablet) {
			if (isTv) {
				document.documentElement.style.fontSize = '4px';
				const column = (19.27 / 100) * width;
				const gutter = (1.2 / 100) * width;

				setColumnsPerRow(4);
				setGutter(gutter);
				setItemHeight(height / 2.5);
				setItemWidths(computeWidths(column, gutter, PHONE_CARD_SPAN));
			} else {
				document.documentElement.style.fontSize = '62.5%';
				const column = (18.22 / 100) * width;
				const gutter = (2.6 / 100) * width;

				setColumnsPerRow(2);
				setGutter(gutter);
				setItemHeight(CARD_HEIGHT);
				setItemWidths(computeWidths(column, gutter, TABLET_CARD_SPAN));
			}

			setOffset((9.66 / 100) * width);
		}
	}, [isTablet, isTv, height, width]);

	useEffect(() => {
		if (isDesktop) {
			const column = (5.4166 / 100) * width;
			const gutter = (1 / 100) * width;
			const widths = computeWidths(column, gutter, DESKTOP_CARD_SPAN);

			if (isTv) {
				setItemHeight(height / 2.5);
				document.documentElement.style.fontSize = '42.5%';
			} else {
				setItemHeight(Math.max(widths[1] / 0.8, CARD_HEIGHT));
				document.documentElement.style.fontSize = '62.5%';
			}

			if (isLaptop) {
				setColumnsPerRow(3);
			} else {
				setColumnsPerRow(4);
			}

			setGutter(gutter);
			setItemWidths(widths);
			setOffset((10.625 / 100) * width);
		}
	}, [isDesktop, isTv, height, width]);

	return (
		<Container>
			<GridContext.Provider
				value={{ columnsPerRow, gutter, itemHeight, itemWidths, offset, resizing }}
			>
				<Memoize>{children}</Memoize>
			</GridContext.Provider>
		</Container>
	);
}
