export enum StorageKeys {
  AccessToken = 'biim-token',
  RefreshToken = 'biim-refresh-token',
  Username = 'biim-username',
  Attendee = 'biim-attendee',
  Status = 'biim-status',
  Version = 'biim-vesion',
  RecordingTaskId = 'biim-recording-task-id',
  RecordingStopped = 'biim-recording-stopped',
}

export const Storage = {
  set: function (getKey: (keys: typeof StorageKeys) => string, value: string) {
    const key = getKey(StorageKeys);
    localStorage.setItem(key, value);
  },
  get: function (getKey: (keys: typeof StorageKeys) => string) {
    const key = getKey(StorageKeys);
    return localStorage.getItem(key);
  },
  remove: function (getKeys: (keys: typeof StorageKeys) => string | string[]) {
    const keys = getKeys(StorageKeys);

    if (Array.isArray(keys)) {
      keys.forEach((key) => localStorage.removeItem(key));
    } else {
      localStorage.removeItem(keys);
    }
  },
  clear: function () {
    localStorage.clear();
  },
};
