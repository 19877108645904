import styled from 'styled-components/macro';

import { Colors,MediaQueries } from 'environment';

export const Main = styled.main`
  background-color: ${Colors.darkest};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  @media ${MediaQueries.phone} {
    min-height:calc(100vh - 8rem);
    width:100%;
  }
`;

export const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  animation: fade-in 1s;

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;
