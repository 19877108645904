import { Action } from 'redux';

import { Nullable } from 'types';

export interface AuthSession {
	token: string;
	refresh: string;
	username: string;
}

export interface State {
	session: Nullable<AuthSession>;
}

export enum ActionType {
	LOGIN = 'auth/LOGIN',
	REFRESH_TOKEN = 'auth/REFRESH_TOKEN',
	LOGOUT = 'auth/LOGOUT',
}

export interface LoginAction extends Action {
	type: ActionType.LOGIN;
	payload: AuthSession;
}

export interface RefreshTokenAction extends Action {
	type: ActionType.REFRESH_TOKEN;
	payload: {
		access: string;
	};
}

export interface LogoutAction extends Action {
	type: ActionType.LOGOUT;
}

export type Actions = LoginAction | RefreshTokenAction | LogoutAction;
