import {
  AudioInputControl,
  AudioOutputControl,
  ControlBar,
  ControlBarButton,
  LocalVideo,
  Phone,
  VideoInputControl,
  VideoTileGrid,
} from 'amazon-chime-sdk-component-library-react';
import styled from 'styled-components';

import { Colors, MediaQueries, Sizes } from 'environment';
import { PlacementProps } from 'types';

export const Heading = styled.div`
  font-family: Comfortaa;
  color: white;
  background-color: black;
  font-size: 2rem;
  padding: 5px;
  margin-top: 10px;
  @media ${MediaQueries.smallerPhone} {
    font-size: 1.2rem;
  }
`;

export const ContainerCreatorDevice = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5.6rem;
  padding-right: 5.5rem;
  @media ${MediaQueries.phoneMax} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 14.6rem;
  padding-right: 12.5rem;

  @media only screen and (max-width: ${Sizes.uhd}px) {
    padding: unset;
  }
`;

interface AlignProps {
  align?: {
    baseline?: boolean;
    end?: boolean;
  };
  margin?: PlacementProps;
}

export const Block = styled.div<AlignProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ align }) =>
    align?.baseline ? 'baseline' : align?.end ? 'flex-end' : 'center'};
  z-index: 1;
  flex: 1 1 0%;

  @media only screen and (max-width: ${Sizes.uhd}px) {
    align-items: center;
    flex-direction: column;
    margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : 0)};
  }

  @media ${MediaQueries.tabletMax} {
    width: 60%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media ${MediaQueries.phoneMax} {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const BlockRight = styled.div<AlignProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ align }) =>
    align?.baseline ? 'baseline' : align?.end ? 'flex-end' : 'center'};
  z-index: 1;
  flex: 1 1 0%;

  @media only screen and (max-width: ${Sizes.uhd}px) {
    align-items: center;
    flex-direction: column;
    margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : 0)};
  }

  @media ${MediaQueries.tabletMax} {
    width: 40%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media ${MediaQueries.phoneMax} {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Subtitle = styled.h5`
  font-size: 2.2rem;
  text-align: center;
  width: 30rem;
  color: ${Colors.white};
  margin-top: 1rem;

  @media ${MediaQueries.phoneMax} {
    font-size: 1.6rem;
    margin-top: 1rem;
  }
`;

export const CreatorName = styled.h1`
  font-size: 5rem;
  height: 6rem;
  color: ${Colors.white};
  margin-top: 3.5rem;
  max-width: 50rem;
  height: auto;

  @media only screen and (max-width: ${Sizes.uhd}px) {
    text-align: center;
  }

  @media ${MediaQueries.tabletMax} {
    font-size: 3rem;
  }
  @media ${MediaQueries.phoneMax} {
    font-size: 2.2rem;
  }
`;

export const BiimContainer = styled.div`
  border: 0.2rem solid ${Colors.gray};
  padding: 0.5rem;
  margin-top: 2rem;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  height: auto;
  max-width: 41rem;
`;

export const BiimTitle = styled.h1`
  color: ${Colors.white};
  font-size: 2rem;

  @media ${MediaQueries.tabletMax} {
    font-size: 1rem;
  }
  @media ${MediaQueries.phoneMax} {
    font-size: 1rem;
  }
`;

export const DateContainer = styled.div`
  margin-top: 3.4rem;
  padding: 1.4rem;
  width: 32rem;
  background: ${Colors.black};

  @media ${MediaQueries.tabletMax} {
    margin-top: 2rem;
  }
  @media ${MediaQueries.phoneMax} {
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
  }
`;

export const DateTitle = styled.h6`
  text-transform: uppercase;
  letter-spacing: 1.248px;
  text-align: center;
  color: ${Colors.white};

  @media ${MediaQueries.tabletMax} {
    font-size: 1.5rem;
  }
  @media ${MediaQueries.phoneMax} {
    font-size: 1.2rem;
  }
`;

export const Image = styled.div<{ src: string }>`
  position: absolute;
  background: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  animation: fade-in 2s;
`;

export const LiveControlBar = styled(ControlBar)`
  position: absolute;
  bottom: 11.4rem;
  right: 0;
`;

export const Video = styled(LocalVideo)<{ chatVisibility: boolean; isMobile: boolean }>`
  position: relative;
  background: ${Colors.darkerBrown};
  transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  bottom: 0;
  margin: 0;

  @media ${MediaQueries.phone} {
    bottom: 0;
  }

  .ch-video {
    margin: 0;
    //height: calc(100vh - 7rem);
    width: 100vw;
    border-radius: 0;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  }
`;

export const MeetVideo = styled(VideoTileGrid)<{ chatVisibility: boolean; isMobile: boolean }>`
  position: relative;
  background: ${Colors.darkerBrown};
  transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  bottom: 0rem;
  margin: 0;

  @media ${MediaQueries.phone} {
    bottom: 0;
  }

  .ch-video {
    @media screen and (orientation: landscape) {
      height: ${({ isMobile }) => isMobile && '100vh'};
    }
  }
`;
export const MeetVideoWrap = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  height: calc(100% - 6rem);
  @media ${MediaQueries.phone} {
    height: calc(100% - 8rem);
  }
`;

export const BarControlDesktop = styled(ControlBar)<{ chatVisibility: boolean }>`
  position: absolute;
  background: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  height: 6rem;
  padding-right: 2rem;
  padding-left: 2rem;
  margin: auto auto 0;

  .ch-control-bar-item {
    margin-right: 2rem;
  }

  .ch-control-bar-item-iconButton {
    color: white;

    :focus,
    :hover {
      background: unset;
      box-shadow: unset;
    }
  }

  .ch-control-bar-item-caret {
    color: white;
    width: 3rem;
    height: 3rem;
    border: unset;
    background-color: transparent;
    margin-left: 0;
    :focus,
    :hover {
      background: unset;
      box-shadow: unset;
    }
  }

  .ch-control-bar-item-iconButton {
    //margin-right: 3rem;
    .ch-icon {
      width: 3.5rem;
      height: 3.5rem;
      :hover {
        width: 4rem;
        height: 4rem;
      }
    }

    :focus,
    :hover {
      box-shadow: unset;
      border: unset;
      background: unset;
    }
  }

  @media ${MediaQueries.phone} {
    top: auto;
    bottom: 0;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
    height: 4rem;
    width: 100%;
    margin: 0;
    box-shadow: 0px 2px 40px rgba(241, 78, 180, 0.4);

    .ch-control-bar-item-caret {
      color: white;
      width: 2rem;
      height: 2rem;
      border: unset;
      background-color: transparent;

      :focus,
      :hover {
        background: unset;
        box-shadow: unset;
      }

      :hover {
        width: 2rem;
        height: 2rem;
      }
    }

    .ch-control-bar-item-iconButton {
      .ch-icon {
        width: 3rem;
        height: 3rem;

        :hover {
          width: 3rem;
          height: 3rem;
        }
      }

      :focus,
      :hover {
        box-shadow: unset;
        border: unset;
        background: unset;
      }
    }
  }

  // @media screen and (orientation: landscape) and (max-width: ${Sizes.lmd - 1}px) {
  //   bottom: ${({ chatVisibility }) => (chatVisibility ? '6rem' : '57vh')};
  // }

  @media ${MediaQueries.phone} {
    .reactionContainer {
      position: relative;
    }
  }
`;

export const BarControlMobile = styled(ControlBar)<{ chatVisibility: boolean; isMobile: boolean }>`
  position: absolute;
  background: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  height: 6rem;
  padding-right: 2rem;
  margin: auto auto 0;

  .ch-control-bar-item {
    margin-right: 2rem;
  }

  .ch-control-bar-item-iconButton {
    color: white;

    :focus,
    :hover {
      background: unset;
      box-shadow: unset;
    }
  }

  .ch-control-bar-item-caret {
    color: white;
    width: 3rem;
    height: 3rem;
    border: unset;
    background-color: transparent;
    margin-left: 2rem;
    :focus,
    :hover {
      background: unset;
      box-shadow: unset;
    }
  }

  .ch-control-bar-item-iconButton {
    .ch-icon {
      width: 3.5rem;
      height: 3.5rem;

      :hover {
        width: 4rem;
        height: 4rem;
      }
    }

    :focus,
    :hover {
      box-shadow: unset;
      border: unset;
      background: unset;
    }
  }

  @media ${MediaQueries.phone} {
    top: ${({ chatVisibility }) => (chatVisibility ? 'unset' : 'unset')};
    bottom: 0rem;
    height: 4rem;
    width: 100%;
    left: 0;
    padding-right: 0;
    box-shadow: 0px 2px 40px rgba(241, 78, 180, 0.4);

    @media screen and (orientation: landscape) {
      // left: ${({ isMobile }) => isMobile && '4rem'};
      // bottom: ${({ isMobile }) => isMobile && '5%'};
      // top: ${({ isMobile }) => isMobile && 'unset'};
    }

    .ch-control-bar-item-caret {
      color: white;
      width: 2rem;
      height: 2rem;
      border: unset;
      background-color: transparent;

      :focus,
      :hover {
        background: unset;
        box-shadow: unset;
      }

      :hover {
        width: 2rem;
        height: 2rem;
      }
    }

    .ch-control-bar-item-iconButton {
      .ch-icon {
        width: 3rem;
        height: 3rem;

        :hover {
          width: 2.5rem;
          height: 2.5rem;
        }
      }

      :focus,
      :hover {
        box-shadow: unset;
        border: unset;
        background: unset;
      }
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;
  margin-top: 4.5rem;
`;

export const HangUpPhone = styled(Phone)`
  // cursor: not-allowed;
`;

export const CountdownContainer = styled.div`
  margin-bottom: 5rem;
  margin-right: 3rem;

  @media only screen and (max-width: ${Sizes.uhd}px) {
    margin-right: unset;
  }
  @media ${MediaQueries.tabletMax} {
    margin-bottom: 2rem;
    h1 {
      font-size: 5rem;

      p {
        font-size: 5rem;
      }
    }
    h4 {
      font-size: 2rem;
      padding: 0 1.5rem;
    }
  }
  @media ${MediaQueries.phoneMax} {
    margin-bottom: 2rem;
    h1 {
      font-size: 4rem;
      p {
        font-size: 4rem;
      }
    }
    h4 {
      font-size: 1.5rem;
      padding: 0 1.5rem;
    }
  }
`;

export const ControlBarButtonCustom = styled(ControlBarButton)`
  .ch-icon {
    background-color: red;
  }
  .ch-popover-menu {
    background-color: black;
    border: 0.5px solid black;

    li {
      .ch-content {
        :hover {
          background-color: gray;
        }
      }
    }
  }
`;
export const AudioInputControlCustom = styled(AudioInputControl)`
  span:nth-child(2){
    margin-left: 4rem;
  }
  .ch-popover-menu {
    background-color: black;
    border: 0.5px solid black;

    li {
      .ch-content {
        :hover {
          background-color: gray;
        }
      }
    }
  }
`;
export const AudioOutputControlCustom = styled(AudioOutputControl)`
  span:nth-child(2){
    margin-left: 4rem;
  }
  .ch-popover-menu {
    background-color: black;
    border: 0.5px solid black;

    li {
      .ch-content {
        :hover {
          background-color: gray;
        }
      }
    }
  }
`;
export const VideoInputControlCustom = styled(VideoInputControl)`
  span:nth-child(2){
    margin-left: 4rem;
  }
  div > .ch-popover-menu {
    background-color: black;
    border: 0.5px solid black;

    li {
      .ch-content {
        :hover {
          background-color: gray;
        }
      }
    }
  }
`;
export const ControlBarButtonCustomModerator = styled(ControlBarButton)`
  margin-left: 1rem;
  .ch-control-bar-item-iconButton{
    .ch-icon {
      background-color: red;
    }
  }
  .ch-control-bar-item-caret{
    margin-left: 2rem;
  }
  .ch-popover-menu {
    background-color: black;
    border: 0.5px solid black;

    li {
      .ch-content {
        :hover {
          background-color: gray;
        }
      }
    }
  }
`;

export const NoPermission = styled.span`
  cursor: pointer;
  bottom: 15px;
  background-color: #E74C3C;
  padding: 5px;
  color: #fff;
  border-radius: 5px;
`;
export const ControlBarWrap = styled.div`
  display: flex;
  width: 32rem;
  justify-content: space-between;
  margin-bottom: unset;
  margin-left: -4rem;
  @media ${MediaQueries.phoneMax} {
    width: 90%;
  }
`;
export const PermissionContainer = styled.div`
  display: flex;
  width: calc(100% - 8rem);
  justify-content: space-between;
  @media ${MediaQueries.phoneMax} {
    
  }
`;


