import { Colors, MediaQueries } from 'environment';
import styled from 'styled-components/macro';
import { PlacementProps } from 'types';

export interface StyleProps {
  margin?: PlacementProps;
  width?: number | string;
}

interface StateProps {
  invalid?: boolean;
  boxShadow?: string;
  backgroundColor?: string;
  centerText?: boolean;
}

export const Container = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : 0)};
  margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : 0)};
  margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : 0)};
  margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : 0)};
  position: relative;
  width: ${({ width }) =>
    width !== undefined ? (typeof width === 'string' ? width : `${width}rem`) : 'auto'};
`;

export const Input = styled.input<StateProps>`
  background-color: ${({ backgroundColor = 'white' }) => backgroundColor};
  border-radius: 1rem;
  color: ${Colors.white};
  box-shadow: ${({ boxShadow }) => boxShadow};
  text-align: ${({ centerText }) => centerText && 'center'};
  min-height: 4.8rem;
  outline: none;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  width: 100%;
  transition: border-color 0.2s, color 0.2s;

  @media ${MediaQueries.phone} {
    padding-left: clamp(1rem, 3vw, 2.4rem) !important;
    padding-right: clamp(0px, 2vw, 2.4rem) !important;
  }

  :disabled {
    color: gray;
    border-color: black;

    ::placeholder {
      color: gray;
    }
  }

  :not(:disabled) {
    :focus,
    :hover {
      border-color: ${({ invalid }) => (invalid ? 'red' : 'gray')};
    }
  }

  ::placeholder {
    color: gray;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const MessageInput = styled.input<StateProps>`
  background-color: ${({ backgroundColor = Colors.darkest }) => backgroundColor};
  border-color: ${({ backgroundColor = Colors.darkest }) => backgroundColor};
  border-radius: 1rem;
  border-width: inherit;
  color: ${Colors.white};
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 0px;
  text-align: ${({ centerText }) => centerText && 'center'};
  min-height: 4.7rem;
  outline: none;
  padding: 0 2rem;
  width: 100%;

  @media ${MediaQueries.phone} {
    padding-left: clamp(1rem, 3vw, 2.4rem) !important;
    padding-right: clamp(0px, 2vw, 2.4rem) !important;
  }

  :disabled {
    color: gray;

    ::placeholder {
      color: gray;
    }
  }

  ::placeholder {
    color: white;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Label = styled.label`
  align-self: flex-start;
  color: black;
  margin-bottom: 0.8rem;
  margin-left: 2.4rem;
`;

export const Error = styled.p`
  color: ${Colors.pink};
  font-size: 1.6rem;
  margin-top: 0.8rem;
  text-align: center;
`;

export const Floating = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0.4rem;
  height: 100%;
  width: 4rem;
  background: ${Colors.white};
`;
