import { Colors, MediaQueries } from 'environment';
import styled, { css } from 'styled-components/macro';

export interface TextStyleProps {
  fontSize?: number;
  fontWeight?: number | string;
  lineHeight?: number;
  color?: string;
  background?: string;
}

interface Props {
  danger: boolean;
  disabled: boolean;
  interactive: boolean;
  selected: boolean;
  fontStyle?: TextStyleProps;
}

export const Option = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.8rem;
  width: 100%;
  padding: 0 2.4rem;
  background-color: ${({ fontStyle }) =>
    fontStyle?.background ? fontStyle.background : Colors.darkest};
  outline: none;
  color: ${({ danger }) => (danger ? 'red' : 'white')};
  font-family: 'Comfortaa', sans-serif;
  font-size: 1.6rem;
  font-weight: ${({ selected }) => selected && 600};
  line-height: 2rem;
  transition: 0.2s ease-in-out;
  border-bottom: 1px solid ${Colors.darker};

  span {
    color: ${({ danger }) => (danger ? 'red' : 'white')};
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    color: ${({ danger, fontStyle }) =>
      danger ? 'red' : fontStyle?.color ? fontStyle.color : 'white'};
    font-size: ${({ fontStyle }) =>
      fontStyle?.fontSize ? `${fontStyle?.fontSize}rem` : 'inherit'};
    font-weight: ${({ fontStyle }) =>
      fontStyle?.fontWeight ? `${fontStyle?.fontWeight}` : 'inherit'};
    line-height: ${({ fontStyle }) =>
      fontStyle?.fontWeight ? `${fontStyle?.lineHeight}rem` : 'inherit'};
    transition: color 0.1s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({ danger, disabled, interactive }) =>
    interactive &&
    !disabled &&
    css`
      :focus,
      :hover {
        background-color: ${danger ? `${Colors.pink}` : `${Colors.darker}`};
        color: ${danger ? `${Colors.white}` : `${Colors.gray}`};
        cursor: pointer;
        transform: translateX(0.3rem);
      }

      @media ${MediaQueries.phone} {
        :focus,
        :hover {
          transform: translateX(0);
        }
      }
    `}
`;
