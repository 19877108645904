import { useNavbarUIContext } from 'hooks';
import { Attendees } from 'amazon-chime-sdk-component-library-react';
import { EventRuntime } from 'components/displayers';
import {
  LiveAnnouncement,
  LiveDot,
  LiveTitle,
  UtilitiesBarHeader,
  AttendeeCount,
} from 'features/event/commonStyles.style';

export const HeaderUtilitiesBar = () => {
  const { runtime, attendeeCount, isVideoEnabled, setGuestView, guestView, setGuestChat } =
    useNavbarUIContext();
  return (
    <UtilitiesBarHeader>
      {location.pathname.split('/')[2] !== 'recording' && <EventRuntime runtime={runtime} />}
      {isVideoEnabled && location.pathname.split('/')[2] !== 'recording' && (
        <LiveAnnouncement>
          <LiveDot />
          <LiveTitle>Live</LiveTitle>
        </LiveAnnouncement>
      )}
      {location.pathname.split('/')[2] !== 'recording' && (
        <AttendeeCount
          onClick={() => {
            setGuestView(!guestView);
            setGuestChat('Guests');
          }}
        >
          <Attendees />
          <span>{attendeeCount}</span>
        </AttendeeCount>
      )}
    </UtilitiesBarHeader>
  );
};
