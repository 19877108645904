import { useState } from 'react';

import { BaseModalProps } from 'components/widgets';
import { useCallbackOnce } from 'hooks';

export function useModal(defaultOpen = false): [BaseModalProps, () => void] {
	const [open, setOpen] = useState(defaultOpen);

	const handleToggle = useCallbackOnce(() => {
		setOpen(o => !o);
	});

	const handleClose = useCallbackOnce(() => {
		setOpen(false);
	});

	return [{ open, onClose: handleClose }, handleToggle];
}
