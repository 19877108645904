import { BaseModal, PrimaryButton, SecondaryButton } from 'components/widgets';
import { MediaQueries } from 'environment';
import { useMediaQuery } from 'hooks';
import { useRealitimeSubscribeChatState } from '../../chat/RealtimeSubscribeChatProvider';
import { ButtonContainer } from './LeaveModal.style';
import { ATTENDEE_NOTIFY } from "../../../consts";

interface Props {
  title?: string;
  name: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export function LeaveModal({ name, title, open, onClose, onSubmit }: Props) {
  const isPhone = useMediaQuery(MediaQueries.phone);
  const { sendAttendeeNotifyData } = useRealitimeSubscribeChatState();

  const onConfirm = () => {
	sendAttendeeNotifyData(name, ATTENDEE_NOTIFY.LEFT, ATTENDEE_NOTIFY.LEFT_CLASS);
    onSubmit();
  };

  return (
    <BaseModal
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={title ?? `Are you sure you wish to leave?`}
    >
      <ButtonContainer>
        <SecondaryButton fontSize={1.8} fontWeight="normal" width={29} onClick={onClose}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          fontSize={1.8}
          fontWeight="normal"
          width={29}
          margin={{ bottom: isPhone ? 3 : 0, left: isPhone ? 0 : 2 }}
          onClick={onConfirm}
        >
          Confirm
        </PrimaryButton>
      </ButtonContainer>
    </BaseModal>
  );
}
