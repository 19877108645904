export const Layers = {
	loader: 10,
	menu: {
		drawer: 1,
		icon: 100
	},
	modal: 100,
	drawer: 101,
	floater: 999
};
