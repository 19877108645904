import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';

export const ImageContainer = styled.div`
  display: block;
  flex: 1;
  position: relative;
  margin-right: 7rem;
  max-height: 30rem;
  max-width: 38rem;
  width: 100%;

  @media ${MediaQueries.phone} {
    margin-right: unset;
    margin-bottom: 2.5rem;
  }
`;

export const Image = styled.img`
  width: 38rem;
  height: 26rem;
  object-fit: cover;
  border-radius: 1.1rem;

  @media ${MediaQueries.phone} {
    width: 85vw;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: 4.5rem;

  @media ${MediaQueries.phone} {
    flex-direction: column-reverse;
    align-self: center;
  }
`;

export const Content = styled.div`
  display: flex;

  @media ${MediaQueries.phone} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const DateContainer = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  bottom: 5.8rem;
  color: ${Colors.white};
  padding: 1rem;
  background-color: ${Colors.darkest};
  height: 4.2rem;

  span {
    font-size: 10px;
    line-height: 6px;
    letter-spacing: 1.248px;
  }
`;

export const Info = styled.div`
  display: flex;
  position: relative;
  bottom: 0.8rem;
  padding: 3rem 0.9rem 1rem;
  //margin-right: 1.8rem;
  justify-content: space-between;
  right: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 0 0 10px 10px;
`;

export const Price = styled.p`
  color: ${Colors.white};
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 2.1rem;
  line-height: 2rem;
  letter-spacing: 1.326px;
`;

export const Stock = styled.div`
  display: inline-flex;
`;

export const StockLeft = styled.p`
  color: ${Colors.gray};
  font-size: 1.2rem;
  letter-spacing: 1.326px;
  align-self: flex-end;
  padding-top: 0.3rem;
`;

export const LinkContainer = styled.div`
  margin-top: 3rem;
`;

export const LinkContent = styled.div`
  display: flex;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex: 1;
`;

export const LinkTitle = styled.p`
  color: ${Colors.gray};
  font-size: 1rem;
  letter-spacing: 1.326px;
  margin-right: 1rem;
`;

export const LinkDescription = styled.p`
  color: ${Colors.white};
  font-size: 1rem;
  letter-spacing: 1.326px;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const Countdown = styled.div`
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 1rem;
  z-index: 1;
  width: 15rem;
  display: flex;
  height: auto;
  justify-content: center;
`;
