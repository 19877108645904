import { animated } from 'react-spring';
import styled from 'styled-components/macro';

import { Icon } from 'components/icons';
import { Colors, Layers, MediaQueries } from 'environment';

interface Props {
  fullscreen?: boolean;
  height?: number | string;
  width?: number | string;
}

export const Overlay = styled.div<{ isMobile: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${Layers.modal};
  width: 100vw;
  height: 100vh;
  background-color: rgba(15, 17, 25, 0.7);
  // box-shadow: inset 0px 0px 6px rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(1rem);
  animation: fade-in 1s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div<Props>`
  display: flex;
  justify-content: center;
  position: relative;
  opacity: 1;
  height: ${({ fullscreen, height }) =>
    fullscreen
      ? '100%'
      : height === undefined
      ? 'auto'
      : typeof height === 'string'
      ? height
      : `${height}rem`};
  max-height: ${({ fullscreen }) => (fullscreen ? '100%' : '92%')};
  max-width: ${({ fullscreen }) => (fullscreen ? '100%' : '100.67rem')};
  width: ${({ width }) => (typeof width === 'string' ? width : `${width}rem`)};

  @media ${MediaQueries.phone} {
    max-height: calc(100vh - 3.2rem);
    max-width: calc(100vw - 3.2rem);
  }
`;

export const AnimatedContainer = animated(Container);

export const Dialog = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.6);
  background-color: ${({ fullscreen }) => (fullscreen ? 'black' : Colors.darker)};
  border-radius: ${({ fullscreen }) => (fullscreen ? 0 : '2rem')};
  animation: fade-in 0.6s;
  outline: none;
  overflow: hidden;
  width: 100%;

  @media ${MediaQueries.phone} {
    padding-top: 2rem;
  }
`;

interface HeaderProps {
  scrolled: boolean;
}

export const Header = styled.div<HeaderProps>`
  box-shadow: ${({ scrolled }) => scrolled && 'inset 0px 0px 6px rgba(255, 255, 255, 0.25)'};
  display: flex;
  padding: 2rem 4rem 2rem 4rem;
  position: relative;
  transition: box-shadow 0.3s;
  justify-content: center;

  h3 {
    color: ${Colors.white};
    font-weight: normal;
  }

  @media ${MediaQueries.phone} {
    padding: 3rem 2rem 0 2rem;
  }
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  transition: 0.2s ease-in-out;

  :hover {
    transform: rotate(90deg);
  }

  @media ${MediaQueries.phone} {
    top: -1rem;
    right: 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 4rem;
  padding-top: 2rem;
  padding-bottom: 4rem;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${MediaQueries.phone} {
    padding-left: clamp(1rem, 5vw, 4rem);
    padding-right: clamp(1rem, 5vw, 4rem);
  }
`;
