import { auth } from './api/auth';
import { event } from './api/event';
import { user } from './api/user';

import { AuthRequestConfig } from './generics';

export const factory = () => ({
	auth: (config?: AuthRequestConfig) => auth(config),
	event: (config?: AuthRequestConfig) => event(config),
	user: (config: AuthRequestConfig) => user(config)
});

export type ApiFactory = ReturnType<typeof factory>;
