import styled, { css } from 'styled-components';

export const Container = styled.div<{ checked: boolean }>`
  cursor: pointer;

  span {
    display: block;
    position: relative;
    width: 30px;
    height: 2px;
    border-radius: 8px;
    background: #cdcdcd;
    transition: 0.3s ease;

    :not(:first-child) {
      margin-top: 8px;
    }

    :first-child {
      transform-origin: 0 0;
      transform: rotate(0deg);
      margin-top: 4px;
    }

    :last-child {
      transform-origin: 0 100%;
      transform: rotate(0deg);
    }

    :nth-child(2) {
      opacity: 1;
    }
  }

  ${({ checked }) =>
    checked &&
    css`
      span {
        :first-child {
          transform: rotate(45deg);
        }

        :nth-child(2) {
          opacity: 0;
        }

        :last-child {
          transform: rotate(-45deg);
        }
      }
    `}
`;
