import React, { useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { LayoutStyleProps, PlacementProps, Position } from 'types';

import { Menu } from './DropdownMenu.style';

function computePosition(
  bounds: {
    left: number;
    top: number;
    right: number;
    bottom: number;
    width: number;
    height: number;
  },
  margin?: PlacementProps,
  offset?: PlacementProps,
  position?: Position
) {
  let top = margin?.top ? margin.top * 10 : 0;
  let bottom = margin?.bottom ? margin.bottom * 10 : 0;
  let left = margin?.left ? margin.left * 10 : 0;
  const right = margin?.right ? margin.right * 10 : 0;

  const style: React.CSSProperties = {
    top,
    bottom,
    left,
    right,
  };

  if (bounds.height) {
    top += bounds.height + 10;
    delete style.bottom;
  }

  if (offset) {
    if (offset.top) {
      top += offset.top;
      style.top = top;
    }

    if (offset.bottom) {
      style.bottom = offset.bottom;
      delete style.top;
    }

    if (offset.left) {
      left += offset.left;
      style.left = left;
    }

    if (offset.right) {
      style.right = offset.right;
      delete style.left;
    }
  }

  style.top = top;

  if (position === 'right') {
    delete style.left;
  }

  if (position === 'bottom') {
    bottom += bounds.height + 5;
    style.bottom = bottom;
    delete style.top;
  }

  return style;
}

interface Props extends LayoutStyleProps {
  bounds: {
    left: number;
    top: number;
    right: number;
    bottom: number;
    width: number;
    height: number;
  };
  children: React.ReactNode;
  headerComponent?: React.ReactNode;
  label?: string;
  offset?: PlacementProps;
  position?: Position;
  menuPadding?: PlacementProps;
  width?: number | string;
}

export const DropdownMenu = React.forwardRef<HTMLDivElement, Props>(function (
  { bounds, children, headerComponent, label, margin, offset, position, width, menuPadding },
  ref
) {
  const style = useMemo(
    () => computePosition(bounds, margin, offset, position),
    [bounds, margin, offset, position]
  );

  const paddingStyle = {
    paddingTop: menuPadding?.top ? menuPadding.top : 0,
    paddingBottom: menuPadding?.bottom ? menuPadding.bottom : 0,
    paddingLeft: menuPadding?.left ? menuPadding.left : 0,
    paddingRight: menuPadding?.right ? menuPadding.right : 0,
  };

  return (
    <Menu
      aria-label={label}
      ref={ref}
      role="listbox"
      style={{ ...style, ...paddingStyle }}
      width={width}
    >
      <Scrollbars
        autoHeight
        renderTrackHorizontal={(props) => <div {...props} className="horizontal-track" />}
      >
        {headerComponent}
        {children}
      </Scrollbars>
    </Menu>
  );
});
