import Countdown from 'react-countdown';
import moment from 'moment-timezone';
import { addLeadingZeros } from 'helpers';
import { TIME_ZONE, EUROPE_LONDON } from 'consts';

import {
	Container,
	Day,
	DayContainer,
	QueueBox,
	QueueBoxLabel,
	QueueBoxText,
	QueueContainer,
	Timer,
	Title,
} from './CountdownToEvent.style';

interface Props {
	date: string | Date;
	small?: boolean;
	queue?: boolean;
	cancelled?: boolean;
}

export function CountdownToEvent({ date, small = false, queue = false, cancelled = false }: Props) {
	
	const futureDate = () => {
		const FUTURE_DATE = date;
		const TIME_ZONE_NOW = moment.tz(new Date(), EUROPE_LONDON).format('z');
		const TIME_ZONE_FUTURE = moment(FUTURE_DATE).tz(EUROPE_LONDON).format('z');
		let futureDateValue: any  = '';

		if(TIME_ZONE_NOW === TIME_ZONE.BST) {
			futureDateValue = TIME_ZONE_FUTURE === TIME_ZONE.BST ? 
				moment(FUTURE_DATE).tz(EUROPE_LONDON).format() :
				moment(FUTURE_DATE).tz(EUROPE_LONDON).subtract(1, 'hours').format()
		}

		if(TIME_ZONE_NOW === TIME_ZONE.GMT) {
			futureDateValue = TIME_ZONE_FUTURE === TIME_ZONE.GMT ? 
				moment(FUTURE_DATE).tz(TIME_ZONE.GMT).format() :
				moment(FUTURE_DATE).tz(EUROPE_LONDON).add(1, 'hours').format()
		}

		return futureDateValue
	}

	const europeNow = () => {
		const TimeZone = moment.tz(new Date(), EUROPE_LONDON).format('z');
		return TimeZone === TIME_ZONE.BST ? 
			moment.tz(new Date(), EUROPE_LONDON).valueOf() :
			moment.tz(new Date(), TIME_ZONE.GMT).valueOf()
	}

	const Cancelled = () => (
		<Container style={{ justifyContent: 'center' }} small={small}>
			<Timer small={small}>Cancelled</Timer>
		</Container>
	);

	const Completionist = () => (
		<Container small={small}>
			<Title small={small}>biim in</Title>
			<Timer small={small}>
				00
				<p>:</p>
				00
				<p>:</p>
				00
			</Timer>
			<DayContainer small={small}>
				<Day small={small}>days</Day>
				<Day small={small}>hrs</Day>
				<Day small={small}>mins</Day>
			</DayContainer>
		</Container>
	);

	const renderer = ({
		days,
		hours,
		minutes,
		seconds,
		completed,
	}: {
		days: number;
		hours: number;
		minutes: number;
		seconds: number;
		completed: boolean;
	}) => {
		if (cancelled) return <Cancelled />;
		if (queue) {
			return (
				<QueueContainer>
					<QueueBox>
						<QueueBoxText>{addLeadingZeros(String(days))}</QueueBoxText>
						<QueueBoxLabel>Days</QueueBoxLabel>
					</QueueBox>
					<QueueBox>
						<QueueBoxText>{addLeadingZeros(String(hours))}</QueueBoxText>
						<QueueBoxLabel>Hours</QueueBoxLabel>
					</QueueBox>
					<QueueBox>
						<QueueBoxText>{addLeadingZeros(String(minutes))}</QueueBoxText>
						<QueueBoxLabel>Minutes</QueueBoxLabel>
					</QueueBox>
					<QueueBox>
						<QueueBoxText>{addLeadingZeros(String(seconds))}</QueueBoxText>
						<QueueBoxLabel>Seconds</QueueBoxLabel>
					</QueueBox>
				</QueueContainer>
			);
		} else if (completed) {
			return <Completionist />;
		} else {
			// Render a countdown
			return (
				<Container small={small}>
					<Title small={small}>biim in</Title>
					<Timer small={small}>
						{addLeadingZeros(String(days))}
						<p>:</p>
						{addLeadingZeros(String(hours))}
						<p>:</p>
						{addLeadingZeros(String(minutes))}
					</Timer>
					<DayContainer small={small}>
						<Day small={small}>days</Day>
						<Day small={small}>hrs</Day>
						<Day small={small}>mins</Day>
					</DayContainer>
				</Container>
			);
		}
	};

	return <Countdown date={futureDate()} renderer={renderer} now={europeNow}/>;
}
