import React, { useRef } from 'react';

import { Icon } from 'components/icons';
import { Colors } from 'environment';

import { TypeaheadInputProps } from './Typeahead';
import { Container, Input, Spacer } from './TypeaheadToggle.style';

interface Props extends TypeaheadInputProps {
	clearable: boolean;
	disabled: boolean;
	labelId: string;
	name: string;
	style?: React.CSSProperties;
	toggleRef: React.RefObject<HTMLDivElement>;
	value: string;
	onClearClick: () => void;
	icon?: React.ReactElement;
}

export function TypeaheadToggle({
	icon,
	clearable,
	disabled,
	labelId,
	multiple,
	name,
	toggleRef,
	value,
	style,
	onClearClick,
	...props
}: Props) {
	const ref = useRef<HTMLInputElement>(null);

	function handleClick() {
		if (ref.current) ref.current.focus();
	}

	return (
		<Container ref={toggleRef} style={style} onClick={handleClick}>
			<Spacer>
				<Input
					{...props}
					aria-disabled={disabled}
					aria-labelledby={labelId}
					autoComplete="off"
					disabled={disabled}
					icon={
						clearable &&
						!multiple && (
							<Icon
								color={Colors.darkest}
								interactable={false}
								type={(t) => t.Clear}
								size={1.8}
								onClick={onClearClick}
							/>
						)
					}
					name={name}
					style={{ color: Colors.black }}
					ref={ref}
					type="text"
					value={value}
				/>
			</Spacer>
		</Container>
	);
}
