import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon } from 'components/icons';
import { TextInput } from 'components/inputs';
import { PrimaryButton, Link } from 'components/widgets';
import { Colors } from 'environment';
import { useEffectOnce, useLogout, useNavigation } from 'hooks';
import { Container, LoginContainer, Title } from 'pages/auth/LoginPage.style';
import { useNavbarColorContext, useNavbarUIContext } from 'hooks';

export function SelectActionPage() {
  const [link, setLink] = useState('');
  const { setIsEnabled } = useNavbarUIContext();
  const { navigate, routes } = useNavigation();
  const logout = useLogout();
  const { switchColor } = useNavbarColorContext();

  useEffectOnce(() => {
    switchColor('dark');
    setIsEnabled(false)
  });

  function handleSubmitLink(url?: string) {
    if (url) {
      console.log({ url });
      const newWindow = window.open(url, '_self');
      if (newWindow) newWindow.opener = null;
    }
  }

  function handleLogout() {
    logout();
    navigate(routes.login);
  }

  function handleOnEnterAction(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      // @ts-ignore: A keyboard event is fine here
      handleSubmitLink(e.target.value);
    }
  }

  return (
    <Container>
      <LoginContainer>
        <Icon type={(t) => t.Logo} color="undefined" size={15} margin={{ bottom: 5 }} />
        <Title>Welcome to biim</Title>
        <FormInput
          name="link"
          placeholder="Link"
          value={link}
          boxShadow="0px 0px 3px rgba(255, 255, 255, 0.12), inset 0px 4px 4px rgba(0, 0, 0, 0.25)"
          backgroundColor={Colors.darkest}
          onChange={(e) => setLink(e.target.value)}
          type="text"
          centerText
          margin={{ bottom: 3 }}
          onKeyDown={(e) => handleOnEnterAction(e)}
        />
        <Button margin={{ bottom: 2.5 }} onClick={() => handleSubmitLink(link)}>
          Submit
        </Button>
        <Link margin={{ bottom: 6.5 }} onClick={handleLogout}>
          Go to login
        </Link>
      </LoginContainer>
    </Container>
  );
}

const FormInput = styled(TextInput)`
  width: clamp(26rem, 74vw, 32.4rem);
`;

const Button = styled(PrimaryButton)`
  width: clamp(26rem, 74vw, 32.4rem);
`;
