import { EventBiim } from 'biim-api';
import { createContext, useContext } from 'react';

interface ContextValue {
  eventId: string;
  eventData: EventBiim;
}

export const EventContext = createContext<ContextValue>({
  eventId: '',
  eventData: {
    availabilityDate: '',
    creatorEmail: '',
    creatorImage: '',
    creatorName: '',
    creatorYoutubePlaylistId: '',
    creatorSpotifyPlaylistId: '',
    creatorWaitingRoomBackgroundMusicTrackUrl: '',
    creatorThankYouVideoUrl: '',
    creatorThankYouMessage: '',
    creatorTippingProductId: '',
    description: '',
    id: 0,
    images: [],
    eventType: 1,
    moderatorEmail: '',
    permalink: '',
    price: 0,
    relatedProducts: [],
    runningTime: '',
    status: 1,
    stockInitial: 0,
    stockRemaining: 0,
    title: '',
    userName: '',
    userType: '',
    uuidEvent: '',
    uuidGuest: '',
    wooCreatorId: '',
    wooProductId: 0,
  },
});

export function useEventContext() {
  const context = useContext(EventContext);
  return context;
}
