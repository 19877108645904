import React, { useEffect, useState } from 'react';

import { Layout } from 'components/displayers';
import { CustomDropdown } from 'components/widgets';
import { Colors } from 'environment';
import { truncateText } from 'helpers';
import { usePrevious } from 'hooks';

import { Label, StyleProps } from '../style';
import { TypeaheadToggle } from './TypeaheadToggle';
import { Container } from './Typeahead.style';
import { v4 as uuidv4 } from 'uuid';

export type TypeaheadInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onSelect'>;

interface Props extends StyleProps, TypeaheadInputProps {
	icon?: React.ReactElement;
	className?: string;
	clearable?: boolean;
	disabled?: boolean;
	name: string;
	label?: string;
	loading?: boolean;
	placeholder?: string;
	selected?: string | string[];
	suggestions: string[];
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onClearClick?: () => void;
	onInputClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
	onOutsideClick?: () => void;
	onSelect?: (item: string) => void;
}

export function Typeahead({
	className,
	clearable: outsideClearable = false,
	disabled = false,
	label,
	loading = false,
	margin,
	multiple = false,
	name,
	placeholder,
	selected,
	suggestions,
	value,
	width,
	onChange,
	onClearClick,
	onInputClick,
	onOutsideClick,
	onSelect,
	...props
}: Props) {
	const [clearable, setClearable] = useState(outsideClearable);
	const [focus, setFocus] = useState(false);
	const [items, setItems] = useState(suggestions);
	const [open, setOpen] = useState(false);
	const [searching, setSearching] = useState(false);

	const wasLoading = usePrevious(loading);

	useEffect(() => {
		if (searching && wasLoading && !loading) {
			setSearching(false);
		}
	}, [loading, searching, wasLoading]);

	useEffect(() => {
		setClearable(outsideClearable);
	}, [outsideClearable]);

	useEffect(() => {
		setItems(suggestions.filter((d) => d.toLowerCase().includes(value.toLowerCase())));
	}, [suggestions, value]);

	function handleBlur() {
		setFocus(false);
	}

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (!searching) setSearching(true);
		onChange(e);
	}

	function handleClearClick() {
		setClearable(false);
		onClearClick?.();
	}

	function handleClose() {
		setOpen(false);
	}

	function handleFocus() {
		setFocus(true);
		setOpen(true);
	}

	function handleInputClick(e: React.MouseEvent<HTMLInputElement>) {
		if (!suggestions.length) setSearching(true);
		setOpen(true);
		onInputClick?.(e);
	}

	function handleOutsideClick() {
		setOpen(false);
		onOutsideClick?.();
	}

	function handleSelect(suggestion: string) {
		setOpen(false);
		setClearable(true);
		onSelect?.(suggestion);
	}

	const labelId = `${name}-label`;

	return (
		<Layout.Column className={className} margin={margin} width={width}>
			{label && <Label id={labelId}>{label}</Label>}
			<Container disabled={disabled} focus={focus}>
				<CustomDropdown<string>
					flex={1}
					label={label}
					open={open}
					search
					selected={(d) => selected === d.toString()}
					toggleComponent={({ ref }) => (
						<TypeaheadToggle
							{...props}
							autoComplete="off"
							clearable={clearable}
							disabled={disabled}
							labelId={labelId}
							multiple={multiple}
							name={name}
							placeholder={placeholder}
							toggleRef={ref}
							value={value}
							onBlur={handleBlur}
							onChange={handleChange}
							onClearClick={handleClearClick}
							onClick={handleInputClick}
							onFocus={handleFocus}
						/>
					)}
					onClose={handleClose}
					onOutsideClick={handleOutsideClick}
					onSelect={handleSelect}
				>
					{items.length === 0 ? (
						<CustomDropdown.Empty searching={searching} />
					) : (
						items.map((suggestion) => (
							<CustomDropdown.Item
								style={{ borderBottom: 'unset' }}
								fontStyle={{
									fontSize: 1.8,
									lineHeight: 3.6,
									color: Colors.darkest,
									background: Colors.white,
								}}
								data={suggestion}
								key={`${suggestion.slice(0, 4)}-${uuidv4()}`}
							>
								{truncateText(suggestion, 35)}
							</CustomDropdown.Item>
						))
					)}
				</CustomDropdown>
			</Container>
		</Layout.Column>
	);
}
