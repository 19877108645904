import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';

import { Icon } from 'components/icons';
import { TextInput } from 'components/inputs';
import { PrimaryButton } from 'components/widgets';
import { useLogin, useNavigation, useNavbarUIContext } from 'hooks';
import { Colors } from 'environment';

import { Container, LoginContainer, Terms, Title } from './LoginPage.style';

const validationSchema = yup.object().shape({
  email: yup.string().required('username is required'),
  password: yup.string().required('password is required'),
});

interface FormValues {
  email: string;
  password: string;
}

export function LoginPage() {
  const { routes, navigate } = useNavigation();
  const [{ data }, login] = useLogin();
  const { setIsEnabled } = useNavbarUIContext();
  const { errors, touched, values, handleBlur, handleChange, handleSubmit } = useFormik<FormValues>(
    {
      initialValues: { email: '', password: '' },
      validationSchema,
      onSubmit: (values) => {
        login({ username: values.email, password: values.password });
      },
    }
  );

  useEffect(() => {
    setIsEnabled(false)
    if (data) navigate(routes.admin);
  }, [data]);

  return (
    <Container>
      <LoginContainer>
        <Icon type={(t) => t.Logo} color="undefined" size={15} margin={{ bottom: 5 }} />
        <Title>Login to biim</Title>
        <FormInput
          name="email"
          placeholder="Username"
          boxShadow="0px 0px 3px rgba(255, 255, 255, 0.12), inset 0px 4px 4px rgba(0, 0, 0, 0.25)"
          backgroundColor={Colors.darkest}
          centerText
          margin={{ bottom: 2 }}
          type="text"
          value={values.email}
          error={touched.email ? errors.email : ''}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <FormInput
          name="password"
          placeholder="Password"
          boxShadow="0px 0px 3px rgba(255, 255, 255, 0.12), inset 0px 4px 4px rgba(0, 0, 0, 0.25)"
          backgroundColor={Colors.darkest}
          type="password"
          centerText
          margin={{ bottom: 6 }}
          value={values.password}
          error={touched.password ? errors.password : ''}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Button
          margin={{ bottom: 2.5 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Login
        </Button>
        {/* <NoAccount>Need an account? Sign up.</NoAccount> */}
        {/* <Link margin={{ bottom: 6.5 }}>forgotten password?</Link> */}
        <Terms>By logging in, you agree to our Privacy Policy and Terms of Service </Terms>
      </LoginContainer>
    </Container>
  );
}

const FormInput = styled(TextInput)`
  width: clamp(26rem, 74vw, 32.4rem);

  input {
    :not(:disabled):focus,
    :not(:disabled):hover {
      border-color: ${Colors.pink};
    }
  }
`;

const Button = styled(PrimaryButton)`
  width: clamp(26rem, 74vw, 32.4rem);
`;
