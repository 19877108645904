import { EventStatus } from 'biim-api';

import { useEventStatusContext } from 'hooks';

import { EventManagerContainer } from '../commonStyles.style';
import { CreatorAfterEvent, CreatorBeforeEvent, CreatorDeviceSetup } from '../creator';
import { ModeratorBeforeEvent, ModeratorLiveEvent, ModeratorMeetEvent } from '../moderator';

export function ModeratorEventManager() {
	const { status } = useEventStatusContext();

	function getEventForModerator() {
		switch (status) {
			case EventStatus.Before:
				return <CreatorBeforeEvent />;
			case EventStatus.DeviceSetup:
				return <CreatorDeviceSetup />;
			case EventStatus.LiveInProgress:
				return <ModeratorLiveEvent />;
			case EventStatus.MeetInProgress:
				return <ModeratorMeetEvent />;
			case EventStatus.Finished:
				return <CreatorAfterEvent />;
			case EventStatus.Cancelled:
				return <CreatorAfterEvent />;
			default:
				<div />;
		}
	}

	return <EventManagerContainer>{getEventForModerator()}</EventManagerContainer>;
}
