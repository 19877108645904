import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const ButtonContainer = styled.div`
	justify-content: space-between;
	display: flex;

	@media ${MediaQueries.phone} {
		flex-direction: column-reverse;
		align-self: center;
	}
`;
