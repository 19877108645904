import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { isEmpty } from 'lodash';

interface CustomRequestConfig extends AxiosRequestConfig {
	token?: string;
}

export const instance = axios.create<CustomRequestConfig>({
	headers: {
		'Content-Type': 'application/json'
	},
	timeout: 20000
});

instance.interceptors.request.use(async request => {
	try {
		if (request.token) {
			request.headers.Authorization = `Bearer ${request.token}`;
			delete request.token;
		}

		if (request.data && !isEmpty(request.data)) {
			Object.keys(request.data).forEach(key => {
				if (request.data[key] === undefined || request.data[key] === null) {
					delete request.data[key];
				}
			});
		}

		if (request.params && !isEmpty(request.params)) {
			Object.keys(request.params).forEach(key => {
				if (request.params[key] === undefined || request.params[key] === null) {
					delete request.params[key];
				}
			});
		}
	} catch (error) {
		const err = error as AxiosError;
		console.log('Failed to execute the request: ', err.message || error);
	}

	return request;
});

export function buildUrl(endpoint = '') {
	let url = `${process.env.REACT_APP_BASE_URL}${endpoint}`;

	if (url.charAt(url.length - 1) !== '/') url = `${url}/`;
	return url;
}
