import React, {useState } from 'react';

import {
  DeviceLabelTriggerStatus,
} from 'amazon-chime-sdk-component-library-react';
import DeviceSelection from '../DeviceSelection'
import PermissionPromptWindow from './PermissionPromptWindow';
import {
  useEffectOnce
} from 'hooks';
export interface DeviceSetupProps {
  closePreview: () => void;
  open: boolean;
  onSubmit: () => void;
  type: string
}

type Props = DeviceSetupProps;
// Show permission prompt when the user is granting the browser permissions
// Show nothing if permission is already granted or component loads on initial render
export function DevicePermissionPrompt({ closePreview, onSubmit, open, type }: Props) {
  const [mediaPermissions, setMediaPermissions] = useState(DeviceLabelTriggerStatus.UNTRIGGERED);
  const [showPermissionCheck, setShowPermissionCheck] = useState(true);

  const checkMedia = async () => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(
      () => {
        // microphone available
        setMediaPermissions(DeviceLabelTriggerStatus.GRANTED);
      },
      () => {
        // microphone not available
        setMediaPermissions(DeviceLabelTriggerStatus.DENIED);
      }
    );
  };
  
  const dismissPermissionCheck = () => {
    setShowPermissionCheck(false)
  }

  useEffectOnce(() => {
    checkMedia();
  });

  return (
    <>
      {mediaPermissions === DeviceLabelTriggerStatus.GRANTED && (
        <DeviceSelection
          open={open}
          closePreview={closePreview}
          onSubmit={onSubmit}
          type={type}
        />
      )}
      {mediaPermissions === DeviceLabelTriggerStatus.DENIED && showPermissionCheck && (
        <PermissionPromptWindow
          open={open}
          closePreview={dismissPermissionCheck}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}
