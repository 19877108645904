import styled, { css } from 'styled-components/macro';
import { Colors } from 'environment';
import { LayoutStyleProps } from 'types';

interface ContainerProps extends LayoutStyleProps {
	active: boolean;
	color: string;
	disabled: boolean;
	interactable: boolean;
	size: number;
}

export const Container = styled.div<ContainerProps>`
	border: none;
	outline: none;
	background: transparent;
	border-radius: 50%;
	position: relative;
	display: flex;
	align-items: center;
	cursor: ${({ disabled, interactable }) =>
		disabled ? 'not-allowed' : interactable ? 'pointer' : undefined};
	height: fit-content;
	margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
	margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
	margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
	margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};

	a {
		z-index: 1;
	}

	svg {
		height: ${({ size }) => `${size}rem`};
		width: ${({ size }) => `${size}rem`};
		z-index: 1;

		path {
			fill: ${({ active, color }) => (active ? Colors.white : color)};
		}

		line {
			stroke: ${({ active, color }) => (active ? Colors.white : color)};
		}
	}

	${({ active, size }) =>
		active &&
		css`
			cursor: default;
			svg{
				fill: '#ffffff';
			}
			&::before {
				// content: '';
				// background-color: #ff47b1;
				// height: ${size + size * 0.2}rem;
				// width: ${size + size * 0.2}rem;
				// position: absolute;
				// left: -${size * 0.1}rem;
				// top: -${size * 0.1}rem;
				// border-radius: 50%;
			}
		`}

	${({ disabled, interactable, size }) =>
		interactable &&
		!disabled &&
		css`
			:focus,
			:hover {
				&::before {
					content: '';
					height: ${size + size * 0.2}rem;
					width: ${size + size * 0.2}rem;
					position: absolute;
					left: -${size * 0.1}rem;
					top: -${size * 0.1}rem;
					border-radius: 50%;
				}
			}
		`}
`;
