import { ControlBar } from 'amazon-chime-sdk-component-library-react';
import styled from 'styled-components';

import { Icon } from 'components/icons';
import {PrimaryButton, SecondaryButton} from 'components/widgets';
import { Colors, MediaQueries, Sizes } from 'environment';
import { PlacementProps } from 'types';

export const EventManagerContainer = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
`;

interface AlignProps {
  align?: {
    baseline?: boolean;
  };
  margin?: PlacementProps;
}

export const Block = styled.div<AlignProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${({ align }) => (align?.baseline ? 'baseline' : 'center')};
  z-index: 1;
  flex: 1 1 0%;

  @media only screen and (max-width: ${Sizes.uhd}px) {
    align-items: center;
    margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : 0)};
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 14.6rem;
  padding-right: 12.5rem;

  @media only screen and (max-width: ${Sizes.uhd}px) {
    padding: unset;
  }
`;

export const Content = styled.div`
display: grid;
  width: 100%;
  flex: 1;
  justify-content: space-between;

  @media only screen and (max-width: ${Sizes.uhd}px) {
    display: contents;
    align-items: center;
    padding-top: 10rem;
    padding-bottom: 10rem;
    width: auto;
  }
  @media ${MediaQueries.phoneMax}{
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    padding-left:1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem !important;
  }
  
`;

export const LogoContainer = styled.div`
  height: 14.3rem;
  width: 38.1rem;
  position: relative;
  display: flex;
   @media ${MediaQueries.tabletMax} {
      width: 100%;
      height: inherit !important;
   }
  @media only screen and (max-width: ${Sizes.uhd}px) {
    justify-content: center;
  }
   @media ${MediaQueries.phoneMax} {
     width: 100%;
      height: inherit !important;
   }
`;

export const Logo = styled(Icon)`
  position: absolute;
  top: 1%;

  @media ${MediaQueries.tabletMax} {
    position: relative;
    > svg{
        width: 17rem;
        margin: 0 auto;
        height:inherit;
    }
  }
  @media ${MediaQueries.phoneMax} {
    position: relative;
    > svg{
         width: 9rem;
        margin: 0 auto;
        height:inherit;
    }
  }
`;

export const Related = styled.div`
  display: flex;
  width: 100%;
  height: 28rem;
  overflow-x: auto;
  padding: 0 2rem 1.5rem;
  box-sizing: border-box;
  z-index: 1;
  background-color: ${Colors.black};

  > div {
    width: 360px;
    min-width: 360px;
    max-width: 360px;
    margin-right: 0;
    padding-right: 2rem;
    transition: 0.3s ease-in-out;

    &:hover {
      transform: translateY(-0.6rem);
    }
  }

  ::-webkit-scrollbar {
    background: transparent;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
  }
`;

export const RelatedTitle = styled.div`
  font-family: Comfortaa;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  z-index: 1;
  color: ${Colors.white};
  background-color: ${Colors.black};
  padding: 1rem;
  text-align: center;
  display: block;
`;

export const RelatedWrapper = styled.div`{
  display: block;
  width: 100%;
  
} `;

export const Image = styled.div<{ src: string }>`
  position: absolute;
  background: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

export const BarControl = styled(ControlBar)`
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  width: 95%;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  bottom: 0rem;
  right: 2rem;
  padding-right: 2rem;
  height: 6rem;

  .ch-control-bar-item {
    margin-right: 2rem;
  }

  .ch-control-bar-item-iconButton {
    color: white;
    :focus,
    :hover {
      background: unset;
      box-shadow: unset;
    }
  }

  .ch-control-bar-item-caret {
    color: white;
    width: 3rem;
    height: 3rem;

    :focus,
    :hover {
      background: unset;
      box-shadow: unset;
      width: 3rem;
      height: 3rem;
    }
  }

  .ch-control-bar-item-iconButton {
    //margin-right: 3rem;

    .ch-icon {
      width: 3.5rem;
      height: 3.5rem;
    }

    :focus,
    :hover {
      box-shadow: unset;
      width: 4rem;
      border: unset;
      background: unset;
      height: 4rem;
    }
  }
`;

export const EndSubtitle = styled.h4`
  font-size: clamp(2rem, 6vw, 2.1rem);
  color: ${Colors.white};

  @media (max-width: ${Sizes.uhd}8px) {
    // top: -15rem;
  }
`;

export const CreatorName = styled.h1`
@media (max-width: ${Sizes.uhd}px) { 
  font-size: 3rem;
 }
`;

export const EndTitle = styled.h1`
  font-size: clamp(2rem, 8vw, 5rem);
  color: ${Colors.white};
`;

export const CancelledTitle = styled.h1`
  font-size: clamp(4.5rem, 8vw, 5rem);
  color: ${Colors.white};
`;

export const EndDescription = styled.h6`
  padding: 1%;
  color: ${Colors.white};

  @media only screen and (max-width: ${Sizes.uhd}px) {
    text-align: center;
  }
`;

export const CreatorImage = styled.div<{ src: string }>`
  
  background: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position:right top;
  height: 100%;
  width: 100%;
  border-top-right-radius: 2.2rem;
  border-bottom-right-radius: 2.2rem;
`;

export const CreatorTitle = styled.p`
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  color: white;
  @media ${MediaQueries.phoneMax} {
    font-size: 1rem;
  }
  
`;

export const CreatorAfterEventContainer = styled.div`
  width: clamp(347px, 53vw, 777px);
  height: clamp(146px, 24vw, 184px);
  background: #0f1119;
  box-shadow: 0px 3px 50px rgba(241, 78, 180, 0.68);
  border-radius: 22px;
  position: relative;
  object-fit: cover;
  margin-bottom: 1%;
  border-top-left-radius: 2.2rem;
  border-bottom-left-radius: 2.2rem;
`;

export const CreatorAfterEventContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const CreatorAfterEventNameContainer = styled.div`
  width: 100%;
  padding-top:2rem;
`;

export const CreatorAfterEventName = styled.p`
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  width: 100%;
  white-space: wrap;
  overflow: hidden;
  font-size: 3rem;
  color: white;
  line-height: unset;
  padding: 0 2rem;

  @media ${MediaQueries.biggerPhone} {
    font-size: 2.5rem;
    text-align: center;
  }
  @media ${MediaQueries.phoneMax} {
    font-size: 2rem;
    text-align: center;
  }
`;

export const CreatorAfterEventName2 = styled.p`
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: clamp(1.4rem, 3vw, 3rem);
  color: white;
  line-height: unset;
  padding: 0 clamp(2.4rem, 2vw, 2rem);
`;

export const CreateAfterEventTitleContainer = styled.div`
  border: 3px solid #4a4a4a;
  background: #1c1e25;
  padding: clamp(0.2rem, 2vw, 1.5rem) clamp(1.1rem, 2vw, 3rem);
  margin: 1rem clamp(2rem, 2vw, 2rem);
  max-width: 37rem;
  max-height: 10rem;
  width: clamp(14rem, 25vw, 37rem);
  overflow: hidden;
  text-align:center;
  
  @media ${MediaQueries.biggerPhone} {
    text-align: center;
    margin:1.5rem auto;
    padding:1rem;
  }
  @media ${MediaQueries.phoneMax} {
    width:80%;
  }
  
`;

export const BackgroundGradientLeft = styled.div`
  position: absolute;
  width: 871px;
  height: 100%;
  z-index: 1;
  left: 0;
  float:left;
  background: linear-gradient(270deg, rgba(15, 17, 25, 0.8) 50%, rgba(15, 17, 25, 0) 88.05%);
  transform: rotate(180deg);
`;

export const BackgroundGradientRight = styled.div`
  position: absolute;
  width: 871px;
  height: 100%;
  z-index: 1;
  right: 0;
  float:right;
  background: linear-gradient(270deg, rgba(15, 17, 25, 0.8) 50%, rgba(15, 17, 25, 0) 88.05%);
`;

export const BackgroundGradient = styled.div`
  position: absolute;
  z-index: 1;
  background: rgba(15, 17, 25, 0.25);
  width: 100%;
  height: 100%;
`;

export const LiveDot = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3rem;
  background: red;
  animation: 1s blink ease infinite;
  @keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;

export const LiveTitle = styled.h6`
  color: white;
  margin-left: 1rem;
`;

export const LiveAnnouncement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2rem;

  @media ${MediaQueries.smallerPhone} {
    top: 2rem;
    left: 2rem;
    display: none;
  }
`;

export const LiveAnnouncementMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  display: none;
  
  @media ${MediaQueries.smallerPhone} {
    margin-left:1rem;
    display: flex;
    position: relative;

  }
  @media ${MediaQueries.sm1Phone} {
    margin-left:1rem;
    display: flex;
    position: absolute;
    top:4rem;
  }
  
`;




export const Button = styled(PrimaryButton)`
  width: clamp(28rem, 74vw, 37.5rem);
  @media ${MediaQueries.tabletMax}{
     width: 100%;
  }
   @media ${MediaQueries.phoneMax}{
     width: 100%;
  }
`;

export const SButton = styled(SecondaryButton)`
  width: clamp(28rem, 74vw, 37.5rem);
  
  @media ${MediaQueries.tabletMax}{
     width: 100%;
  }
  @media ${MediaQueries.phoneMax}{
     width: 100%;
  }
`;

export const ContentInline = styled.div`
 widht: 100%;
 display: flex;
 justify-content: space-between;
`;


export const UtilitiesBar = styled.div`
 display: flex;
  position: absolute;
  top: 0.5rem;
  left: 3rem;
  flex-direction: row;
  align-items: center;
  font-family: Comfortaa,sans-serif;

  @media ${MediaQueries.phone} {
    //top: 0.5rem;
    //left: 1.5rem;
  }
  
  @media ${MediaQueries.tabletMax} {
   
  }
  @media ${MediaQueries.phoneMax} {
  
  }
`;

export const UtilitiesBarHeader = styled.div`
 display: flex;
  position: absolute;
  top: 1.7rem;
  right: 21rem;
  flex-direction: row;
  align-items: center;
  font-family: Comfortaa,sans-serif;

  @media ${MediaQueries.phone} {
    //top: 0.5rem;
    //left: 1.5rem;
  }

  
  @media ${MediaQueries.tabletMax} {
   
  }
  @media ${MediaQueries.phoneMax} {
    right: 8rem;
  }
`;


export const AttendeeCount = styled.div`
  background-color: ${Colors.darkerBrown};
  border-radius: 2rem;
  padding: 2px 10px;
  cursor: pointer;
  svg{
    width: 3rem;
    color: white;
  }
  span{
    color: ${Colors.white};
  }
  :hover{
    background-color: ${Colors.darkestBrown};
  }
`;


