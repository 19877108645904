import { BaseModal, PrimaryButton, SecondaryButton } from 'components/widgets';
import { MediaQueries } from 'environment';
import { useMediaQuery } from 'hooks';

import { ButtonContainer } from './ConfirmationModal.style';

interface Props {
	title?: string;
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
}

export function ConfirmationModal({ title, open, onClose, onSubmit }: Props) {
	const isPhone = useMediaQuery(MediaQueries.phone);

	return (
		<BaseModal
			open={open}
			onSubmit={onSubmit}
			onClose={onClose}
			title={title ?? `Are you sure you wish to leave?`}
		>
			<ButtonContainer>
				<SecondaryButton fontSize={1.8} fontWeight="normal" width={29} onClick={onClose}>
					Cancel
				</SecondaryButton>
				<PrimaryButton
					fontSize={1.8}
					fontWeight="normal"
					width={29}
					margin={{ bottom: isPhone ? 3 : 0, left: isPhone ? 0 : 2 }}
					onClick={onSubmit}
				>
					Confirm
				</PrimaryButton>
			</ButtonContainer>
		</BaseModal>
	);
}
