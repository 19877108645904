import { createContext, useContext } from 'react';
import { Socket } from 'socket.io-client';

interface ContextValue {
	socket: Socket;
}

export const SocketsContext = createContext<ContextValue>({
	socket: {} as Socket,
});

export function useSocketsContext() {
	const context = useContext(SocketsContext);
	return context;
}
