import { useEffect } from 'react';
import { useRealitimeSubscribeChatState } from '../chat/RealtimeSubscribeChatProvider';
import { MeetingStatus } from 'amazon-chime-sdk-component-library-react';
import styled from "styled-components";
import {MediaQueries,Colors} from "../../environment";
import Expire from "./Expire";
import { ATTENDEE_NOTIFY } from "../../consts";
import './Notify.scss';

type Props = {
  name: string;
  meetingStatus: number;
};

export const Notify = ({ name, meetingStatus }: Props) => {
  const { attendeeNotifyData, sendAttendeeNotifyData } = useRealitimeSubscribeChatState(); 

  useEffect(() => {
    const onPageLoad = () => {
      sendAttendeeNotifyData(name, ATTENDEE_NOTIFY.JOIN, ATTENDEE_NOTIFY.JOIN_CLASS);
    };

    if (MeetingStatus.Succeeded === meetingStatus) {
      onPageLoad();
    }
  }, [meetingStatus]);

  window.onunload = function () {
    sendAttendeeNotifyData(name, ATTENDEE_NOTIFY.LEFT, ATTENDEE_NOTIFY.LEFT_CLASS);
  };

  return (
    <>
      <NotificationMessageWrapper>
        {attendeeNotifyData.map((r: any, i: any) => (
          <Expire delay={2000} key={i}>
            <NotificationBlock className={`notification-hide ${r.dynamicClass}`} >
              {r.attendeeName} has {r.state}
            </NotificationBlock>
          </Expire>
        ))}
      </NotificationMessageWrapper>
    </>
  );
};


export const NotificationMessageWrapper = styled.div`
  position: absolute;
  top: 4rem;
`;

export const NotificationBlock = styled.div`
  background-color: rgb(31 196 240 / 67%);
  color: ${Colors.white}
  border-radius: 5px;
  //box-shadow: 0px -2px 12px -9px #000000;
  font-family: Comfortaa;
  padding: 1rem 2rem;
  margin: 10px 0 10px 30px;
  font-size: 1.5rem;

  &.attendee-leave {
    background-color: rgb(246 24 24 / 67%);
  }

  @media ${MediaQueries.phone} {

  }
  @media ${MediaQueries.phone} {

  }
`;


