import styled from 'styled-components/macro';
import { Colors, Images, MediaQueries, Sizes } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// min-height: ${Sizes.md / 10}rem;
	overflow: hidden;
	position: relative;
	height: 100%;
	width: 100%;
	background: ${Colors.darkest};
	background-image: url(${Images.Tiles});
	background-repeat: repeat;
	flex: 1;

	@media ${MediaQueries.tablet} {
		justify-content: center;
	}
`;

export const LoginContainer = styled.div`
	min-width: 40rem;
	height: 90%;
	border-radius: 1rem;
	background: ${Colors.darkest};
	border: 1px solid ${Colors.darkestBrown};
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 3px 50px #55c2e6;

	@media ${MediaQueries.phone} {
		min-width: unset;
		width: 90%;
	}
`;

export const Title = styled.p`
	font-weight: 400;
	font-size: 2rem;
	margin-bottom: 6.5rem;
	color: ${Colors.white};
`;

export const Terms = styled.p`
	font-family: Comfortaa;
	font-style: normal;
	font-weight: bold;
	font-size: 1.2rem;
	line-height: 2.1rem;
	text-align: center;
	max-width: 22rem;
	margin-bottom: 3rem;
	color: ${Colors.darkerBrown};
`;

export const NoAccount = styled.p`
	font-family: Comfortaa;
	font-style: normal;
	font-weight: bold;
	font-size: 1.6rem;
	line-height: 0.5rem;
	max-width: 22.6rem;
	margin-bottom: 2rem;
	color: ${Colors.darkerBrown};
`;
