import { EventStatus } from 'biim-api';
import styled from 'styled-components';

import { Icon } from 'components/icons';
import { Colors, Images } from 'environment';
import { BiimCard } from 'components/displayers/Card';
import {
  useEffectOnce,
  useEventContext,
  useEventStatusContext,
  useGetRelatedBiims,
  useNavbarColorContext,
  useNavbarUIContext,
} from 'hooks';

import { Image } from './Guest.styles';
import {
  EndDescription,
  EndSubtitle,
  EndTitle,
  CreateAfterEventTitleContainer,
  CreatorAfterEventContainer,
  CreatorAfterEventContent,
  CreatorAfterEventName,
  CreatorImage,
  CreatorTitle,
  Related,
  RelatedTitle,
  CreatorAfterEventNameContainer,
  CancelledTitle,
} from '../commonStyles.style';

import Ratings from '../../../components/widgets/Ratings/Ratings';
import React from 'react';

export function GuestAfterEvent() {
  const { eventData: data } = useEventContext();
  const relatedBiims = useGetRelatedBiims(data.relatedProducts);
  const { setIsEnabled } = useNavbarUIContext();
  const { type, switchColor } = useNavbarColorContext();
  const { status } = useEventStatusContext();

  useEffectOnce(() => {
    console.log(`Guest After Event ${process.env.REACT_APP_BIIM_STORE_URL}`);
    setIsEnabled(false);
    if (type === 'dark') switchColor('colored');
  });
  return (
    <Container>
      <Image src={Images.FinishBg} />
      <Content>
        <CreatorAfterEventContainer>
          <CreatorAfterEventContent>
            <CreatorImage src={data.images[0].src}>
              <CreatorAfterEventNameContainer>
                <CreatorAfterEventName>{data.creatorName}</CreatorAfterEventName>
              </CreatorAfterEventNameContainer>
              <CreateAfterEventTitleContainer>
                <CreatorTitle>{data.title}</CreatorTitle>
              </CreateAfterEventTitleContainer>
            </CreatorImage>
          </CreatorAfterEventContent>
        </CreatorAfterEventContainer>
        <EndSubtitle>This biim has now</EndSubtitle>
        {status === EventStatus.Finished && <EndTitle>FINISHED</EndTitle>}
        {status === EventStatus.Cancelled && <CancelledTitle>CANCELLED</CancelledTitle>}
        <EndSubtitle>Thanks for joining</EndSubtitle>
        {data.creatorTippingProductId &&
          localStorage.getItem(
            `tip-${data.uuidEvent}-${String(new Date().toLocaleDateString('en-GB'))}`
          ) && (
            <EndSubtitle
              style={{ fontSize: 'clamp(2rem,6vw,1.7rem)', display: 'flex', margin: '1rem 0' }}
            >
              <Icon type={(t) => t.TipIcon} size={3} color="white" />
              <div
                style={{
                  marginLeft: '1rem',
                }}
              >
                You have tipped the creator&nbsp;
                <a
                  href={`${process.env.REACT_APP_BIIM_STORE_URL}/product/${
                    data.creatorTippingProductId
                  }/?tipAmount=${localStorage.getItem(
                    `tip-${data.uuidEvent}-${String(new Date().toLocaleDateString('en-GB'))}`
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: `${Colors.pink}`,
                    textDecoration: 'none',
                    fontSize: 'clamp(2rem,6vw,1.7rem)',
                  }}
                >
                  click here
                </a>
                &nbsp;to proceed with payment !
              </div>
            </EndSubtitle>
          )}
        <Icon type={(t) => t.Logo} color="undefined" size={12} />
        {status === EventStatus.Finished && (
          <EndDescription>
            The recorded biim will be available in 48hrs please check back to re-watch this biim
          </EndDescription>
        )}
      </Content>
      <Ratings uuidEvent={data.uuidEvent} />
      <RelatedTitle>Biims you might enjoy</RelatedTitle>
      <Related>
        {relatedBiims &&
          relatedBiims.map((biim, idx) => (
            <BiimCard
              margin={{ right: 2 }}
              key={`${biim?.id}-${idx}`}
              availability={data.availabilityDate ?? ''}
              creatorName={biim?.creatorName ?? ''}
              title={biim?.title ?? ''}
              imgSrc={biim?.images[0].src ?? ''}
              profileImgSrc={biim?.creatorImage ?? ''}
              price={biim?.price ?? 0}
              permalink={biim?.permalink ?? ''}
              stock={biim?.stockInitial ?? 0}
              remainingStock={biim?.stockRemaining ?? 0}
            />
          ))}
      </Related>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  animation: fade-in 2s;
`;

const Content = styled.div`
  width: 100%;
  flex: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-top: 6.1rem;
  align-items: center;
`;
