import React, { ReactNode, useContext } from 'react';

import { RealitimeSubscribeChatStateProvider } from './RealtimeSubscribeChatProvider';

type RealtimeDataAction = 'sendmessage';
type RealtimeDataCmd = 'TEXT' | 'WHITEBOARD';

type Props = {
  children: ReactNode;
};

export type RealtimeData = {
  uuid: string;
  action: RealtimeDataAction;
  cmd: RealtimeDataCmd;
  data: any;
  createdDate: number;
  senderName: string;
  senderColor: string;
};

export type RealtimeReactionData = {
  uuid: string;
  action: RealtimeDataAction;
  cmd: RealtimeDataCmd;
  className1: any;
  className2: any;
  createdDate: number;
  senderName: string;
  senderColor: string;
};

export type RealtimeAttendeeNotifyData = {
  uuid: string;
  action: RealtimeDataAction;
  cmd: RealtimeDataCmd;
  attendeeName: any;
  state: any;
  createdDate: number;
  dynamicClass: string;
};

export type MuteAllAttendeeData = {
  uuid: string;
  action: RealtimeDataAction;
  cmd: RealtimeDataCmd;
  data: any;
  createdDate: number;
  senderName: string;
};

export const RealitimeSubscribeStateContext = React.createContext<Record<string, unknown> | null>(
  null
);

export const useRealitimeSubscribeState = (): Record<string, unknown> => {
  const state = useContext(RealitimeSubscribeStateContext);
  if (!state) {
    throw new Error('Error using RealitimeSubscribe in context!');
  }
  return state;
};

export const RealitimeSubscribeStateProvider = ({ children }: Props) => {
  const providerValue = {};
  return (
    <RealitimeSubscribeStateContext.Provider value={providerValue}>
      <RealitimeSubscribeChatStateProvider>{children}</RealitimeSubscribeChatStateProvider>
    </RealitimeSubscribeStateContext.Provider>
  );
};
