import { ReactionBarSelector } from '../../components/widgets/Reactions/components';
import { useRealitimeSubscribeChatState } from '../chat/RealtimeSubscribeChatProvider';
import './Reactions.style.scss';

import {
  ReactionBar,
} from './Reactions.styles';

export const Reactions = () => {
  const { reactionData, sendReactionData, tipData } = useRealitimeSubscribeChatState(); // <---- (1)

  return (
    <>
      <div className="reactions-wrap">
        {reactionData.map((r, i) => (
          <div className={`${r.className2}-animation`} key={i}>
            <div className={`${r.className1}`}></div>
          </div>
        ))}
         {tipData.map((r, i) => (
          <div className={`tip-animation`} key={i}>
            <div className={`tip tani1`}></div>
          </div>
        ))}
      </div>

      <ReactionBar>
        <ReactionBarSelector
          onSelect={(label) => sendReactionData(label)}
        />
      </ReactionBar>
    </>
  );
};
