import Tiles from '../assets/images/tiles.png';
import Logo from '../assets/images/logo.png';
import Test from '../assets/images/back-test.png';
import FinishBg from '../assets/images/finish-event-bg.png';

export const Images = {
	Tiles,
	Logo,
	Test,
	FinishBg
};
