import styled from 'styled-components';
import { Typography } from 'components/displayers';
import { PlacementProps } from 'types';

interface MarginProps {
	margin?: PlacementProps;
}

export const Container = styled.div<MarginProps>`
	display: flex;
	align-items: center;
	padding: 0.8rem;
	height: 4.8rem;
	border-radius: 0.4rem;
	border: black;
	background: black;
	margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
	margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
	margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
	margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
`;

export const P = styled(Typography.P)`
	color: black;
`;

export const Aside = styled.div`
	margin-left: auto;
`;
