import styled, { css } from 'styled-components/macro';

export interface ContainerProps {
	disabled: boolean;
	focus: boolean;
}

export const Container = styled.div<ContainerProps>`
	background-color: white;
	box-shadow: 0px 0px 3px rgba(255, 255, 255, 0.12), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 1.1rem;
	color: black;
	display: flex;
	flex-direction: column;
	min-height: 4.8rem;
	outline: none;
	position: relative;
	transition: border-color 0.2s, color 0.2s;

	${({ disabled }) =>
		!disabled &&
		css`
			:hover {
				border-color: blue;
			}
		`}

	${({ focus }) =>
		focus &&
		css`
			border-color: blue;
		`}
`;
