import { H2, H3, H4, H5, H6, P, Span } from './Typography.style';

export function Typography() {
	return null;
}

Typography.H2 = H2;
Typography.H3 = H3;
Typography.H4 = H4;
Typography.H5 = H5;
Typography.H6 = H6;
Typography.P = P;
Typography.Span = Span;
