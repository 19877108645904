import { AuthRequestConfig } from '../../generics';
import { axios, buildUrl, serializeInput } from '../../utils';
import { Credentials } from './models';
import { RefreshTokenData, LoginData } from './types';

const endpoints = {
	login: '/auth',
	refresh: '/auth/refreshToken',
};

export default (token?: AuthRequestConfig) => ({
	login: async function({ username, password }: Credentials) {
		const url = buildUrl(endpoints.login);
		const { data } = await axios.post<LoginData>(url, { username, password });
		return data;
	},
	refreshToken: async function(refreshToken: string) {
		const url = buildUrl(endpoints.refresh);

		const params = serializeInput({
			accessToken: (token ? token.accessToken : ''),
			refreshToken: refreshToken,
		});
		const { data } = await axios.post<RefreshTokenData>(url, params);

		return data.accessToken;
	},
});
