import { Colors } from 'environment';
import { createContext, useContext } from 'react';

interface ContextValue {
	color: string;
	type: 'dark' | 'colored';
	switchColor: (color: 'dark' | 'colored') => void;
}

export const NavbarColorContext = createContext<ContextValue>({
	color: Colors.darkest,
	type: 'colored',
	switchColor: () => null,
});

export function useNavbarColorContext() {
	const context = useContext(NavbarColorContext);
	return context;
}
