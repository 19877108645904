import { useLocation, Link } from 'react-router-dom';
import { Icon } from 'components/icons';
import { CustomDropdown, HamburgerMenu, PrimaryButton } from 'components/widgets';
import { Images, MediaQueries } from 'environment';
import {
  useLogin,
  useLogout,
  useMediaQuery,
  useNavigation,
  usePlatformContext,
  useNavbarColorContext,
  useNavbarUIContext,
} from 'hooks';
import { Container, Logo, Right, SupportIcon } from './Navbar.style';
import { HeaderUtilitiesBar } from '../UtilitiesBar';

export function Navbar() {
  const { color } = useNavbarColorContext();
  const isPhone = useMediaQuery(MediaQueries.phone);
  const { navigate, replace, routes } = useNavigation();
  const { isTv } = usePlatformContext();
  const { isEnabled } = useNavbarUIContext();
  const location = useLocation();
  const logout = useLogout();
  const [{ data }] = useLogin();

  function handleLogoutClick() {
    replace(routes.root);
    logout();
  }

  function handleLogin() {
    navigate(routes.login);
  }

  function handleOnSelect(route: string) {
    navigate(route);
  }

  function checkPath() {
    return location.pathname.length >= 40;
  }

  if (isTv) {
    return (
      <Container color={color}>
        <Icon type={(t) => t.ShortLogo} color="undefined" onClick={() => navigate(routes.root)} />
        <Right>
          <PrimaryButton onClick={handleLogoutClick}>Logout</PrimaryButton>
        </Right>
      </Container>
    );
  }

  return (
    <Container color={color}>
      {!checkPath() && (
        <CustomDropdown
          width={isPhone ? '100vw' : 25}
          offset={isPhone ? { top: 60, left: -33 } : { top: 60, left: -40 }}
          toggleComponent={({ onToggle, ref, open }) => (
            <HamburgerMenu
              ref={ref}
              isOpen={open}
              onClick={() => {
                onToggle();
              }}
            />
          )}
        >
          <CustomDropdown.Item
            menu
            style={{
              justifyContent: 'center',
            }}
            active={routes.root === location.pathname}
            onSelect={() => handleOnSelect(routes.root)}
            onClick={() => handleOnSelect(routes.root)}
          >
            Home
          </CustomDropdown.Item>
          <CustomDropdown.Item
            menu
            style={{ justifyContent: 'center' }}
            active={routes.admin === location.pathname}
            onSelect={() => handleOnSelect(routes.admin)}
            onClick={() => handleOnSelect(routes.admin)}
          >
            Biims
          </CustomDropdown.Item>
          {data?.username ? (
            <CustomDropdown.Item
              menu
              style={{ justifyContent: 'center' }}
              onClick={handleLogoutClick}
            >
              Logout
            </CustomDropdown.Item>
          ) : (
            <CustomDropdown.Item
              menu
              style={{ justifyContent: 'center' }}
              active={routes.login === location.pathname}
              onClick={handleLogin}
            >
              Login
            </CustomDropdown.Item>
          )}
        </CustomDropdown>
      )}
      {/* TODO: on event disable click */}
      {location.pathname.match(/([a-z0-9]*-){1,5}[a-z0-9]*/gm) ? (
        <Link to={routes.admin} target="_blank" rel="noopener noreferrer">
          <Logo
            style={{
              left: checkPath() ? '4rem' : '9.3rem',
              position: checkPath() ? 'initial' : 'absolute',
            }}
            src={Images.Logo}
          />
        </Link>
      ) : (
        <Logo
          style={{
            left: checkPath() ? '4rem' : '9.3rem',
            position: checkPath() ? 'initial' : 'absolute',
          }}
          src={Images.Logo}
          onClick={() => handleOnSelect(routes.admin)}
        />
      )}
      {isEnabled && <HeaderUtilitiesBar />}
      <Right>
        {location.pathname.split('/')[2] !== 'recording' && (
          <SupportIcon
            onClick={(e) => {
              window.open('mailto:help@biim.tv');
              e.preventDefault();
            }}
          >
            <>
              <Icon size={2} color="white" type={(t) => t.QuestionMark} />
              <span>Need Support?</span>
            </>
          </SupportIcon>
        )}
      </Right>
    </Container>
  );
}
