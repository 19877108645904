import { Circle, Container, Outer, Title } from './RadioButton.style';

interface Props {
	selected: boolean;
	title: string;
	onSelect: () => void;
}

export function RadioButton({ selected, title, onSelect }: Props) {
	return (
		<Container onClick={onSelect}>
			<Outer>{selected && <Circle />}</Outer>
			<Title selected={selected}>{title}</Title>
		</Container>
	);
}
