import React, { useEffect } from 'react';
import { io } from 'socket.io-client';

import { SocketsContext } from 'hooks/context/useSocketsContext';

interface Props {
  children: React.ReactNode;
}

export function Sockets({ children }: Props) {
  //TODO: remove this comment, this is for testing purpose
  const socket = io(String(process.env.REACT_APP_BASE_URL));

  useEffect(() => {
    socket.on('connect', () => {
      //console.log(socket.id);
    });

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  return <SocketsContext.Provider value={{ socket }}>{children}</SocketsContext.Provider>;
}
