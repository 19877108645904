import { Icon } from 'components/icons';
import {Colors, MediaQueries} from 'environment';
import styled from 'styled-components';

export const Content = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	top: 2rem;
	background: rgba(15, 17, 25, 0.7);
	z-index: 1000;
	border-radius: 2.2rem;
	transition: right 1s;
`;

export const BackgroundGradientLeft = styled.div`
	position: absolute;
	width: 100%;
	height: 20%;
	border-top-left-radius: 2.2rem;
     border-top-right-radius: 2.2rem;
	z-index: 0;
	left: 0;
	background: rgba(15, 17, 25, 0.25);
`;

export const InfoIcon = styled(Icon)<{ isOpen: boolean }>`
	position: absolute;
	top: 2rem;
	left: ${({ isOpen }) => (isOpen ? '2rem' : '-9rem')};
	transition: left 1ms ease-in-out;
	transition-delay: ${({ isOpen }) => (isOpen ? undefined : '1s')};
`;

export const Creator = styled.div<{ imgUrl: string }>`
	display: flex;
	flex-direction: column;
	background-image: ${({ imgUrl }) => `url(${imgUrl})`};
	background-size: cover;
	border-top-left-radius: 2.2rem;
  border-top-right-radius: 2.2rem;
	height: 25.6rem;
	padding-right: 45%;
	padding-left: 7.5rem;
	justify-content: center;
    @media ${MediaQueries.smallerPhone} {
    padding: 5rem 2rem;
  }
`;

export const CreatorTitle = styled.span`
	font-family: Comfortaa;
	font-style: normal;
	font-weight: bold;
	line-height: 2rem;
	font-size: 1.8rem;
	color: ${Colors.white};
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	direction: ltr;
	z-index: 1;
`;

export const CreatorSubtitleContainer = styled.div`
	border: 0.2rem solid ${Colors.gray};
	padding: 0.5rem;
	margin-top: 1rem;
	background-color: rgba(255, 255, 255, 0.1);
	display: flex;
	height: auto;
	z-index: 1;
`;

export const CreatorSubtitle = styled.span`
	font-family: Comfortaa;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: ${Colors.white};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	direction: ltr;
	z-index: 1;
`;

export const DateContainer = styled.div`
	padding: 1.4rem;
	width: 100%;
	background: ${Colors.black};
`;

export const DateTitle = styled.h6`
	text-transform: uppercase;
	letter-spacing: 1.248px;
	text-align: center;
	color: ${Colors.white};
`;

export const EventDescriptionContainer = styled.div`
	z-index: 1;
	margin-bottom: 4rem;
	max-height: 30rem;
	padding: 5rem 7rem;
    overflow-y: scroll;
  @media ${MediaQueries.smallerPhone} {
    padding: 5rem 2rem;
  }
  
`;

export const EventTitleDescription = styled.h3`
	color: ${Colors.white};
	margin-bottom: 1rem;
	font-family: Comfortaa;
	font-style: normal;
	font-weight: bold;
	font-size: 2rem;
	line-height: 2.5rem;
`;

export const EventDescription = styled.div`
	padding-top: 4.6rem;
	color: ${Colors.white};
    font-family: Comfortaa;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
`;

export const EventModal = styled.div`
  position: absolute;
  z-index: 100;
  font-family: Comfortaa;
	font-style: normal;
	font-weight: normal;

`;
export const IconWrap = styled.div`
left: 6rem;
position: absolute;
  top: 0.5rem;
`;

