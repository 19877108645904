import { ReactComponent as ShortLogo } from '../assets/icons/short-logo.svg';
import { ReactComponent as Logo } from '../assets/icons/logo.svg';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as Clear } from '../assets/icons/clear.svg';
import { ReactComponent as Profile } from '../assets/icons/profile.svg';
import { ReactComponent as Settings } from '../assets/icons/settings.svg';
import { ReactComponent as Share } from '../assets/icons/share.svg';
import { ReactComponent as Calendar } from '../assets/icons/calendar.svg';
import { ReactComponent as TeardropEmpty } from '../assets/icons/teardrop-empty.svg';
import { ReactComponent as Teardrop } from '../assets/icons/teardrop.svg';
import { ReactComponent as ArrowLeft } from '../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowUp } from '../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow-down.svg';
import { ReactComponent as InfoEvent } from '../assets/icons/info-event.svg';
import { ReactComponent as Message } from '../assets/icons/message.svg';
import { ReactComponent as MessageMute } from '../assets/icons/message-mute.svg';
import { ReactComponent as Mail } from '../assets/icons/mail.svg';
import { ReactComponent as GrayMail } from '../assets/icons/gray-mail.svg';
import { ReactComponent as ChatDesktop } from '../assets/icons/chat-desktop.svg';
import { ReactComponent as ChatMobile } from '../assets/icons/chat-mobile.svg';
import { ReactComponent as FilmPlay } from '../assets/icons/film.svg';
import { ReactComponent as YoutubeIcon } from '../assets/icons/youtube.svg';
import { ReactComponent as SpotifyIcon } from '../assets/icons/spotify.svg';
import { ReactComponent as EnlargeIcon } from '../assets/icons/enlarge.svg';
import { ReactComponent as QuestionMark } from '../assets/icons/question_mark.svg';
import { ReactComponent as Mic } from '../assets/icons/mic.svg';
import { ReactComponent as MicMute } from '../assets/icons/mute_mic.svg';
import { ReactComponent as Talk } from '../assets/icons/talk.svg';
import { ReactComponent as TalkStop } from '../assets/icons/talk_stop.svg';
import { ReactComponent as Speaker } from '../assets/icons/speaker.svg';
import { ReactComponent as SpeakerMute } from '../assets/icons/speaker_mute.svg';
import { ReactComponent as UsersTalk } from '../assets/icons/talk2.svg';
import { ReactComponent as UsersTalkStop } from '../assets/icons/talk2_stop.svg';
import { ReactComponent as TipIcon } from '../assets/icons/pound-coin.svg';
import { ReactComponent as ChatDesktopUnread } from '../assets/icons/chat-desktop-unread.svg';
import { ReactComponent as ChatMobileUnread } from '../assets/icons/chat-mobile-unread.svg';

export const Icons = {
  Logo,
  ShortLogo,
  Close,
  Clear,
  Profile,
  Settings,
  Share,
  Calendar,
  Teardrop,
  TeardropEmpty,
  ArrowLeft,
  ArrowUp,
  ArrowDown,
  InfoEvent,
  Message,
  MessageMute,
  Mail,
  GrayMail,
  ChatDesktop,
  ChatMobile,
  FilmPlay,
  YoutubeIcon,
  SpotifyIcon,
  EnlargeIcon,
  QuestionMark,
  Mic,
  MicMute,
  Talk,
  TalkStop,
  Speaker,
  SpeakerMute,
  UsersTalk,
  UsersTalkStop,
  TipIcon,
  ChatDesktopUnread,
  ChatMobileUnread,
};
