export const Sizes = {
	xs: 320,
	sm1:375,
	sm: 425,
	mm: 637,
	md: 768,
	lmd: 850,
	lg: 1024,
	xl: 1280,
	hd: 1440,
	hhd: 1736,
	fhd: 1920,
	qhd: 2560,
	uhd: 3840,
};

export const MediaQueries = {
	xsPhone: `only screen and (max-width: ${Sizes.xs}px)`,
	sm1Phone: `only screen and (max-width: ${Sizes.sm1}px)`,
	smPhone: `only screen and (max-width: ${Sizes.sm}px)`,
	smallerPhone: `only screen and (max-width: ${Sizes.mm - 1}px)`,
	phone: `only screen and (max-width: ${Sizes.lmd - 1}px)`,
	phoneMax:`only screen and (max-width: ${Sizes.md -1}px)`,
	tabletMax:`only screen and (max-width: ${Sizes.md}px)`,
	tablet: `only screen and (min-width: ${Sizes.lmd}px) and (max-width: ${Sizes.hd - 1}px)`,
	biggerPhone: `only screen and (max-width: ${Sizes.lg}px)`,
	desktop: `only screen and (min-width: ${Sizes.hhd}px)`,
	laptop: `only screen and (min-width: ${Sizes.hd}px) and (max-width: ${Sizes.hhd}px)`,
	fhd: `only screen and (min-width: ${Sizes.fhd}px)`,
	qhd: `only screen and (min-width: ${Sizes.qhd}px)`,
};
