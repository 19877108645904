import styled from "styled-components";
import {Colors, MediaQueries} from "../../../environment";

export const RunTime = styled.div`
  padding: 1rem 2rem;
  color: ${Colors.white};
  font-size: 1.5rem;
  border-radius: 2rem;
  border:2px solid #ff47b1;
  margin-right: 2rem;
  line-height: 1.5rem;
  width: 13rem;
  display: flex;
  justify-content: center;
  @media ${MediaQueries.xsPhone} {
    position: absolute;
    top: 6rem;
    right: 0rem;
  }
`;
export const RunTimeOver = styled.div`
  padding: 1rem 1rem;
  color: ${Colors.white};
  font-size: 1.1rem;
  border-radius: 2rem;
  border:2px solid #ff47b1;
  margin-right: 2rem;
  line-height: 1.5rem;
  width: 16rem;
  display: flex;
  justify-content: center;
  @media ${MediaQueries.xsPhone} {
    position: absolute;
    top: 6rem;
    right: 0rem;
  }
`;


export const RunTimeAdmin = styled.div`
  padding: 1rem 2rem;
  color: ${Colors.white};
  font-size: 1.5rem;
  border-radius: 2rem;
  border:2px solid #ff47b1;
  margin-right: 2rem;
  line-height: 1.5rem;
  width: 12rem;
  display: flex;
  justify-content: center;
  @media ${MediaQueries.xsPhone} {
    position: absolute;
    top: 10rem;
    right: 0rem;

  }
`;

export const RunTimeOverAdmin = styled.div`
  padding: 1rem 1rem;
  color: ${Colors.white};
  font-size: 1.1rem;
  border-radius: 2rem;
  border:2px solid #ff47b1;
  margin-right: 2rem;
  line-height: 1.5rem;
  width: 16rem;
  display: flex;
  justify-content: center;
  @media ${MediaQueries.xsPhone} {
    position: absolute;
    top: 10rem;
    right: -2rem;
  }
`;

