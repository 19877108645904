import styled from 'styled-components/macro';
import { BaseButton as DefaultBaseButton } from './BaseButton';

export const BaseButton = styled(DefaultBaseButton)`
	border-radius: 0.5rem;

	:focus,
	:hover {
		color: babyblue;
	}
`;
