import React, { useState } from 'react';

import { monthNames, toOrdinal } from 'consts';
import { addLeadingZeros, convertDate } from 'helpers';

import {
  BackgroundGradientLeft,
  Content,
  Creator,
  CreatorSubtitle,
  CreatorSubtitleContainer,
  CreatorTitle,
  DateContainer,
  DateTitle,
  EventDescription,
  EventDescriptionContainer,
  EventTitleDescription,
  EventModal,
  IconWrap,
} from './EventInfo.style';
import { Icon } from '../../../../components/icons';
import { BaseModal } from '../../../../components/widgets';

interface Props {
  image: string;
  creatorName: string;
  title: string;
  info: string;
  date: string;
}

export function EventInfo({ image, creatorName, title, info, date }: Props) {
  const [showModal, setShowModal] = useState(false);
  const { day, hours, minutes, month, year } = convertDate(date);

  return (
    <EventModal>
      <IconWrap>
        <Icon
          type={(t) => t.InfoEvent}
          size={3.2}
          color="undefined"
          onClick={() => setShowModal(true)}
        />
      </IconWrap>

      <BaseModal
        open={showModal}
        width={65}
        height={65}
        onSubmit={() => null}
        onClose={() => setShowModal(false)}
      >
        <Content>
          <Creator imgUrl={image}>
            <BackgroundGradientLeft />
            <CreatorTitle>{creatorName}</CreatorTitle>
            <CreatorSubtitleContainer>
              <CreatorSubtitle>{title}</CreatorSubtitle>
            </CreatorSubtitleContainer>
          </Creator>
          <DateContainer>
            <DateTitle>
              {toOrdinal(day)} {monthNames[month]} {year} - {addLeadingZeros(String(hours))}:
              {addLeadingZeros(String(minutes))}
            </DateTitle>
          </DateContainer>
          <EventDescriptionContainer>
            <EventTitleDescription>Event Information</EventTitleDescription>
            <EventDescription>{info.substring(3, info.length - 5)}</EventDescription>
          </EventDescriptionContainer>
        </Content>
      </BaseModal>
    </EventModal>
  );
}
