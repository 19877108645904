// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState } from 'react';
import {
  Heading
} from 'amazon-chime-sdk-component-library-react';
import {
  StyledWrapper,
  StyledAudioGroup,
  StyledVideoGroup,
  StyledLayout,
  heading,
} from './DeviceSelection.styled';
import MicrophoneDevices from './mic';
import SpeakerDevices from './speaker';
import CameraDevices from './camera';
import { PrimaryButton } from '../../index';

export interface DeviceSetupProps {
  closePreview: () => void;
  open: boolean;
  onSubmit: () => void;
  type: string;
}

type Props = DeviceSetupProps;

const DeviceSelection = ({ closePreview, type }: Props) => {
  const [, setOpenModal] = useState(true);

  const joinBiim = () => {
    closePreview()
    setOpenModal(false)
  }

  return (
    <StyledLayout>
      <Heading tag="h1" level={1} css={heading}>
        Device Setup
      </Heading>
      <StyledWrapper>
        <StyledAudioGroup>
          <MicrophoneDevices />
          <SpeakerDevices />
        </StyledAudioGroup>
        <StyledVideoGroup>
          <CameraDevices />
        </StyledVideoGroup>
      </StyledWrapper>
      {type === 'guest' ? (
        <PrimaryButton onClick={() => joinBiim()}> Join Biim </PrimaryButton>
      ) : (
        <PrimaryButton onClick={() => joinBiim()}> Start Biim </PrimaryButton>
      )}
    </StyledLayout>
  );
};

export default DeviceSelection;
