import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { DataMessage } from 'amazon-chime-sdk-js';
import { v4 as uuidv4 } from 'uuid';
import { useAppState } from 'app';

import {
  RealtimeData,
  RealtimeReactionData,
  RealtimeAttendeeNotifyData,
  MuteAllAttendeeData,
} from './RealtimeSubscriberProvider';

type Props = {
  children: ReactNode;
};

type DataMessageType = 'CHAT';

export interface RealitimeSubscribeChatStateValue {
  chatData: RealtimeData[];
  sendChatData: (mess: string) => void;
  userPreasence: Array<any | null>;
  reactionData: RealtimeReactionData[];
  sendReactionData: (mess: string) => void;
  attendeeNotifyData: RealtimeAttendeeNotifyData[];
  sendAttendeeNotifyData: (name: string, type: string, className: string) => void;
  adminChatData: RealtimeData[];
  sendAdminChatData: (mess: string) => void;
  muteAllAttendeeData: MuteAllAttendeeData;
  sendMuteAllAttendeeData: (mess: string) => void;
  tipData: RealtimeData[];
  sendTipData: (mess: string) => void;
}

const RealitimeSubscribeChatStateContext =
  React.createContext<RealitimeSubscribeChatStateValue | null>(null);

export const useRealitimeSubscribeChatState = (): RealitimeSubscribeChatStateValue => {
  const state = useContext(RealitimeSubscribeChatStateContext);
  if (!state) {
    throw new Error('Error using RealitimeSubscribe in context!');
  }
  return state;
};

export const RealitimeSubscribeChatStateProvider = ({ children }: Props) => {
  const audioVideo = useAudioVideo();
  const { localUser, setLocalUser } = useAppState();
  const [chatData, setChatData] = useState([] as RealtimeData[]);
  const [adminChatData, setAdminChatData] = useState([] as RealtimeData[]);
  const [tipData, setTipData] = useState([] as RealtimeData[]);
  const [userPreasence, setUserPreasence] = useState([] as RealtimeData[]);
  const [reactionData, setReactionData] = useState([] as RealtimeReactionData[]);
  const [attendeeNotifyData, setAttendeeNotifyData] = useState([] as RealtimeAttendeeNotifyData[]);
  const [muteAllAttendeeData, setMuteAllAttendeeData] = useState({} as MuteAllAttendeeData);

  /** LIVE CHAT *******
   * send chat data
   * @param text char text
   */
  const sendChatData = (text: string) => {
    const mess: RealtimeData = {
      uuid: uuidv4(),
      action: 'sendmessage',
      cmd: 'TEXT',
      data: text,
      createdDate: new Date().getTime(),
      senderName: localUser.name,
      senderColor: localUser.color,
    };
    if (audioVideo)
      audioVideo?.realtimeSendDataMessage('CHAT' as DataMessageType, JSON.stringify(mess));
    setChatData([...chatData, mess]);
  };

  /** LIVE CHAT
   * Receive chat data
   * @param mess
   */
  const receiveChatData = (mess: DataMessage) => {
    const data = JSON.parse(mess.text()) as RealtimeData;
    setChatData([...chatData, data]);
  };

  /** LIVE ADMIN CHAT *******
   * send chat data
   * @param text char text
   */
  const sendAdminChatData = (text: string) => {
    const mess: RealtimeData = {
      uuid: uuidv4(),
      action: 'sendmessage',
      cmd: 'TEXT',
      data: text,
      createdDate: new Date().getTime(),
      senderName: localUser.name,
      senderColor: localUser.color,
    };
    if (audioVideo)
      audioVideo?.realtimeSendDataMessage('ADMIN_CHAT' as DataMessageType, JSON.stringify(mess));
    setAdminChatData([...adminChatData, mess]);
  };

  /** LIVE ADMIN CHAT
   * Receive chat data
   * @param mess
   */
  const receiveAdminChatData = (mess: DataMessage) => {
    const data = JSON.parse(mess.text()) as RealtimeData;
    setAdminChatData([...adminChatData, data]);
  };

    /** LIVE TIP CHAT *******
   * send tip data
   * @param text char text
   */
    const sendTipData = (text: string) => {
      const mess: RealtimeData = {
        uuid: uuidv4(),
        action: 'sendmessage',
        cmd: 'TEXT',
        data: text,
        createdDate: new Date().getTime(),
        senderName: localUser.name,
        senderColor: localUser.color,
      };
      if (audioVideo)
        audioVideo?.realtimeSendDataMessage('TIP' as DataMessageType, JSON.stringify(mess));
      setTipData([...tipData, mess]);
    };
  
    /** LIVE TIP CHAT
     * Receive tip data
     * @param mess
     */
    const receiveTipData = (mess: DataMessage) => {
      const data = JSON.parse(mess.text()) as RealtimeData;
      setTipData([...tipData, data]);
    };

  const attendeePresenceSet: any = new Set();
  const callback = (presentAttendeeId: string, present: boolean) => {
    // console.log(`Attendee ID: ${presentAttendeeId} Present: ${present}`);
    if (present) {
      attendeePresenceSet.add(presentAttendeeId);
    } else {
      attendeePresenceSet.delete(presentAttendeeId);
    }

    setTimeout(() => {
      if (JSON.stringify(userPreasence) != JSON.stringify([...attendeePresenceSet]))
        setUserPreasence([...attendeePresenceSet]);
    }, 100);
  };

  /** LIVE REACTIONS *************
   * Send live reactions
   * @param text selected reaction
   */
  const sendReactionData = (text: string) => {
    const className = text.split(' ');
    const mess: RealtimeReactionData = {
      uuid: uuidv4(),
      action: 'sendmessage',
      cmd: 'TEXT',
      className1: `${text}${Math.floor(Math.random() * (2 - 1 + 1)) + 1}`,
      className2: className[0],
      createdDate: Math.floor(Date.now() / 1000),
      senderName: localUser.name,
      senderColor: localUser.color,
    };
    if (audioVideo)
      audioVideo?.realtimeSendDataMessage('REACTIONS' as DataMessageType, JSON.stringify(mess));
    setReactionData([...reactionData, mess]);
  };

  /** LIVE REACTIONS
   * Receive reactions
   * @param mess
   */
  const receiveReactionData = (mess: DataMessage) => {
    const data = JSON.parse(mess.text()) as RealtimeReactionData;
    setReactionData([...reactionData, data]);
  };

  /** NOTIFICATIONS **************************
   * Send Attendee Joined / Left Notifications
   * @param name attendeeName
   * @param type joined or left
   * @param className joined-class or left-class
   */
  const sendAttendeeNotifyData = (name: string, type: string, className: string) => {
    const notification: RealtimeAttendeeNotifyData = {
      uuid: uuidv4(),
      action: 'sendmessage',
      cmd: 'TEXT',
      state: type,
      attendeeName: name,
      createdDate: Math.floor(Date.now() / 1000),
      dynamicClass: className,
    };
    if (audioVideo)
      audioVideo?.realtimeSendDataMessage(
        'ATTENDEE' as DataMessageType,
        JSON.stringify(notification)
      );
    setAttendeeNotifyData([...attendeeNotifyData, notification]);
  };

  /** NOTIFICATIONS
   * Receive Attendee Joined /Left Notifications
   * @param notification
   */
  const receiveAttendeeData = (notification: DataMessage) => {
    const data = JSON.parse(notification.text()) as RealtimeAttendeeNotifyData;
    setAttendeeNotifyData([...attendeeNotifyData, data]);
  };

  /** Mute All users *******
   * send mute all data
   * @param text char text
   */
  const sendMuteAllAttendeeData = (text: string) => {
    const mess: MuteAllAttendeeData = {
      uuid: uuidv4(),
      action: 'sendmessage',
      cmd: 'TEXT',
      data: text,
      createdDate: new Date().getTime(),
      senderName: localUser.name,
    };
    if (audioVideo)
      audioVideo?.realtimeSendDataMessage('MUTE_ALL' as DataMessageType, JSON.stringify(mess));
    setMuteAllAttendeeData(mess);
  };

  /** Mute All users
   * Receive mute all data
   * @param mess
   */
  const receiveMuteAllData = (mess: DataMessage) => {
    const data = JSON.parse(mess.text()) as MuteAllAttendeeData;
    setMuteAllAttendeeData(data);
  };

  useEffect(() => {
    if (audioVideo) audioVideo?.realtimeSubscribeToAttendeeIdPresence(callback);
  }, [userPreasence]);

  useEffect(() => {
    if (audioVideo) {
      audioVideo.realtimeSubscribeToReceiveDataMessage(
        'REACTIONS' as DataMessageType,
        receiveReactionData
      );
      audioVideo.realtimeSubscribeToReceiveDataMessage('CHAT' as DataMessageType, receiveChatData);
      audioVideo.realtimeSubscribeToReceiveDataMessage(
        'ATTENDEE' as DataMessageType,
        receiveAttendeeData
      );
      audioVideo.realtimeSubscribeToReceiveDataMessage(
        'ADMIN_CHAT' as DataMessageType,
        receiveAdminChatData
      );
      audioVideo.realtimeSubscribeToReceiveDataMessage(
        'MUTE_ALL' as DataMessageType,
        receiveMuteAllData
      );
      audioVideo.realtimeSubscribeToReceiveDataMessage(
        'TIP' as DataMessageType,
        receiveTipData
      );
    }

    return () => {
      if (audioVideo) {
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage('REACTIONS' as DataMessageType);
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage('CHAT' as DataMessageType);
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage('ATTENDEE' as DataMessageType);
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage('ADMIN_CHAT' as DataMessageType);
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage('MUTE_ALL' as DataMessageType);
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage('TIP' as DataMessageType);
      }
    };
  });

  const providerValue = {
    chatData,
    sendChatData,
    setLocalUser,
    userPreasence,
    reactionData,
    sendReactionData,
    attendeeNotifyData,
    sendAttendeeNotifyData,
    adminChatData,
    sendAdminChatData,
    muteAllAttendeeData,
    sendMuteAllAttendeeData,
    tipData,
    sendTipData
  };
  return (
    <RealitimeSubscribeChatStateContext.Provider value={providerValue}>
      {children}
    </RealitimeSubscribeChatStateContext.Provider>
  );
};
