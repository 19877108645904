import React, { useRef, useState } from 'react';
import { Icon } from 'components/icons';
import { useMediaQuery } from '../../hooks';
import { MediaQueries } from '../../environment';

interface FullScreenProps {
  children: React.ReactNode;
}

const FullScreen = ({ children }: FullScreenProps) => {
  const fullScreenRef = useRef<HTMLDivElement>(null);

  const handleFullScreen = () => {
    if (fullScreenRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        fullScreenRef.current.requestFullscreen();
      }
    }
  };

  const isPhone = useMediaQuery(MediaQueries.phone);

  return (
    <div ref={fullScreenRef} style={{ width: '100%', height: '100%' }}>
      <span
        data-tip="Full Screen"
        style={{
          height: '2.5rem',
          position: 'absolute',
          right: isPhone ? '1rem' : '2rem',
          top: isPhone ? '1rem' : '',
          bottom: isPhone ? 'inherit' : '2rem',
          cursor: 'pointer',
        }}
      >
        {!isPhone && (
          <button onClick={handleFullScreen} style={{ cursor: 'pointer' }}>
            <Icon type={(t) => t.EnlargeIcon} size={2} color="white" />
          </button>
        )}
      </span>
      {children}
    </div>
  );
};

export default FullScreen;
