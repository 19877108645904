import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';
import { Typeahead } from 'components/inputs';

export const TitleContainer = styled.div`
  padding-bottom: 4rem;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const Title = styled.h2`
  color: ${Colors.white};
  text-align: center;
`;

export const Subtitle = styled.h3`
  color: ${Colors.white};
  text-align: center;
  margin-bottom: 4rem;

  @media ${MediaQueries.phone} {
    text-align: center;
  }
`;

export const SearchTitle = styled.h3`
  color: ${Colors.white};
  margin-bottom: 1rem;

  @media ${MediaQueries.phone} {
    text-align: center;
  }
`;

export const SearchSubtitle = styled.h6`
  color: ${Colors.white};
  margin-bottom: 2.3rem;

  @media ${MediaQueries.phone} {
    text-align: center;
  }
`;

export const SearchContent = styled.div`
  width: 100%;
  max-width: 1400px;
  background: linear-gradient(91.14deg, #4dc2e6 2.11%, #ff47b1 98.1%);
  mix-blend-mode: normal;
  border-radius: 2.2rem;
  margin: 0 auto 4rem;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  padding: 5rem 4rem 2rem;

  @media ${MediaQueries.phone} {
    padding: 4rem;
  }
`;

export const SearchBar = styled.div`
  display: flex;
  width: 100%;

  @media ${MediaQueries.phone} {
    flex-direction: column;

    * {
      text-align: center;
    }
  }
`;

export const SearchInput = styled(Typeahead)`
  width: 100%;

  @media ${MediaQueries.phone} {
    margin-bottom: 2rem;
  }
`;
