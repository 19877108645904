import { EventStatus } from 'biim-api';
import { useCallback } from 'react';

import { useDispatch } from 'hooks';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils';

import { ActionType, updateBiimStatus } from 'store/events';

export function useUpdateBiimStatus(): OperationResult<
	null,
	(uuidEvent: string, status: EventStatus) => void
> {
	const dispatch = useDispatch();
	const [{ loading, error }] = useActivity(ActionType.UPDATE_BIIM_STATUS);

	const handler = useCallback(
		(uuidEvent: string, status: EventStatus) => {
			dispatch(updateBiimStatus(uuidEvent, status));
		},
		[dispatch]
	);

	return [{ loading, error, data: null }, handler];
}
