import styled from 'styled-components/macro';
import { Layers, MediaQueries } from 'environment';

export const Aside = styled.aside`
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	max-height: 100vh;

	@media ${MediaQueries.phone} {
		width: 100%;
	}
`;

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: gray;
	z-index: ${Layers.drawer};
`;
