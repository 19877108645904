import { EventStatus } from 'biim-api';

import { useEventStatusContext } from 'hooks';

import {
  CreatorAfterEvent,
  CreatorBeforeEvent,
  CreatorLiveEvent,
  CreatorMeetEvent,
  CreatorDeviceSetup,
} from '../creator';
import { EventManagerContainer } from '../commonStyles.style';

export function CreatorEventManager() {
	const { status } = useEventStatusContext();

	function getEventForCreator() {
		switch (status) {
      case EventStatus.Before:
        return <CreatorBeforeEvent />;
      case EventStatus.DeviceSetup:
		    return <CreatorDeviceSetup />;
      case EventStatus.LiveInProgress:
        return <CreatorLiveEvent />;
      case EventStatus.MeetInProgress:
        return <CreatorMeetEvent />;
      case EventStatus.Finished:
        return <CreatorAfterEvent />;
      case EventStatus.Cancelled:
        return <CreatorAfterEvent />;
      default:
        <div />;
    }
	}

	return <EventManagerContainer>{getEventForCreator()}</EventManagerContainer>;
}
