import { EventStatus, EventType } from 'biim-api';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { CountdownToEvent } from 'components/displayers';
import { Alerts } from 'components/layout';
import { TextInput } from 'components/inputs';
import { BaseModal, PrimaryButton, SecondaryButton, RadioButton } from 'components/widgets';
import { Colors, MediaQueries } from 'environment';
import { formatDate } from 'helpers';
import { useMediaQuery, useSocketsContext, useUpdateBiim } from 'hooks';

import {
  ButtonContainer,
  Content,
  Countdown,
  DateContainer,
  ImageContainer,
  Info,
  InputContainer,
  Price,
  Stock,
  StockLeft,
  Image,
  LinkContainer,
  LinkTitle,
  LinkDescription,
  LinkContent,
  RadioContainer,
} from './EditBiimModal.style';

export interface CardDataProps {
  id: number;
  img: string;
  price: string;
  stock: string;
  stockLeft: string;
  date: string;
  status: number;
  title: string;
  eventType: number;
  creatorEmail: string;
  moderatorEmail: string;
  permalink: string;
  uuidCreator: string;
  uuidModerator: string;
  uuidGuest: string;
}

interface Props {
  onClose: () => void;
  open: boolean;
  cardData: CardDataProps;
}

const HOST_NAME = window.location.origin;

const validationSchema = yup.object().shape({
  creatorEmail: yup.string().email('It must be a valid email!').optional(),
  moderatorEmail: yup.string().email('It must be a valid email!').optional(),
});

interface FormValues {
  creatorEmail: string;
  moderatorEmail: string;
}

export function EditBiimModal({ open, onClose, cardData }: Props) {
  const [eventStatus, setEventStatus] = useState(cardData.status);
  const [eventType, setEventType] = useState(cardData.eventType);

  const { socket } = useSocketsContext();

  const { errors, values, handleBlur, setFieldValue, handleSubmit, resetForm } =
    useFormik<FormValues>({
      initialValues: { creatorEmail: '', moderatorEmail: '' },
      validationSchema,
      onSubmit: ({ creatorEmail, moderatorEmail }) => {
        updateBiim(cardData.id, { creatorEmail, moderatorEmail });
        Alerts.success('biim succesfully edit!');
        handleOnCloseModal();
      },
    });

  useEffect(() => {
    socket.on('event_updated', ({ _, status, idEvent }) => {
      if (idEvent === cardData.id) setEventStatus(status);
    });
    socket.on('event_refreshed', ({ _, status, __, idEvent }) => {
      if (idEvent === cardData.id) setEventStatus(status);
    });

    return () => {
      socket.off('event_updated', (args) => {
        console.log('socket event_updated off', { args });
      });
      socket.off('event_refreshed', (args) => {
        console.log('socket event_refreshed off', { args });
      });
    };
  }, [socket]);

  useEffect(() => {
    cardData.creatorEmail !== null && setFieldValue('creatorEmail', cardData.creatorEmail, true);
    cardData.moderatorEmail !== null &&
      setFieldValue('moderatorEmail', cardData.moderatorEmail, true);
    setEventStatus(cardData.status);
  }, [cardData.id]);

  useEffect(() => {
    setEventType(cardData.eventType);
  }, [cardData.eventType]);

  const isPhone = useMediaQuery(MediaQueries.phone);
  const [, updateBiim] = useUpdateBiim();

  function handleOnCloseModal() {
    resetForm();
    onClose();
  }

  async function handleCancelBiim() {
    updateBiim(cardData.id, { status: EventStatus.Cancelled });
    setEventStatus(EventStatus.Cancelled);
    Alerts.info(`biim ${cardData.title} was cancelled!`);
  }

  async function handleResetBiim() {
    updateBiim(cardData.id, { status: EventStatus.Before });
    setEventStatus(EventStatus.Before);
    Alerts.info(`biim ${cardData.title} has been reset!`);
  }

  function handleSelectEventType(eventType: EventType) {
    setEventType(eventType);
    updateBiim(cardData.id, { eventType });
    Alerts.info(
      `biim event type has change into ${eventType === EventType.Live
        ? 'Live'
        : eventType === EventType.Meet
          ? 'Meet & greet'
          : 'Live with meet & greet'
      }!`
    );
  }

  const { DO_MMMM_YYYY_24H, EUROPE_LONDON_DATE } = formatDate(cardData.date)

  function getStatus() {
    switch (eventStatus) {
      case 1:
        return 'Not started';
      case 2:
        return 'Cancelled';
      case 3:
        return 'In progress';
      case 4:
        return 'Finished';
      default:
        return 'Unknown';
    }
  }

  async function copyLink(text: string, e: any) {
    Alerts.info('Link copied!');
    e.stopPropagation();
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  return (
    <BaseModal
      open={open}
      closeable={true}
      onSubmit={handleSubmit}
      onClose={handleOnCloseModal}
      title={`Edit "${cardData.title}"`}
    >
      <Content>
        <ImageContainer>
          <Countdown>
            <CountdownToEvent
              small
              date={EUROPE_LONDON_DATE}
              cancelled={cardData.status === EventStatus.Cancelled}
            />
          </Countdown>
          <Image src={cardData.img} />
          <DateContainer>
            <span>
              { DO_MMMM_YYYY_24H }
            </span>
          </DateContainer>
          <Info>
            <Price>£{parseFloat(`${cardData.price}`).toFixed(2)}</Price>
            <Stock>
              <Price style={{ marginLeft: 15 }}>{cardData.stockLeft}</Price>
              <StockLeft>/{cardData.stock}</StockLeft>
            </Stock>
          </Info>
        </ImageContainer>
        <InputContainer>
          <TextInput
            name="creator"
            placeholder="Edit Creator Email"
            type="text"
            width={!isPhone ? 45.3 : 'auto'}
            boxShadow="0px 0px 3px rgba(255, 255, 255, 0.12), inset 0px 4px 4px rgba(0, 0, 0, 0.25)"
            backgroundColor={Colors.darkest}
            error={errors.creatorEmail}
            value={values.creatorEmail}
            onChange={(e) => setFieldValue('creatorEmail', e.target.value)}
            onBlur={handleBlur}
            margin={{ bottom: 1.7 }}
          />
          <TextInput
            name="moderator"
            placeholder="Edit Moderator Email"
            type="text"
            width={!isPhone ? 45.3 : 'auto'}
            boxShadow="0px 0px 3px rgba(255, 255, 255, 0.12), inset 0px 4px 4px rgba(0, 0, 0, 0.25)"
            backgroundColor={Colors.darkest}
            error={errors.moderatorEmail}
            value={values.moderatorEmail}
            onBlur={handleBlur}
            onChange={(e) => setFieldValue('moderatorEmail', e.target.value)}
          />
          <LinkContainer>
            <LinkContent>
              <LinkTitle>Store: </LinkTitle>
              <LinkDescription onClick={(e) => copyLink(cardData.permalink, e)}>
                {cardData.permalink}
              </LinkDescription>
            </LinkContent>
            <LinkContent>
              <LinkTitle>Creator: </LinkTitle>
              <LinkDescription
                onClick={(e) => copyLink(`${HOST_NAME}/${cardData.uuidCreator}`, e)}
              >{`${HOST_NAME}/${cardData.uuidCreator}`}</LinkDescription>
            </LinkContent>
            <LinkContent>
              <LinkTitle>Moderator: </LinkTitle>
              <LinkDescription
                onClick={(e) => {
                  copyLink(`${HOST_NAME}/${cardData.uuidModerator}`, e);
                }}
              >{`${HOST_NAME}/${cardData.uuidModerator}`}</LinkDescription>
            </LinkContent>
            <LinkContent>
              <LinkTitle>Guest: </LinkTitle>
              <LinkDescription
                onClick={(e) => copyLink(`${HOST_NAME}/${cardData.uuidGuest}`, e)}
              >{`${HOST_NAME}/${cardData.uuidGuest}`}</LinkDescription>
            </LinkContent>
            <LinkContent>
              <LinkTitle>Status: </LinkTitle>
              <p
                style={{
                  color: Colors.white,
                  fontSize: '1rem',
                  letterSpacing: '1.326px',
                }}
              >
                {getStatus()}
              </p>
            </LinkContent>
            <LinkContent>
              <LinkTitle>Event type: </LinkTitle>
              <RadioContainer>
                <RadioButton
                  selected={eventType === EventType.Live}
                  title="Live"
                  onSelect={() => handleSelectEventType(EventType.Live)}
                />
                <RadioButton
                  selected={eventType === EventType.Meet}
                  title="Meet & greet"
                  onSelect={() => handleSelectEventType(EventType.Meet)}
                />
                <RadioButton
                  selected={eventType === EventType.LiveMeet}
                  title="Live with meet & greet"
                  onSelect={() => handleSelectEventType(EventType.LiveMeet)}
                />
              </RadioContainer>
            </LinkContent>
          </LinkContainer>
        </InputContainer>
      </Content>
      <ButtonContainer>
        <SecondaryButton
          fontSize={1.8}
          fontWeight="normal"
          width={29}
          disabled={cardData.status === EventStatus.Cancelled}
          onClick={handleCancelBiim}
        >
          Cancel biim
        </SecondaryButton>
        <SecondaryButton
          fontSize={1.8}
          fontWeight="normal"
          width={29}
          margin={{ bottom: isPhone ? 3 : 0 }}
          disabled={cardData.status === EventStatus.Before}
          onClick={handleResetBiim}
        >
          Reset biim
        </SecondaryButton>
        <PrimaryButton
          fontSize={1.8}
          fontWeight="normal"
          width={29}
          margin={{ bottom: isPhone ? 3 : 0 }}
          disabled={!!errors.creatorEmail || !!errors.moderatorEmail}
          onClick={handleSubmit}
        >
          Save changes
        </PrimaryButton>
      </ButtonContainer>
    </BaseModal>
  );
}
