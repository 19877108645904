import React from 'react';
import styled from 'styled-components';
import {Colors} from 'environment';

const SpinnerCanvas = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  z-index: -1;
`;


export default function WaitingAnimation() {
    return (
        <SpinnerCanvas>
            <svg
                viewBox="0 0 600 600"
                width="100vw"
                height="calc(100vh)"
                preserveAspectRatio="xMidYMid slice"
            >
                <defs>
                    <linearGradient id="Gradient1">
                        <stop stopColor={Colors.lightBlue} offset="0%" />
                        <stop stopColor={Colors.blue} offset="50%" />
                        <stop stopColor={Colors.purpleblue} offset="100%" />
                    </linearGradient>
                    <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
                        <stop offset="0%" stopColor={Colors.pink} />
                        <stop offset="80%" stopColor={Colors.purplepink} />
                        <stop offset="100%" stopColor={Colors.purplepink} />
                    </linearGradient>
                    <linearGradient id="Gradient3">
                        <stop stopColor={Colors.bluegreen} offset="0%" />
                        <stop stopColor={Colors.purplepink} offset="50%" />
                        <stop stopColor={Colors.purpleblue} offset="100%" />
                    </linearGradient>
                    <linearGradient id="Gradient4">
                        <stop stopColor={Colors.lightBlue} offset="0%" />
                        <stop stopColor={Colors.purplepink} offset="50%" />
                        <stop stopColor={Colors.pink} offset="100%" />
                    </linearGradient>
                </defs>
                <g>
                    <path
                        // eslint-disable-next-line max-len
                        d="M0 300A1 1 0 01600 300L530 330 530 300A1 1 0 0030 300C30 310 29.953 310.286 32 330Z"
                        fill="url(#Gradient1)"
                    />
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        from="0 300 300"
                        to="360 300 300"
                        dur="41s"
                        repeatCount="indefinite"
                    />
                </g>
                <g>
                    <path
                        d="M300 100A1 1 0 01300 500L270 580A1 1 0 00280 60Z"
                        fill="url(#Gradient2)"
                    />
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        from="360 300 300"
                        to="0 300 300"
                        dur="11s"
                        repeatCount="indefinite"
                    />
                </g>
                <g>
                    <path
                        d="M500 300A1 1 0 01100 300L89 320A1 1 0 00520 260Z"
                        fill="url(#Gradient3)"
                    />
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        from="0 300 300"
                        to="360 300 300"
                        dur="19s"
                        repeatCount="indefinite"
                    />
                </g>
                <g>
                    <path
                        d="M0 300A1 1 0 00600 300L570 270A1 1 0 013 300Z"
                        fill="url(#Gradient4)"
                    />
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        from="360 300 300"
                        to="0 300 300"
                        dur="5s"
                        repeatCount="indefinite"
                    />
                </g>
                <circle cx="300" cy="300" r="160" fill={Colors.darker} />
            </svg>
        </SpinnerCanvas>
    );
}