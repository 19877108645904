import { useRealitimeSubscribeChatState } from '../chat/RealtimeSubscribeChatProvider';
import { Icons } from '../../components/widgets/Reactions/components/icons';
import './ReactionCounter.style.scss';



export const ReactionsCounter = () => {
  const { reactionData } = useRealitimeSubscribeChatState(); // <---- (1)

  const hahaCount = reactionData.filter((reaction) => reaction.className2 === 'haha').length;
  const loveCount = reactionData.filter((reaction) => reaction.className2 === 'heart').length;
  const likeCount = reactionData.filter((reaction) => reaction.className2 === 'satisfaction').length;
  const sadCount = reactionData.filter((reaction) => reaction.className2 === 'sad').length;
  const clapCount = reactionData.filter((reaction) => reaction.className2 === 'clap').length;
  const wowCount = reactionData.filter((reaction) => reaction.className2 === 'wow').length;

  return (
    <>
        <div className="reaction-counter">
          <div className="reaction">
            <div className="emoji"><img src={Icons.likeIcon} /></div>
            <div className="count">{likeCount}</div>
          </div>
          <div className="reaction">
            <div className="emoji"><img src={Icons.loveIcon} /></div>
            <div className="count">{loveCount}</div>
          </div>
          <div className="reaction">
            <div className="emoji"><img src={Icons.hahaIcon} /></div>
            <div className="count">{hahaCount}</div>
          </div>
          <div className="reaction">
            <div className="emoji"><img src={Icons.wowIcon} /></div>
            <div className="count">{wowCount}</div>
          </div>
          <div className="reaction">
            <div className="emoji"><img src={Icons.sadIcon} /></div>
            <div className="count">{sadCount}</div>
          </div>
          <div className="reaction">
            <div className="emoji"><img src={Icons.clapIcon} /></div>
            <div className="count">{clapCount}</div>
          </div>
        </div>
    </>
  );
};
