import React, { useRef } from 'react';

import { PermissionPrompt } from './DevicePermissionPrompt.style';
import { BaseModal } from 'components/widgets';

import { Card } from 'components/displayers';
import Bowser from 'bowser';
import { DialogType } from 'consts';
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from 'mic-check';
const browser = Bowser.getParser(window.navigator.userAgent);
export interface DeviceSetupProps {
  closePreview: () => void;
  open: boolean;
  onSubmit: () => void;
  inlineContentStyles?: any
}

type Props = DeviceSetupProps;

const PermissionPromptWindow = ({ onSubmit, inlineContentStyles }: Props) => {
  const [showDialog, setShowDialog] = React.useState<DialogType | null>(null);

  const [audioAllowed, setAudioAllowed] = React.useState<boolean>(false);
  const [videoAllowed, setVideoAllowed] = React.useState<boolean>(false);

  const [errorDetails, setErrorDetails] = React.useState<MediaPermissionsError | undefined>();

  // Create wrapper refs to access values even during setTimeout
  // https://github.com/facebook/react/issues/14010
  const showDialogRef = useRef(showDialog);
  showDialogRef.current = showDialog;
  const audioAllowedRef = useRef(audioAllowed);
  audioAllowedRef.current = audioAllowed;
  const videoAllowedRef = useRef(videoAllowed);
  videoAllowedRef.current = videoAllowed;

  React.useEffect(() => {
    checkMediaPermissions();
  }, []);

  React.useEffect(() => {
    console.log('audio allowed permission changed: ', audioAllowed);
    if (audioAllowed || videoAllowed) {
      // set the default devices
      // MediaManager.findMediaDevices();
    }
  }, [audioAllowed, videoAllowed]);

  const checkForExplanationDialog = () => {
    if ((!audioAllowedRef.current || !videoAllowedRef.current) && showDialogRef.current === null)
      setShowDialog(DialogType.explanation);
  };

  const checkMediaPermissions = () => {
    requestMediaPermissions()
      .then(() => {
        setAudioAllowed(true);
        setVideoAllowed(true);
        setShowDialog(null);
      })
      .catch((error: MediaPermissionsError) => {
        if (error.type === MediaPermissionsErrorType.SystemPermissionDenied) {
          // user denied permission
          setShowDialog(DialogType.systemDenied);
        } else if (error.type === MediaPermissionsErrorType.UserPermissionDenied) {
          // browser doesn't have access to devices
          setShowDialog(DialogType.userDenied);
        } else if (error.type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
          // most likely when other apps or tabs are using the cam/mic (mostly windows)
          setShowDialog(DialogType.trackError);
        } else {
          console.log(error);
        }
        setErrorDetails(error);
      });

    setTimeout(() => {
      checkForExplanationDialog();
    }, 500);
  };

  const _renderErrorMessage = () => {
    if (!errorDetails) return null;
    return (
      <>
        <p style={{ color: 'red' }}>Error Details</p>
        <p>
          {errorDetails.name}: {errorDetails.message}
        </p>
      </>
    );
  };

  const _renderTrackErrorDialog = () => {
    return (
      <Card
        title="Allow App to use your camera and microphone"
        description={
          <>
            <p>
              App needs access to your camera and microphone so that other participants can see and
              hear you.
            </p>
            {_renderErrorMessage()}
          </>
        }
      />
    );
  };

  const _renderUserDeniedDialog = () => {
    return (
      <Card
        title="Camera and microphone are blocked"
        description={
          <>
            <p>
              App requires access to your camera and microphone.{' '}
              {browser.getBrowserName() !== 'Safari' && (
                <p>
                  Click the camera blocked icon{' '}
                  <img
                    alt="icon"
                    src={
                      'https://www.gstatic.com/meet/ic_blocked_camera_dark_f401bc8ec538ede48315b75286c1511b.svg'
                    }
                    style={{ display: 'inline' }}
                  />{' '}
                  in your browsers address bar.
                </p>
              )}
            </p>
            {_renderErrorMessage()}
          </>
        }
      />
    );
  };

  const _renderSystemDeniedDialog = () => {
    const settingsDataByOS = {
      macOS: {
        name: 'System Preferences',
        link: 'x-apple.systempreferences:com.apple.preference.security?Privacy_Camera',
      },
    };

    return (
      <Card
        title="Cant use your camera or microphone"
        description={
          <>
            <p>
              Your browser might not have access to your camera or microphone. To fix this problem,
              click
              {
                // @ts-ignore
                settingsDataByOS[browser.getOSName()] ? (
                  <a
                    onClick={() => {
                      window.open(
                        // @ts-ignore
                        settingsDataByOS[browser.getOSName()].link,
                        '_blank'
                      );
                    }}
                  >
                    {
                      // @ts-ignore
                      settingsDataByOS[browser.getOSName()].name
                    }
                  </a>
                ) : (
                  'Settings'
                )
              }
            </p>
            {_renderErrorMessage()}
          </>
        }
      />
    );
  };

  const _renderExplanationDialog = () => {
    return (
      <Card
        title="Cant start your camera or microphone"
        description={
          <>
            <p>
              Another application (Zoom, Webex) or browser tab (Google Meet, Messenger Video) might
              already be using your webcam. Please turn off other cameras before proceeding.
            </p>
            {_renderErrorMessage()}
          </>
        }
      />
    );
  };

  const _renderDialogContent = () => {
    switch (showDialog) {
      case DialogType.explanation:
        return _renderExplanationDialog();
      case DialogType.systemDenied:
        return _renderSystemDeniedDialog();
      case DialogType.userDenied:
        return _renderUserDeniedDialog();
      case DialogType.trackError:
        return _renderTrackErrorDialog();
    }
  };

  return (
    <BaseModal
      inlineContentStyles={inlineContentStyles}
      open={true}
      onSubmit={onSubmit}
      onClose={onSubmit}
      title={`Device Permissions`}
    >
      <PermissionPrompt>{_renderDialogContent()}</PermissionPrompt>
    </BaseModal>
  );
};

export default PermissionPromptWindow;
