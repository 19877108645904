import React, { useEffect, useState, useRef } from 'react';
import useSound from 'use-sound';
import { BaseModal, PrimaryButton } from 'components/widgets';
import { QueueCreatorTitle, BgMusicLabel } from 'features/event/guest/Guest.styles';
import { Icon } from '../../icons';

interface Props {
  musicStart: boolean;
  spotifyOpen: boolean;
  youtubeOpen: boolean;
  handler: any;
  url: string;
}

export default function BackGroundMusic({
  musicStart,
  spotifyOpen,
  youtubeOpen,
  handler,
  url,
}: Props) {
  // const [play, { stop, sound, pause }] = useSound(url, {
  //   // `interrupt` ensures that if the sound starts again before it's
  //   // ended, it will truncate it. Otherwise, the sound can overlap.
  //   interrupt: false,
  // });
  const [audio, setAudio] = useState(new Audio(url));
  const [showModal, setShowModal] = useState(true);
  const [playStatus, setPlayStatus] = useState(musicStart);

  const start = () => {
    if (!spotifyOpen && !youtubeOpen) {
      audio.play();
      setShowModal(false);
      setPlayStatus(true);
      handler(true);
    }
  };

  const pauseMusic = () => {
    audio.pause();
    setPlayStatus(false);
    handler(false);
  };

  useEffect(() => {
    if (musicStart === true) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [musicStart]);

  useEffect(() => {
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  return (
    <>
      {showModal && (
        <BaseModal
          open={showModal}
          onSubmit={() => null}
          onClose={() => start()}
          title={`Welcome to Biim`}
        >
          <QueueCreatorTitle>
            Hola! We’re so excited you are a part of BIIM. <br /> We love all our customers, and
            that includes you too! <br />
            <br />
            <br />
            *Close all other conferencing tools before using BIIM
            <br />
            <br />
            <br />
          </QueueCreatorTitle>

          <PrimaryButton onClick={() => start()} fontSize={1.8} fontWeight="normal">
            Close
          </PrimaryButton>
        </BaseModal>
      )}
      {!showModal && (
        <BgMusicLabel>
          {!playStatus || !musicStart ? (
            <>
              <Icon type={(k) => k.SpeakerMute} size={4} onClick={() => start()} style={{}} />
              <p>Background Music Off</p>
            </>
          ) : (
            <>
              <Icon type={(k) => k.Speaker} size={4} onClick={() => pauseMusic()} style={{}} />
              <p>Background Music On</p>
            </>
          )}
        </BgMusicLabel>
      )}
    </>
  );
}
