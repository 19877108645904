import { Credentials } from 'biim-api';
import { ActionCreator } from 'redux';
import { v4 as uuidv4 } from 'uuid';

import { Alerts } from 'components/layout';
import { Storage } from 'helpers';
import { Thunk } from 'store/types';
import { beginActivity, endActivity, setError } from 'store/ui/activities';

import { ActionType, AuthSession, LoginAction, LogoutAction, RefreshTokenAction } from './types';

const loginAction: ActionCreator<LoginAction> = (session: AuthSession) => ({
	type: ActionType.LOGIN,
	payload: session,
});

export const login = (creds: Credentials): Thunk => async (dispatch, _, context) => {
	const activityId = uuidv4();

	try {
		dispatch(beginActivity({ type: ActionType.LOGIN, uuid: activityId }));
		const { accessToken, refreshToken } = await context.api.auth().login(creds);
		Storage.set(k => k.AccessToken, accessToken);
		Storage.set(k => k.RefreshToken, refreshToken);
		Storage.set(k => k.Username, creds.username);
		dispatch(
			loginAction({ token: accessToken, refresh: refreshToken, username: creds.username })
		);
	} catch (e) {
		// @ts-ignore
		Alerts.error('Wrong credentials! Please try again later!');
		dispatch(
			setError({
				type: ActionType.LOGIN,
				// @ts-ignore
				message: e.message,
				uuid: uuidv4(),
			})
		);
	} finally {
		dispatch(endActivity({ uuid: activityId }));
	}
};

const logoutAction: ActionCreator<LogoutAction> = () => ({
	type: ActionType.LOGOUT,
});

export const logout = (): Thunk => async dispatch => {
	try {
		Storage.remove(k => [k.AccessToken, k.Username, k.RefreshToken, k.Status, k.Attendee]);
		dispatch(logoutAction());
	} catch (e) {
		dispatch(
			setError({
				type: ActionType.LOGOUT,
				// @ts-ignore
				message: e.message,
				uuid: uuidv4(),
			})
		);
	}
};

const refreshTokenAction: ActionCreator<RefreshTokenAction> = (access: string) => ({
	type: ActionType.REFRESH_TOKEN,
	payload: {
		access,
	},
});

export const refreshToken = (access: string): Thunk => async (dispatch, getState) => {
	try {
		const { session } = getState().auth;

		if (session) {
			Storage.set(k => k.AccessToken, access);
			dispatch(refreshTokenAction(access));
		}
	} catch (e) {
		dispatch(
			setError({
				type: ActionType.REFRESH_TOKEN,
				// @ts-ignore
				message: e.message,
				uuid: uuidv4(),
			})
		);
	}
};
