import { EventStatus, EventType } from 'biim-api';
import { useEffect, useState } from 'react';

import { EventStatusContext, useEventContext, useSocketsContext, useUpdateBiimStatus } from 'hooks';

import { ModeratorEventManager } from './ModeratorEventManager';
import { CreatorEventManager } from './CreatorEventManager';
import { GuestEventManager } from './GuestEventManager';
import {isMobile} from "react-device-detect";

export function EventManager() {
  const [status, setStatus] = useState(EventStatus.Before);

  const { socket } = useSocketsContext();
  const { eventData: data } = useEventContext();

  const [, updateBiimStatus] = useUpdateBiimStatus();

  useEffect(() => {
    if (data.status === EventStatus.Finished) setStatus(EventStatus.Finished);
    if (data.status === EventStatus.Cancelled) setStatus(EventStatus.Cancelled);
  }, [data]);

  useEffect(() => {
    socket.on('event_updated', ({ uuidEvent, status }) => {
      console.log('EventManager socket triggered', status, { data }, uuidEvent);
      if (status === EventStatus.Before && data.uuidEvent === uuidEvent) {
        setStatus(EventStatus.Before);
        updateBiimStatus(uuidEvent, EventStatus.Before);
      }
      if (status === EventStatus.Finished && data.uuidEvent === uuidEvent) {
        setStatus(EventStatus.Finished);
        updateBiimStatus(uuidEvent, EventStatus.Finished);
      }
      if (status === EventStatus.Cancelled && data.uuidEvent === uuidEvent) {
        setStatus(EventStatus.Cancelled);
        updateBiimStatus(uuidEvent, EventStatus.Cancelled);
      }
      if (status === EventStatus.LiveInProgress && data.uuidEvent === uuidEvent) {
        updateBiimStatus(uuidEvent, EventStatus.LiveInProgress);
        if (data.eventType === EventType.LiveMeet) setStatus(EventStatus.LiveInProgress);
      }
      if (status === EventStatus.MeetInProgress && data.uuidEvent === uuidEvent) {
        updateBiimStatus(uuidEvent, EventStatus.MeetInProgress);
        if (data.eventType === EventType.LiveMeet) setStatus(EventStatus.MeetInProgress);
      }
    });

    socket.on('event_refreshed', ({ uuidEvent, status, _ }) => {
      if (status !== EventStatus.Before && uuidEvent === data.uuidEvent) {
        setStatus(EventStatus.Before);
      }
    });

    return () => {
      socket.off('event_updated', (args) => {
        console.log('socket event_updated off', { args });
      });
      socket.off('event_refreshed', (args) => {
        console.log('socket event_refreshed off', { args });
      });
    };
  }, [socket, data]);

  function returnPage() {
    if (data.userType) {
      switch (data.userType) {
        case 'creator':
          return <CreatorEventManager />;
        case 'moderator':
          return <ModeratorEventManager />;
        case 'guest':
          return <GuestEventManager />;
        default:
          <div />;
      }
    } else {
      return <div />;
    }
  }

  return (
    <EventStatusContext.Provider value={{ status, setStatus }}>

      {isMobile ? (
          <div style={{ width: '100%', flex: 1, position: 'relative', overflowX: 'hidden' }}>
            {returnPage()}
          </div>
          ):(
          <div style={{ width: '100%', flex: 1, position: 'relative', overflowX: 'hidden' }}>
            {returnPage()}
          </div>
      )}
    </EventStatusContext.Provider>
  );
}
