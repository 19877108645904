import React, { useEffect, useState } from 'react';
import { Reaction } from '../helpers';
import ReactionBarSelectorEmoji from './ReactionBarSelectorEmoji';
import { Icons } from './icons';

export interface ReactionBarSelectorProps {
  iconSize?: number;
  reactions?: Reaction[];
  onSelect?: (key: string) => void;
  style?: React.CSSProperties;
}

export const ReactionBarSelector = React.forwardRef<HTMLDivElement, ReactionBarSelectorProps>(
  (
    {
      iconSize = defaultProps.iconSize,
      reactions = defaultProps.reactions,
      onSelect = defaultProps.onSelect,
      style = defaultProps.style,
    },
    ref
  ) => {
    const emojiStyle = React.useMemo(() => {
      return {
        width: `${iconSize + 10}px`,
        height: `${iconSize + 10}px`,
        display: 'flex',
        alignItems: 'center',
        fontSize: iconSize,
      };
    }, [iconSize]);
    const [disableReactionBar, setDisableReactionBar] = useState(false);
    const handleClick = () => {
      setDisableReactionBar(true);
    };

    useEffect(() => {
      if (disableReactionBar) {
        const timer = setTimeout(() => {
          setDisableReactionBar(false);
        }, 3000);
        return () => clearTimeout(timer);
      }
    }, [disableReactionBar]);

    return (
      <>
        <div
          ref={ref}
          style={{
            ...wrapStyle,
            ...style,
            pointerEvents: disableReactionBar ? 'none' : 'auto',
            opacity: disableReactionBar ? '0.5' : '1',
          }}
        >
          {reactions.map((reaction: Reaction) => {
            return (
              <div style={emojiStyle} key={reaction.key ?? reaction.label}>
                <ReactionBarSelectorEmoji
                  reaction={reaction}
                  onSelect={onSelect}
                  onSelectDisable={() => handleClick()}
                />
              </div>
            );
          })}
        </div>
      </>
    );
  }
);

export const defaultProps: Required<ReactionBarSelectorProps> = {
  style: { backgroundColor: '#ffffff00', width: '100%', justifyContent: 'center' },
  reactions: [
    {
      label: 'Like',
      node: <img src={Icons.likeIcon} className="reaction-bar-icon" />,
      key: 'satisfaction ani',
    },
    {
      label: 'Love',
      node: <img src={Icons.loveIcon} className="reaction-bar-icon" />,
      key: 'heart lani',
    },
    {
      label: 'Haha',
      node: <img src={Icons.hahaIcon} className="reaction-bar-icon" />,
      key: 'haha hani',
    },
    {
      label: 'Wow',
      node: <img src={Icons.wowIcon} className="reaction-bar-icon" />,
      key: 'wow wani',
    },
    {
      label: 'Sad',
      node: <img src={Icons.sadIcon} className="reaction-bar-icon" />,
      key: 'sad ani',
    },
    {
      label: 'Clap',
      node: <img src={Icons.clapIcon} className="reaction-bar-icon" />,
      key: 'clap cani',
    },
  ],
  iconSize: 26,
  onSelect: (key: string) => {
    console.log(key);
  },
};

const wrapStyle = {
  backgroundColor: '#ffffff00',
  borderRadius: '50px',
  padding: '2px',
  boxShadow: '0 0 0 1px rgba(0, 0, 0, .05), 0 1px 2px rgba(0, 0, 0, .15)',
  display: 'flex',
  width: '100%',
};

export default ReactionBarSelector;
