import { EventStatus, EventType, factory } from 'biim-api';
import {
  AudioInputControl,
  AudioOutputControl,
  MeetingStatus,
  Phone,
  useRosterState,
  useMeetingManager,
  useMeetingStatus,
  VideoInputControl,
  DeviceLabelTriggerStatus,
} from 'amazon-chime-sdk-component-library-react';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { MediaQueries } from 'environment';
import { ChatView, RealitimeSubscribeStateProvider, Reactions, Notify } from 'features/chat';
import {
  useEffectOnce,
  useEventContext,
  useEventStatusContext,
  useModal,
  useNavbarColorContext,
  useNavigation,
  useUpdateLiveBiimAttendeeId,
  useNavbarUIContext,
} from 'hooks';
import { endMeeting } from 'utils/api';
import Switch from 'react-switch';
import {
  BarControlDesktop,
  ControlBarButtonCustomModerator,
  PermissionContainer,
} from '../creator/Creator.styles';
import { MeetVideo, MeetVideoWrap, PermissionWrapperMeet } from '../guest/Guest.styles';
import { ConfirmationModal } from '../modal';
import NoPermissionMarquee from '../../../components/widgets/Device/NoPermission';
import { WINDOW_METHODS } from 'consts';
import { LeaveModal } from '../leave';
import { Icons } from '../creator/icons';

export function ModeratorLiveEvent() {
  const [hideChat, setHideChat] = useState(false);

  const [{ open: showLeaveModal, onClose: onCloseLeaveModal }, toggleLeaveModal] = useModal();
  const [{ open: showEndModal, onClose: onCloseEndModal }, toggleEndModal] = useModal();
  const [{ open: showSwitchModal, onClose: onCloseSwitchModal }, toggleSwitchModal] = useModal();

  const { eventData: data } = useEventContext();
  const [moderatorName] = useState(data.userName !== undefined ? data.userName : data.userType);
  const { setRuntime, showLiveDot, setAttendeeCount, setIsEnabled, setUserType } =
    useNavbarUIContext();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  setAttendeeCount(attendees.length);
  const meetingManager = useMeetingManager();
  const eventstatus = useMeetingStatus();
  const { status, setStatus } = useEventStatusContext();

  const { navigate, routes } = useNavigation();
  const { pathname } = useLocation();
  const api = factory();
  const { type, switchColor } = useNavbarColorContext();
  const [{ data: attendeeId }] = useUpdateLiveBiimAttendeeId();
  const [mediaPermissions, setMediaPermissions] = useState(DeviceLabelTriggerStatus.UNTRIGGERED);

  useEffectOnce(() => {
    if (type === 'colored') switchColor('dark');
    setRuntime(data.runningTime, data.availabilityDate);
    setUserType(data.userType);
    setIsEnabled(true);
    showLiveDot(true);
    checkMedia();
    if (data.eventType !== EventType.LiveMeet && status !== EventStatus.MeetInProgress)
      return () => {
        meetingManager.leave();
      };
  });

  const checkMedia = async () => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(
      () => {
        // microphone available
        setMediaPermissions(DeviceLabelTriggerStatus.GRANTED);
      },
      () => {
        // microphone not available
        setMediaPermissions(DeviceLabelTriggerStatus.DENIED);
      }
    );
  };

  useEffect(() => {
    if (!meetingManager.audioVideo) return;

    meetingManager.audioVideo.realtimeMuteLocalAudio();
  }, [meetingManager.audioVideo]);

  useEffect(() => {
    if (data.eventType !== EventType.LiveMeet && status !== EventStatus.MeetInProgress)
      if (eventstatus === MeetingStatus.Left) {
        try {
          unexpectedEnd();
        } catch (e) {
          console.error(e);
        }
      }
  }, [eventstatus]);

  const uuid = pathname.split('/')[1];

  async function unexpectedEnd() {
    await api.event().updateStatusEvent(uuid, EventStatus.Finished);
    meetingManager.leave();
  }

  function leaveMeeting() {
    navigate(routes.root);
    meetingManager.leave();
  }

  const clickedEndMeeting = async () => {
    const meetingId = meetingManager.meetingId;
    if (meetingId) {
      await endMeeting(meetingId);
      await meetingManager.leave();
      await api.event().updateStatusEvent(uuid, EventStatus.Finished);
    }
  };

  const clickedSwitchMeeting = async () => {
    await api.event().updateStatusEvent(uuid, EventStatus.MeetInProgress);
  };

  const hangUpButtonProps = {
    icon: <Phone css="cursor: not-allowed; " />,
    css: `.ch-popover-menu {
			background-color: black;
		}`,
    popOver: [
      {
        onClick: () => toggleLeaveModal(),
        children: <span>Leave biim</span>,
      },
      {
        onClick: () => toggleEndModal(),
        children: <span>End biim</span>,
      },
    ],
    onClick: () => console.log('nothing'),
    label: 'End',
  };

  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to exit?';
      return;
    };

    window.addEventListener(WINDOW_METHODS.BEFORE_UNLOAD, handleTabClose);

    return () => {
      window.removeEventListener(WINDOW_METHODS.BEFORE_UNLOAD, handleTabClose);
      window.location.reload();
    };
  }, []);

  return (
    <>
      {mediaPermissions === DeviceLabelTriggerStatus.DENIED && <NoPermissionMarquee />}
      <MeetVideoWrap style={{}} id={data.userType}>
        <ConfirmationModal
          title="Once you leave, you can not join again. Are you sure you wish to end??"
          open={showEndModal}
          onClose={onCloseEndModal}
          onSubmit={clickedEndMeeting}
        />
        <ConfirmationModal
          title="Are you sure you wish to switch to meet?"
          open={showSwitchModal}
          onClose={onCloseSwitchModal}
          onSubmit={clickedSwitchMeeting}
        />

        <RealitimeSubscribeStateProvider>
          <LeaveModal
            name={moderatorName}
            open={showLeaveModal}
            onClose={onCloseLeaveModal}
            onSubmit={leaveMeeting}
          />
          <ChatView
            attendeeId={attendeeId.attendeeId}
            attendeeName={moderatorName}
            userType={data.userType}
            eventType={data.eventType}
            visible={hideChat}
            onChangeVisibility={setHideChat}
            onChangeStatus={setStatus}
          />
          <Notify name={moderatorName} meetingStatus={eventstatus} />
          <MeetVideo chatVisibility={hideChat} isMobile={isMobile} />
          <BarControlDesktop
            chatVisibility={hideChat}
            layout="undocked-vertical"
            showLabels={false}
          >
            <Reactions />
            <ControlBarWrap>
              {data.eventType === EventType.LiveMeet && (
                <span data-tip="Switch to Meet & Greet">
                  <Switch
                    offColor="#f84bb2"
                    onColor="#0ff"
                    height={35}
                    width={70}
                    handleDiameter={30}
                    activeBoxShadow="0px 0px 1px 2px #fffc35"
                    onChange={toggleSwitchModal}
                    checked={false}
                    uncheckedIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          fontSize: 15,
                          paddingRight: 2,
                        }}
                      >
                        <img src={Icons.MeetIcon} height={'28px'} />
                      </div>
                    }
                  />
                </span>
              )}

              {mediaPermissions === DeviceLabelTriggerStatus.DENIED ? (
                <PermissionContainer>
                  <PermissionWrapperMeet>
                    <AudioInput />
                    <VideoControl />
                  </PermissionWrapperMeet>
                  <AudioOutput />
                </PermissionContainer>
              ) : (
                <>
                  <AudioInput />
                  <VideoControl />
                  <AudioOutput />
                </>
              )}

              <ControlBarButtonCustomModerator {...hangUpButtonProps} />
              <ReactTooltip place="top" type="dark" effect="solid" className="toolTip" />
            </ControlBarWrap>
          </BarControlDesktop>
        </RealitimeSubscribeStateProvider>
      </MeetVideoWrap>
    </>
  );
}

const VideoControl = styled(VideoInputControl)`
  color: white;
  span:nth-child(2) {
    margin-left: 4rem;
  }
  :hover {
    background: transparent;
  }
`;
const AudioOutput = styled(AudioOutputControl)`
  color: white;
  span:nth-child(2) {
    margin-left: 4rem;
  }
`;
const AudioInput = styled(AudioInputControl)`
  color: white;
  span:nth-child(2) {
    margin-left: 4rem;
  }
`;
export const ControlBarWrap = styled.div`
  display: flex;
  width: 32rem;
  justify-content: space-between;
  margin-bottom: unset;
  margin-left: -4rem;
  @media ${MediaQueries.phoneMax} {
    width: 90%;
    justify-content: space-between;
  }
`;
