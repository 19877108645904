import React, { useState } from 'react';
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom';
import { lightTheme, MeetingProvider } from 'amazon-chime-sdk-component-library-react';
import { ThemeProvider } from 'styled-components';
import { Navbar } from 'components/layout';
import { Colors, GlobalStyle } from 'environment';
import {
  NavbarColorContext,
  useEffectOnce,
  useGetBiimsEvent,
  useLogin,
  useScrollTop,
  NavbarUIContext,
} from 'hooks';
import { LoginPage, BiimsPage, EventPage, SelectActionPage } from 'pages';
import { futureDate } from 'helpers';
import { Platform } from './Platform';
import { Main, Container } from './Router.style';
import { PastBiimPage } from 'pages/pastBiims/PastBiimPage';

export enum Routes {
  Root = '/',
  Login = '/login',
  Admin = '/admin',
  PastBiim = '/past-biim/:uuid',
  Event = '/:eventId',
  Recording = '/:eventId/recording',
}

function Router() {
  const [navbarColor, setNavbarColor] = useState(Colors.darkest);
  const [navbarType, setNavbarType] = useState<'dark' | 'colored'>('dark');
  const [eventRuntime, setEventRuntime] = useState('');
  const [videoEnabled, setVideoEnabled] = useState(false);
  const [headCount, setHeadCount] = useState(0);
  const [showHeader, setShowHeader] = useState(false);
  const [showGuestChat, setShowGuestChat] = useState(false);
  const [chatState, setChatState] = useState('Chat');
  const [userTypeState, setUserTypeState] = useState('');

  useScrollTop();

  const [{ data: session }] = useLogin();
  const [, getBiims] = useGetBiimsEvent();

  useEffectOnce(() => {
    getBiims();
  });

  function setRuntime(evRuntime: any, evDate: any) {
    const duration = evRuntime.split(':');
    const seconds = +duration[0] * 60 * 60 + +duration[1] * 60;
    const miliSeconds = seconds * 1000;
    const date = futureDate(evDate, miliSeconds);

    console.log(evRuntime, 'evvvvvv', evDate, date);
    setEventRuntime(date);
  }

  function switchColor(color: 'dark' | 'colored') {
    if (color === 'colored') {
      setNavbarColor('linear-gradient(90deg, #0F1119 0%, #4DC2E6 42%, #FF47B1 100%)');
      setNavbarType('colored');
    } else {
      setNavbarColor(Colors.darkest);
      setNavbarType('dark');
    }
  }

  function showLiveDot(liveDot: boolean) {
    setVideoEnabled(liveDot);
  }

  function setAttendeeCount(attendeeCount: number) {
    setHeadCount(attendeeCount);
  }

  function setIsEnabled(enabled: boolean) {
    setShowHeader(enabled);
  }
  function setGuestView(enabled: boolean) {
    setShowGuestChat(enabled);
  }
  function setGuestChat(chatWindow: string) {
    setChatState(chatWindow);
  }
  function setUserType(chatWindow: string) {
    setUserTypeState(chatWindow);
  }

  return (
    <NavbarColorContext.Provider value={{ color: navbarColor, type: navbarType, switchColor }}>
      <NavbarUIContext.Provider
        value={{
          runtime: eventRuntime,
          setRuntime,
          isVideoEnabled: videoEnabled,
          showLiveDot,
          attendeeCount: headCount,
          setAttendeeCount,
          isEnabled: showHeader,
          setIsEnabled,
          guestView: showGuestChat,
          setGuestView,
          guestChat: chatState,
          setGuestChat,
          userType: userTypeState,
          setUserType,
        }}
      >
        <Container>
          <Navbar />
          <ThemeProvider theme={lightTheme}>
            <MeetingProvider>
              <Switch>
                <Route
                  path={Routes.Admin}
                  element={session?.token ? <BiimsPage /> : <SelectActionPage />}
                />
                <Route path={Routes.Login} element={<LoginPage />} />
                <Route path={Routes.PastBiim} element={<PastBiimPage />} />
                <Route path={Routes.Event} element={<EventPage />} />
                <Route path={Routes.Recording} element={<EventPage />} />
                <Route path={Routes.Root} element={<SelectActionPage />} />
              </Switch>
            </MeetingProvider>
          </ThemeProvider>
        </Container>
      </NavbarUIContext.Provider>
    </NavbarColorContext.Provider>
  );
}

export default function () {
  return (
    <Main>
      <BrowserRouter>
        <Platform>
          <Router />
        </Platform>
        <GlobalStyle />
      </BrowserRouter>
    </Main>
  );
}
