/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateAttendee = /* GraphQL */ `
  subscription OnUpdateAttendee($attendeeId: String) {
    onUpdateAttendee(attendeeId: $attendeeId) {
      attendeeId
      name
      chatPermissions
      meetingPermissions
      createdAt
      updatedAt
    }
  }
`;
