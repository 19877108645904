import React, { useState } from 'react';
import { BaseModal } from '../index';
import { Rating } from 'react-simple-star-rating';
import { Wrapper, Lable, MainWrapper, RTButton, ButtonContainer, Center } from './Ratings.styled';
import { useEventContext, usePostBiimRatings } from 'hooks';
import { Alerts } from 'components/layout';
import { Colors } from 'environment';
import { EndSubtitle } from 'features/event/commonStyles.style';
import { Icon } from '../../icons';

const tooltipArray = ['Terrible', 'Bad', 'Average', 'Great', 'Awesome'];

export interface RatingsProps {
  uuidEvent: string;
}

type Props = RatingsProps;

const Ratings = ({ uuidEvent }: Props) => {
  const { eventData: data } = useEventContext();
  const [, setOpen] = useState(true);
  const [show, setShow] = useState(true);
  const [rating, setRating] = useState({
    performanceRating: 0,
    videoQualityRating: 0,
    audioQualityRating: 0,
  });
  const [comment, setComment] = useState('');
  const [, postBiimRatings] = usePostBiimRatings();

  // Catch Rating value
  const handlePerformanceRating = (rate: number) => {
    setRating({ ...rating, performanceRating: rate });
  };

  const handleVideoQualityRating = (rate: number) => {
    setRating({ ...rating, videoQualityRating: rate });
  };

  const handleAudioQualityRating = (rate: number) => {
    setRating({ ...rating, audioQualityRating: rate });
  };

  const onSubmit = () => {
    const currentPath = window.location.pathname;
    const pathArray = currentPath.split('/');
    const oderId = pathArray[pathArray.length - 1];
    const guestRatings = {
      performanceRating: rating.performanceRating / 20,
      videoQualityRating: rating.videoQualityRating / 20,
      audioQualityRating: rating.audioQualityRating / 20,
      comment: comment,
      oderId: oderId,
    };
    if (
      rating.performanceRating === 0 ||
      rating.videoQualityRating === 0 ||
      rating.audioQualityRating === 0
    ) {
      Alerts.error('All ratings are required');
      setShow(true);
    } else {
      postBiimRatings(uuidEvent, guestRatings);
      setShow(false);
      localStorage.setItem('uuidEvent', uuidEvent);
      Alerts.success('Thank You for taking time to review.');
    }
  };

  const closePreview = () => {
    setOpen(false);
  };

  return (
    <>
      {show && localStorage.getItem('uuidEvent') !== uuidEvent && (
        <BaseModal
          open={true}
          onSubmit={onSubmit}
          onClose={closePreview}
          title={`Your Rating`}
          width={'400px'}
          height={'600px'}
        >
          <MainWrapper>
            <Wrapper>
              {data.creatorTippingProductId &&
                localStorage.getItem(
                  `tip-${data.uuidEvent}-${String(new Date().toLocaleDateString('en-GB'))}`
                ) && (
                  <EndSubtitle style={{ fontSize: 'clamp(2rem,6vw,1.7rem)', display: 'flex' }}>
                    <Icon type={(t) => t.TipIcon} size={3} color="white" />
                    <div
                      style={{
                        marginLeft: '1rem',
                      }}
                    >
                      You have tipped the creator&nbsp;
                      <a
                        href={`${process.env.REACT_APP_BIIM_STORE_URL}/product/${
                          data.creatorTippingProductId
                        }/?tipAmount=${localStorage.getItem(
                          `tip-${data.uuidEvent}-${String(new Date().toLocaleDateString('en-GB'))}`
                        )}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: `${Colors.pink}`,
                          textDecoration: 'none',
                          fontSize: 'clamp(2rem,6vw,1.7rem)',
                        }}
                      >
                        click here
                      </a>
                      &nbsp;to proceed with payment !
                    </div>
                  </EndSubtitle>
                )}
            </Wrapper>
            <Wrapper>
              <Lable>Performance</Lable>
              <Rating
                tooltipArray={tooltipArray}
                onClick={handlePerformanceRating}
                ratingValue={rating.performanceRating}
                size={17}
                transition
                showTooltip
                tooltipDefaultText={''}
                tooltipClassName={'rating-tooltip-biim'}
                tooltipStyle={{
                  backgroundColor: 'rgb(28 30 37)',
                  marginLeft: '-81px',
                  padding: '0px',
                }}
              />
            </Wrapper>
            <Wrapper>
              <Lable>Video</Lable>
              <Rating
                tooltipArray={tooltipArray}
                onClick={handleVideoQualityRating}
                showTooltip
                ratingValue={rating.videoQualityRating}
                size={17}
                tooltipDefaultText={''}
                tooltipClassName={'rating-tooltip-biim'}
                tooltipStyle={{
                  backgroundColor: 'rgb(28 30 37)',
                  marginLeft: '-81px',
                  padding: '0px',
                }}
              />
            </Wrapper>
            <Wrapper>
              <Lable>Audio</Lable>
              <Rating
                tooltipArray={tooltipArray}
                onClick={handleAudioQualityRating}
                showTooltip
                ratingValue={rating.audioQualityRating}
                size={17}
                tooltipDefaultText={''}
                tooltipClassName={'rating-tooltip-biim'}
                tooltipStyle={{
                  backgroundColor: 'rgb(28 30 37)',
                  marginLeft: '-81px',
                  padding: '0px',
                }}
              />
            </Wrapper>
            <Wrapper>
              <Lable style={{ marginBottom: '1rem' }}>Comment</Lable>
              <textarea
                name="comment"
                placeholder="comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                rows={3}
                style={{
                  width: '100%',
                  borderRadius: '1rem',
                  backgroundColor: 'rgba(15,17,25,0.7)',
                  color: '#ffffff',
                  border: '1px solid #000000',
                  padding: '1rem',
                  fontSize: '1.5rem',
                }}
              />
            </Wrapper>
          </MainWrapper>
          <ButtonContainer>
            <RTButton fontSize={1.5} fontWeight="normal" width={10} onClick={onSubmit}>
              Submit
            </RTButton>
          </ButtonContainer>
        </BaseModal>
      )}
    </>
  );
};

export default Ratings;
