import { EventBiim } from 'biim-api';
import { useCallback } from 'react';

import { useDispatch, useSelector } from 'hooks';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils';

import { ActionType, getLiveBiim, selectLiveBiim } from 'store/events';

export function useGetLiveBiim(): OperationResult<EventBiim, (eventUuid: string) => void> {
	const dispatch = useDispatch();
	const [{ loading, error }] = useActivity(ActionType.GET_LIVE_BIIM);

	const data = useSelector((state) => selectLiveBiim(state.event));

	const handler = useCallback(
		(eventUuid: string) => {
			dispatch(getLiveBiim(eventUuid));
		},
		[dispatch]
	);

	return [{ loading, error, data }, handler];
}
