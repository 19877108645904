import { Alerts } from 'components/layout';
import { Router } from 'router';
import { AppStateProvider } from './AppStateProvider';

import { Store } from './Store';
import { Sockets } from './Sockets';
import '../network';

import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

export function App() {
  return (
    <Store>
      <Sockets>
        <Alerts>
          <AppStateProvider>
            <Router />
          </AppStateProvider>
        </Alerts>
      </Sockets>
    </Store>
  );
}
