import { EventStatus } from 'biim-api';
import { useEffect, useState } from 'react';

import { useEffectOnce, useEventContext, useEventStatusContext, useSocketsContext } from 'hooks';

import { GuestAfterEvent, GuestBeforeEventManager, GuestLiveEvent, GuestDeviceSetup } from '../guest';
import { EventManagerContainer } from '../commonStyles.style';
import { GuestMeetEvent } from '../guest/GuestMeetEvent';

export function GuestEventManager() {
	const [socketEventStatus, setSocketEventStatus] = useState(EventStatus.Before);

	const { eventData: data } = useEventContext();
	const { socket } = useSocketsContext();
	const { status } = useEventStatusContext();

	useEffectOnce(() => {
		if (data.status === EventStatus.LiveInProgress)
			setSocketEventStatus(EventStatus.LiveInProgress);
		if (data.status === EventStatus.MeetInProgress)
			setSocketEventStatus(EventStatus.MeetInProgress);
	});

	useEffect(() => {
		socket.on('event_updated', ({ uuidEvent, status }) => {
			if (status === EventStatus.Before && data.uuidEvent === uuidEvent) {
				setSocketEventStatus(status);
			}
			if (status === EventStatus.LiveInProgress && data.uuidEvent === uuidEvent) {
				setSocketEventStatus(status);
			}
			if (status === EventStatus.MeetInProgress && data.uuidEvent === uuidEvent) {
				setSocketEventStatus(status);
			}
		});

		return () => {
			socket.off('event_updated', (args) => {
				console.log({ args });
			});
		};
	}, [socket, data]);

	function getEventForGuest() {
		switch (status) {
			case EventStatus.Before:
				return <GuestBeforeEventManager status={socketEventStatus} />;
			case EventStatus.LiveInProgress:
				return <GuestLiveEvent />;
			case EventStatus.DeviceSetup:
				return <GuestDeviceSetup />;
			case EventStatus.MeetInProgress:
				return <GuestMeetEvent />;
			case EventStatus.Finished:
				return <GuestAfterEvent />;
			case EventStatus.Cancelled:
				return <GuestAfterEvent />;
			default:
				<div />;
		}
	}

	return <EventManagerContainer>{getEventForGuest()}</EventManagerContainer>;
}
