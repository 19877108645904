import styled, { css } from 'styled-components/macro';
import { LayoutStyleProps } from 'types';

interface Props extends LayoutStyleProps {
	$disabled: boolean;
	$focused: boolean;
	$minimal: boolean;
	$open: boolean;
	$invalid: boolean;
}

export const Toggle = styled.div<Props>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${({ $minimal }) => ($minimal ? 'blue' : 'black')};
	border: 0.1rem solid
		${({ $focused, $minimal, $invalid }) =>
			$minimal ? 'blue' : $focused ? 'gray' : $invalid ? 'red' : 'white'};
	border-radius: 2.4rem;
	color: ${({ $minimal }) => ($minimal ? 'black' : 'blue')};
	cursor: pointer;
	font-family: 'Comfortaa', sans-serif;
	font-size: 1.6rem;
	font-weight: ${({ $minimal }) => $minimal && 600};
	height: ${({ $minimal }) => ($minimal ? '4rem' : '4.8rem')};
	margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
	margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
	margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
	margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
	outline: none;
	padding-left: ${({ $minimal }) => ($minimal ? '1.6rem' : '2.4rem')};
	transition: background 0.2s, border-color 0.2s, color 0.2s;
	width: ${({ $minimal, width = 30 }) =>
		$minimal
			? 'auto'
			: width !== undefined
			? typeof width === 'string'
				? width
				: `${width}rem`
			: 'auto'};

	svg {
		transform: ${({ $open }) => $open && 'rotate(180deg)'};
		transition: transform 0.3s;

		path {
			fill: ${({ $minimal }) => ($minimal ? 'blue' : 'white')};
			transition: fill 0.2s;
		}
	}

	:focus,
	:hover {
		background: ${({ $minimal }) => $minimal && 'gray'};
		border-color: ${({ $disabled, $minimal }) =>
			$disabled ? 'blue' : $minimal ? 'gray' : 'black'};
		color: ${({ $minimal }) => $minimal && 'gray'};

		svg {
			path {
				fill: ${({ $minimal }) => $minimal && 'gray'};
			}
		}
	}

	${({ $disabled }) =>
		$disabled &&
		css`
			cursor: not-allowed;
			opacity: 0.25;
		`}
`;

export const TextToggle = styled.span<Props>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* background-color: ${({ $minimal }) => ($minimal ? 'blue' : 'black')};	 */
	color: ${({ $minimal }) => ($minimal ? 'white' : 'white')};
	cursor: pointer;
	font-family: 'Comfortaa', sans-serif;
	font-size: ${({ $minimal }) => ($minimal ? '1.2rem' : '1.6rem')};
	font-weight: ${({ $minimal }) => $minimal && 600};
	margin-top: ${({ margin }) => (margin?.top ? `${margin.top}rem` : undefined)};
	margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}rem` : undefined)};
	margin-left: ${({ margin }) => (margin?.left ? `${margin.left}rem` : undefined)};
	margin-right: ${({ margin }) => (margin?.right ? `${margin.right}rem` : undefined)};
	outline: none;
	transition: background 0.2s, border-color 0.2s, color 0.2s;
	width: ${({ $minimal, width = 30 }) =>
		$minimal
			? 'auto'
			: width !== undefined
			? typeof width === 'string'
				? width
				: `${width}rem`
			: 'auto'};

	svg {
		transform: ${({ $open }) => $open && 'rotate(180deg)'};
		transition: transform 0.3s;
		margin-left: 1rem;

		path {
			fill: ${({ $minimal }) => ($minimal ? 'white' : 'white')};
			transition: fill 0.2s;
		}
	}

	:focus,
	:hover {
		color: ${({ $minimal }) => $minimal && 'white'};

		svg {
			path {
				fill: ${({ $minimal }) => $minimal && 'white'};
			}
		}
	}

	${({ $disabled }) =>
		$disabled &&
		css`
			cursor: not-allowed;
			opacity: 0.25;
		`}
`;
