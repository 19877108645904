export interface Picture {
  id: number;
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;
  src: string;
  name: string;
  alt: string;
}

export enum EventStatus {
  Before = 1,
  Cancelled = 2,
  LiveInProgress = 3,
  Finished = 4,
  MeetInProgress = 5,
  DeviceSetup = 6,
}

export enum EventType {
  Live = 1,
  Meet = 2,
  LiveMeet = 3,
}

export interface Biim {
  id: number;
  uuidEvent: string;
  wooProductId: number;
  permalink: string;
  title: string;
  description: string;
  images: Picture[];
  price: number;
  eventType: EventType;
  stockInitial: number;
  stockRemaining: number;
  relatedProducts: number[];
  availabilityDate: string;
  runningTime: string;
  creatorEmail: string;
  moderatorEmail: string;
  status: EventStatus;
  wooCreatorId: string;
  creatorName: string;
  creatorImage: string;
}

export interface EventBiim extends Biim {
  uuidGuest: any;
  userType: string;
  userName: string;
  creatorYoutubePlaylistId: string;
  creatorSpotifyPlaylistId: string;
  creatorWaitingRoomBackgroundMusicTrackUrl: string;
  creatorThankYouVideoUrl: string;
  creatorThankYouMessage: string;
  creatorTippingProductId: string;
}

export interface AdminBiim extends Biim {
  uuidGuest: string;
  uuidCreator: string;
  uuidModerator: string;
}

export interface Ratings {
  performanceRating: number;
  videoQualityRating: number;
  audioQualityRating: number;
  comment: string;
  oderId: string;
}
