import { EventStatus, EventType } from 'biim-api';
import {
  AudioOutputControl,
  ControlBarButton,
  MeetingStatus,
  Phone,
  useMeetingManager,
  useMeetingStatus,
  DeviceLabelTriggerStatus,
  useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import { ChatView, RealitimeSubscribeStateProvider, Reactions, Notify } from 'features/chat';
import {
  useEffectOnce,
  useEventContext,
  useEventStatusContext,
  useNavbarColorContext,
  useNavigation,
  useUpdateLiveBiimAttendeeId,
  useNavbarUIContext,
  useMediaQuery,
} from 'hooks';
import { BarControlDesktop, Video, MeetVideoWrap, ControlBarWrapLive } from './Guest.styles';
import NoPermissionMarquee from '../../../components/widgets/Device/NoPermission';
import { LeaveModal } from '../leave';
import { WINDOW_METHODS } from 'consts';
import styled from 'styled-components';
import { GuestAfterEvent, GuestTransitEvent } from '.';
import FullScreen from '../../fullScreen/Fullscreen';
import { Icon } from '../../../components/icons';
import { MediaQueries } from '../../../environment';

export function GuestLiveEvent() {
  const [transit, setTransit] = useState(true);
  const [hideChat, setHideChat] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {
    setRuntime,
    showLiveDot,
    setIsEnabled,
    setAttendeeCount,
    setGuestView,
    guestView,
    setGuestChat,
    setUserType,
  } = useNavbarUIContext();
  const toggleModal = () => setShowModal(!showModal);
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  setAttendeeCount(attendees.length);
  const { eventData: data } = useEventContext();
  const [guestName] = useState(data.userName !== undefined ? data.userName : data.userType);

  const meetingManager = useMeetingManager();
  const { navigate, routes } = useNavigation();
  const meetingStatus = useMeetingStatus();
  const { type, switchColor } = useNavbarColorContext();
  const { status, setStatus } = useEventStatusContext();

  const [{ data: attendeeId }] = useUpdateLiveBiimAttendeeId();
  const [mediaPermissions, setMediaPermissions] = useState(DeviceLabelTriggerStatus.UNTRIGGERED);
  const [viewAfterEvent, setViewAfterEvent] = useState(false);
  const isPhone = useMediaQuery(MediaQueries.phone);
  const checkMedia = async () => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(
      () => {
        // microphone available
        setMediaPermissions(DeviceLabelTriggerStatus.GRANTED);
      },
      () => {
        // microphone not available
        setMediaPermissions(DeviceLabelTriggerStatus.DENIED);
      }
    );
  };

  async function getMedia() {
    checkMedia();
    if (meetingManager && meetingManager.audioVideo) {
      // Suppress devices
      meetingManager.audioVideo.setDeviceLabelTrigger(() => Promise.resolve(new MediaStream()));

      meetingManager.audioVideo.start();

      // Invoke devices
      meetingManager.audioVideo.setDeviceLabelTrigger(
        async () => await navigator.mediaDevices.getUserMedia({ audio: true })
      );
      //  const audioInputDevices = await meetingManager.audioVideo.listAudioInputDevices();
      const audioOutputDevices = await meetingManager.audioVideo.listAudioOutputDevices();

      console.log(audioOutputDevices, 'audio');
      //await meetingManager.audioVideo.startAudioInput(audioInputDevices[0].deviceId);
      if (audioOutputDevices[0] !== undefined)
        await meetingManager.audioVideo.chooseAudioOutput(audioOutputDevices[0].deviceId);
      meetingManager.audioVideo.realtimeMuteLocalAudio();
    }
  }

  useEffectOnce(() => {
    if (type === 'colored') switchColor('dark');
    setRuntime(data.runningTime, data.availabilityDate);
    setUserType(data.userType);
    setIsEnabled(true);
    showLiveDot(true);
    getMedia();

    const changeLayout = setTimeout(() => {
      setTransit(false);
    }, 3000);

    if (data.eventType !== EventType.LiveMeet && status !== EventStatus.MeetInProgress)
      return () => {
        clearTimeout(changeLayout);
        meetingManager.leave();
      };
  });

  useEffect(() => {
    if (meetingStatus === MeetingStatus.JoinedFromAnotherDevice) {
      navigate(routes.root);
    }
  });

  const hangUpButtonProps = {
    icon: <Phone />,
    onClick: () => toggleModal(),
    label: 'End',
  };

  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to exit?';
      return;
    };

    window.addEventListener(WINDOW_METHODS.BEFORE_UNLOAD, handleTabClose);

    return () => {
      window.removeEventListener(WINDOW_METHODS.BEFORE_UNLOAD, handleTabClose);
      window.location.reload();
    };
  }, []);

  if (transit) return <GuestTransitEvent title="You will enter a Live Event" />;

  return (
    <>
      {!viewAfterEvent ? (
        <>
          {mediaPermissions === DeviceLabelTriggerStatus.DENIED && <NoPermissionMarquee />}

          <MeetVideoWrap id={data.userType}>
            <RealitimeSubscribeStateProvider>
              <FullScreen>
                <LeaveModal
                  name={guestName}
                  open={showModal}
                  onClose={toggleModal}
                  onSubmit={() => setViewAfterEvent(true)}
                />
                <ChatView
                  attendeeId={attendeeId.attendeeId}
                  attendeeName={guestName}
                  userType={data.userType}
                  eventType={data.eventType}
                  visible={hideChat}
                  onChangeVisibility={setHideChat}
                  onChangeStatus={setStatus}
                />
                {location.pathname.split('/')[2] !== 'recording' && (
                  <Notify name={guestName} meetingStatus={meetingStatus} />
                )}
                <Video layout="standard" chatVisibility={hideChat} isMobile={isMobile} />
                {location.pathname.split('/')[2] !== 'recording' && (
                  <BarControlDesktop
                    chatVisibility={hideChat}
                    layout="undocked-vertical"
                    showLabels={false}
                  >
                    <Reactions />
                    <ControlBarWrapLive>
                      <AudioOutput />
                      <span data-tip="End Call">
                        <ControlBarButtonS {...hangUpButtonProps} />
                      </span>
                      <ReactTooltip place="top" type="dark" effect="solid" />
                    </ControlBarWrapLive>
                    <span
                      data-tip="Send Tip"
                      style={{
                        height: '2.5rem',
                        position: 'absolute',
                        right: isPhone ? '1rem' : '6rem',
                        top: isPhone ? '1rem' : '',
                        bottom: isPhone ? 'inherit' : '2.1rem',
                        cursor: 'pointer',
                      }}
                    >
                      <button
                        style={{
                          cursor: 'pointer',
                          height: '2.5rem',
                          width: '2.5rem',
                          background: 'none',
                          border: 'none',
                        }}
                        onClick={() => {
                          setGuestView(!guestView);
                          setGuestChat('Tips');
                        }}
                      >
                        <Icon type={(t) => t.TipIcon} size={3} color="white" />
                      </button>
                    </span>
                  </BarControlDesktop>
                )}
              </FullScreen>
            </RealitimeSubscribeStateProvider>
          </MeetVideoWrap>
        </>
      ) : (
        <GuestAfterEvent />
      )}
    </>
  );
}

const AudioOutput = styled(AudioOutputControl)`
  color: white;
  span:nth-child(2) {
    margin-left: 4rem;
  }
`;
export const ControlBarButtonS = styled(ControlBarButton)`
  margin-left: 4rem;
  .ch-control-bar-item-iconButton {
    margin-right: 0;
  }
  .ch-icon {
    background-color: red;
  }
`;
