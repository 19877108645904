import React from 'react';

import { Colors } from 'environment';

import { BaseButton, ButtonProps } from './BaseButton';

export function SearchButton(props: ButtonProps) {
	return (
		<BaseButton
			{...props}
			backgroundColor={Colors.white}
			color={Colors.darkest}
			boxShadow="0px 2px 6px rgba(0, 0, 0, 0.25), inset 0px 1px 10px rgba(255, 255, 255, 0.85)"
			fontSize={1.8}
		/>
	);
}
