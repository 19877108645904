import { EventStatus, EventType, factory } from 'biim-api';
import {
  useRosterState,
  MeetingStatus,
  useLocalVideo,
  useMeetingManager,
  useMeetingStatus,
  DeviceLabelTriggerStatus,
  Badge,
} from 'amazon-chime-sdk-component-library-react';
import Switch from 'react-switch';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { ChatView, RealitimeSubscribeStateProvider, ReactionsCounter, Notify } from 'features/chat';
import {
  useEventContext,
  useModal,
  useNavbarColorContext,
  useEffectOnce,
  useEventStatusContext,
  useNavbarUIContext,
} from 'hooks';
import { endMeeting } from 'utils/api';

import {
  AudioInputControlCustom,
  AudioOutputControlCustom,
  BarControlDesktop,
  ControlBarButtonCustom,
  HangUpPhone,
  Video,
  VideoInputControlCustom,
  MeetVideoWrap,
  ControlBarWrap,
  PermissionContainer,
} from './Creator.styles';
import { UtilitiesBar } from '../commonStyles.style';
import { ConfirmationModal } from '../modal';
import NoPermissionMarquee from '../../../components/widgets/Device/NoPermission';
import styled from 'styled-components';
import { PermissionWrapperMeet } from '../guest/Guest.styles';
import { WINDOW_METHODS } from 'consts';
import { LeaveModal } from '../leave';
import { Icons } from './icons';
import axios from 'axios';
import FullScreen from 'features/fullScreen/Fullscreen';
import { Storage } from 'helpers';

export function CreatorLiveEvent() {
  const [hideChat, setHideChat] = useState(false);
  const meetingManager = useMeetingManager();
  const eventstatus = useMeetingStatus();
  const { isVideoEnabled, toggleVideo } = useLocalVideo(); //if needed can use toggleVideo
  const { roster } = useRosterState();
  const [{ open: showEndModal, onClose: onCloseEndModal }, toggleEndModal] = useModal();
  const [{ open: showSwitchModal, onClose: onCloseSwitchModal }, toggleSwitchModal] = useModal();
  const { pathname } = useLocation();
  const { type, switchColor } = useNavbarColorContext();
  const { setRuntime, showLiveDot, setAttendeeCount, setIsEnabled, setUserType } =
    useNavbarUIContext();
  const { eventData: data } = useEventContext();
  const { status } = useEventStatusContext();
  const [mediaPermissions, setMediaPermissions] = useState(DeviceLabelTriggerStatus.UNTRIGGERED);
  const [taskId, setTaskId] = useState('');

  const api = factory();

  const stopRecording = async () => {
    await axios.post(
      `${process.env.REACT_APP_RECORDING_URL}?recordingAction=stop&taskId=${taskId}`,
      {}
    );
    Storage.remove((k) => k.RecordingTaskId);
  };

  useEffectOnce(() => {
    if (type === 'colored') switchColor('dark');
    setRuntime(data.runningTime, data.availabilityDate);
    setUserType(data.userType);
    setIsEnabled(true);
    checkMedia();
    if (data.eventType !== EventType.LiveMeet && status !== EventStatus.MeetInProgress)
      return () => {
        meetingManager.leave();
      };
  });

  async function toggleVideoStream() {
    if (!isVideoEnabled) {
      await toggleVideo();
    }
  }

  const checkMedia = async () => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(
      () => {
        // microphone available
        setMediaPermissions(DeviceLabelTriggerStatus.GRANTED);
      },
      () => {
        // microphone not available
        setMediaPermissions(DeviceLabelTriggerStatus.DENIED);
      }
    );
  };

  useEffect(() => {
    toggleVideoStream();
    if (data.eventType !== EventType.LiveMeet && status !== EventStatus.MeetInProgress) {
      if (eventstatus === MeetingStatus.Left) {
        try {
          unexpectedEnd();
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [eventstatus]);

  const uuid = pathname.split('/')[1];

  async function unexpectedEnd() {
    await api.event().updateStatusEvent(uuid, EventStatus.Finished);
    meetingManager.leave();
  }

  const clickedEndMeeting = async () => {
    stopRecording();
    const meetingId = meetingManager.meetingId;
    if (meetingId) {
      await endMeeting(meetingId);
      await meetingManager.leave();
      await api.event().updateStatusEvent(uuid, EventStatus.Finished);
    }
  };

  const clickedSwitchMeeting = async () => {
    await api.event().updateStatusEvent(uuid, EventStatus.MeetInProgress);
    toggleSwitchModal();
  };

  const hangUpButtonProps = {
    icon: <HangUpPhone />,
    css: `{ background: black; }`,
    onClick: () => toggleEndModal(),
    label: 'End Call',
  };

  const attendees = Object.values(roster);
  showLiveDot(isVideoEnabled);
  setAttendeeCount(attendees.length);

  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to exit?';
      return;
    };
    window.addEventListener(WINDOW_METHODS.BEFORE_UNLOAD, handleTabClose);

    return () => {
      window.removeEventListener(WINDOW_METHODS.BEFORE_UNLOAD, handleTabClose);
      Storage.remove((k) => k.RecordingStopped);
      window.location.reload();
    };
  }, []);

  return (
    <>
      {mediaPermissions === DeviceLabelTriggerStatus.DENIED && <NoPermissionMarquee />}
      <MeetVideoWrap style={{}} id={data.userType}>
        <ConfirmationModal
          title="Are you sure you wish to switch to meet?"
          open={showSwitchModal}
          onClose={onCloseSwitchModal}
          onSubmit={clickedSwitchMeeting}
        />
        <RealitimeSubscribeStateProvider>
          <FullScreen>
            <LeaveModal
              name={data.creatorName.split(' ')[0]}
              title={'Once you leave, you can not join again. Are you sure you wish to end??'}
              open={showEndModal}
              onClose={onCloseEndModal}
              onSubmit={clickedEndMeeting}
            />
            <ChatView
              attendeeId={data.creatorName}
              attendeeName={data.creatorName}
              userType={data.userType}
              eventType={data.eventType}
              visible={hideChat}
              onChangeVisibility={setHideChat}
            />

            <Video chatVisibility={hideChat} isMobile={isMobile} />

            <UtilitiesBar>
              <ReactionsCounter />
            </UtilitiesBar>

            <Notify name={data.creatorName.split(' ')[0]} meetingStatus={eventstatus} />

            <BarControlDesktop
              chatVisibility={hideChat}
              layout="undocked-vertical"
              showLabels={false}
            >
              {/*<Reactions />*/}
              <ControlBarWrap>
                {data.eventType === EventType.LiveMeet && (
                  <span data-tip="Switch to Meet & Greet">
                    <Switch
                      offColor="#f84bb2"
                      onColor="#0ff"
                      height={35}
                      width={70}
                      handleDiameter={30}
                      activeBoxShadow="0px 0px 1px 2px #fffc35"
                      onChange={toggleSwitchModal}
                      checked={false}
                      uncheckedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        >
                          <img src={Icons.MeetIcon} height={'28px'} />
                        </div>
                      }
                    />
                  </span>
                )}
                {mediaPermissions === DeviceLabelTriggerStatus.DENIED ? (
                  <PermissionContainer>
                    <PermissionWrapperMeet>
                      <AudioInputControlCustom />
                      <VideoInputControlCustom />
                    </PermissionWrapperMeet>
                    <AudioOutputControlCustom />
                  </PermissionContainer>
                ) : (
                  <>
                    <AudioInputControlCustom />
                    <VideoInputControlCustom />
                    <AudioOutputControlCustom />
                  </>
                )}
                <span data-tip="End Call">
                  <ControlBarButtonCustomS {...hangUpButtonProps} />
                </span>
                <ReactTooltip place="top" type="dark" effect="solid" className="toolTip" />
              </ControlBarWrap>
            </BarControlDesktop>
          </FullScreen>
        </RealitimeSubscribeStateProvider>
      </MeetVideoWrap>
    </>
  );
}

export const ControlBarButtonCustomS = styled(ControlBarButtonCustom)`
  margin-left: 1rem;
  .ch-control-bar-item-iconButton {
    margin-right: 0;
  }
`;
export const BaggageCustom = styled(Badge)`
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.4rem;
`;
