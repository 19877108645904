import React from 'react';
import moment from 'moment-timezone';
import { 
	EUROPE_LONDON, 
	DO_MMMM_YYYY_24H, 
	DO_MMMM_YYYY_12H, 
	DD_MM_YYYY,
	TIME_ZONE 
} from 'consts';

export function isElement(node: React.ReactNode, name: string) {
	if (!node) return false;
	if (!React.isValidElement(node)) return false;
	if (typeof node.type === 'string') return false;

	return node.type.name === name;
}

export function truncateText(text: string, limit: number) {
	return text.length > limit ? `${text.slice(0, limit - 3)}...` : text;
}

export function addLeadingZeros(value: string) {
	value = String(value);
	while (value.length < 2) {
		value = '0' + value;
	}
	return value;
}

export function convertDate(date: string) {
	const day = new Date(date).getDate();
	const month = new Date(date).getMonth() + 1;
	const year = new Date(date).getFullYear();
	const hours = new Date(date).getHours();
	const minutes = new Date(date).getMinutes();

	return { year, month, day, hours, minutes };
}

export function getQueueDate(date: string) {
	const { year, month, day, hours, minutes } = convertDate(date);

	return `${addLeadingZeros(String(day))}.${addLeadingZeros(
		String(month)
	)}.${year} ${addLeadingZeros(String(hours))}:${addLeadingZeros(String(minutes))}`;
}

export function getDate(date: string) {
	const { year, month, day, hours, minutes } = convertDate(date);

	return `${addLeadingZeros(String(day))}.${addLeadingZeros(
		String(month)
	)}.${year} / ${addLeadingZeros(String(hours))}:${addLeadingZeros(String(minutes))}`;
}

export function formatDate(date: string) {
	const DATE_OBJECT = {
		EUROPE_LONDON_DATE: moment(date).tz(EUROPE_LONDON).format(),
		DO_MMMM_YYYY_24H: moment(date).tz(EUROPE_LONDON).format(DO_MMMM_YYYY_24H),
		DO_MMMM_YYYY_12H: moment(date).tz(EUROPE_LONDON).format(DO_MMMM_YYYY_12H),
		EUROPE_NOW: moment().tz(EUROPE_LONDON).format(),
		DD_MM_YYYY: moment(date).tz(EUROPE_LONDON).format(DD_MM_YYYY),
	}
	return DATE_OBJECT
}

export function  futureDate(date: any, miliSeconds: any) {
	const TIME_ZONE_NOW = moment.tz(new Date(), EUROPE_LONDON).format('z');
	const TIME_ZONE_FUTURE = moment(date).tz(EUROPE_LONDON).format('z');
	let futureDateValue: any  = '';

	if(TIME_ZONE_NOW === TIME_ZONE.BST) {
		futureDateValue = TIME_ZONE_FUTURE === TIME_ZONE.BST ? 
			moment(date).tz(EUROPE_LONDON).add(miliSeconds, 'milliseconds').format() :
			moment(date).tz(EUROPE_LONDON).subtract(1, 'hours').add(miliSeconds, 'milliseconds').format()
	}

	if(TIME_ZONE_NOW === TIME_ZONE.GMT) {
		futureDateValue = TIME_ZONE_FUTURE === TIME_ZONE.GMT ? 
			moment(date).tz(TIME_ZONE.GMT).add(miliSeconds, 'milliseconds').format() :
			moment(date).tz(EUROPE_LONDON).add(1, 'hours').add(miliSeconds, 'milliseconds').format()
	}

	return futureDateValue
}