import { Icon } from 'components/icons';
import React from 'react';

import { LayoutStyleProps } from 'types';

import { TextToggle } from './DropdownToggle.style';

interface Props extends LayoutStyleProps {
	disabled?: boolean;
	focused: boolean;
	minimal?: boolean;
	invalid?: boolean;
	open: boolean;
	title?: string;
	onToggle: () => void;
}

export const DropdownTextToggle = React.forwardRef<HTMLDivElement, Props>(function (
	{
		invalid = false,
		disabled = false,
		focused,
		margin,
		minimal = true,
		open,
		title,
		width,
		onToggle,
	},
	ref
) {
	return (
		<TextToggle
			ref={ref}
			$invalid={invalid}
			$disabled={disabled}
			$focused={focused}
			margin={margin}
			$minimal={minimal}
			$open={open}
			tabIndex={0}
			width={width}
			onClick={onToggle}
		>
			{title}
			<Icon type={(t) => t.ArrowDown} color="white" size={2} />
		</TextToggle>
	);
});
