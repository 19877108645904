import { EventStatus, EventType, factory } from 'biim-api';
import {
  AudioInputControl,
  AudioOutputControl,
  useRosterState,
  MeetingStatus,
  useLocalVideo,
  useMeetingManager,
  useMeetingStatus,
  VideoInputControl,
  DeviceLabelTriggerStatus,
} from 'amazon-chime-sdk-component-library-react';
import Switch from 'react-switch';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { ChatView, RealitimeSubscribeStateProvider, Notify, ReactionsCounter } from 'features/chat';
import {
  useEventContext,
  useModal,
  useNavbarColorContext,
  useEffectOnce,
  useNavbarUIContext,
  useEventStatusContext,
} from 'hooks';
import { endMeeting } from 'utils/api';

import {
  BarControlDesktop,
  ControlBarButtonCustom,
  HangUpPhone,
  MeetVideo,
  MeetVideoWrap,
  PermissionContainer,
  ControlBarWrap,
} from './Creator.styles';
import NoPermissionMarquee from '../../../components/widgets/Device/NoPermission';
import { PermissionWrapperMeet } from '../guest/Guest.styles';
import { UtilitiesBar } from '../commonStyles.style';
import { WINDOW_METHODS } from 'consts';
import { LeaveModal } from '../leave';
import { ConfirmationModal } from '../modal';
import { Icons } from './icons';
import FullScreen from 'features/fullScreen/Fullscreen';

export function CreatorMeetEvent() {
  const [hideChat, setHideChat] = useState(false);
  const meetingManager = useMeetingManager();
  const eventstatus = useMeetingStatus();
  // eslint-disable-next-line
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const [{ open: showEndModal, onClose: onCloseEndModal }, toggleEndModal] = useModal();
  const [{ open: showSwitchModal, onClose: onCloseSwitchModal }, toggleSwitchModal] = useModal();
  const { pathname } = useLocation();
  const { type, switchColor } = useNavbarColorContext();
  const { eventData: data } = useEventContext();
  const { setRuntime, showLiveDot, setAttendeeCount, setIsEnabled, setUserType } =
    useNavbarUIContext();
  const { status } = useEventStatusContext();
  const [mediaPermissions, setMediaPermissions] = useState(DeviceLabelTriggerStatus.UNTRIGGERED);
  const { roster } = useRosterState();
  const api = factory();

  useEffectOnce(() => {
    if (type === 'colored') switchColor('dark');
    setRuntime(data.runningTime, data.availabilityDate);
    setUserType(data.userType);
    setIsEnabled(true);
    if (data.eventType !== EventType.LiveMeet && status !== EventStatus.LiveInProgress)
      return () => {
        meetingManager.leave();
      };
  });

  const checkMedia = async () => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(
      () => {
        // microphone available
        setMediaPermissions(DeviceLabelTriggerStatus.GRANTED);
      },
      () => {
        // microphone not available
        setMediaPermissions(DeviceLabelTriggerStatus.DENIED);
      }
    );
  };

  useEffect(() => {
    async function toggle() {
      if (eventstatus === MeetingStatus.Succeeded) {
        await toggleVideo();
      }
    }
    // toggleMuteMic();
    toggle();
    checkMedia();
    if (eventstatus === MeetingStatus.Left) {
      try {
        unexpectedEnd();
      } catch (e) {
        console.error(e);
      }
    }
  }, [eventstatus]);

  const uuid = pathname.split('/')[1];

  async function unexpectedEnd() {
    await api.event().updateStatusEvent(uuid, EventStatus.Finished);
    meetingManager.leave();
  }

  const clickedEndMeeting = async () => {
    const meetingId = meetingManager.meetingId;
    if (meetingId) {
      await endMeeting(meetingId);
      await meetingManager.leave();
      await api.event().updateStatusEvent(uuid, EventStatus.Finished);
    }
  };

  const clickedSwitchMeeting = async () => {
    await api.event().updateStatusEvent(uuid, EventStatus.LiveInProgress);
    toggleSwitchModal();
  };

  const hangUpButtonProps = {
    icon: <HangUpPhone />,
    css: `{ background: black; }`,
    onClick: () => toggleEndModal(),
    label: 'End',
  };

  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to exit?';
      return;
    };

    window.addEventListener(WINDOW_METHODS.BEFORE_UNLOAD, handleTabClose);

    return () => {
      window.removeEventListener(WINDOW_METHODS.BEFORE_UNLOAD, handleTabClose);
      window.location.reload();
    };
  }, []);

  const attendees = Object.values(roster);
  showLiveDot(isVideoEnabled);
  setAttendeeCount(attendees.length);

  return (
    <>
      {mediaPermissions === DeviceLabelTriggerStatus.DENIED && <NoPermissionMarquee />}
      <MeetVideoWrap style={{}} id={data.userType}>
        <RealitimeSubscribeStateProvider>
          <FullScreen>
            <ConfirmationModal
              title="Are you sure you wish to switch to live?"
              open={showSwitchModal}
              onClose={onCloseSwitchModal}
              onSubmit={clickedSwitchMeeting}
            />
            <LeaveModal
              name={data.creatorName.split(' ')[0]}
              title="Once you leave, you can not join again. Are you sure you wish to end??"
              open={showEndModal}
              onClose={onCloseEndModal}
              onSubmit={clickedEndMeeting}
            />

            <ChatView
              attendeeId={data.creatorName}
              attendeeName={data.creatorName}
              userType={data.userType}
              eventType={data.eventType}
              visible={hideChat}
              onChangeVisibility={setHideChat}
            />

            <MeetVideo layout="featured" chatVisibility={hideChat} isMobile={isMobile} />

            <UtilitiesBar>
              <ReactionsCounter />
            </UtilitiesBar>

            <Notify name={data.creatorName.split(' ')[0]} meetingStatus={eventstatus} />

            <BarControlDesktop
              chatVisibility={hideChat}
              layout="undocked-vertical"
              showLabels={false}
            >
              {/*<Reactions />*/}
              <ControlBarWrap>
                {/* {data.eventType === EventType.LiveMeet && (
                  <span data-tip="Switch to Live">
                    <Switch
                      disabled={true}
                      offColor="#f84bb2"
                      onColor="#1bd1ed"
                      height={35}
                      width={70}
                      handleDiameter={30}
                      activeBoxShadow="0px 0px 1px 2px #fffc35"
                      onChange={toggleSwitchModal}
                      checked={true}
                      checkedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        >
                          <img src={Icons.LiveIcon} height={'28px'} />
                        </div>
                      }
                    />
                  </span>
                )} */}
                {mediaPermissions === DeviceLabelTriggerStatus.DENIED ? (
                  <PermissionContainer>
                    <PermissionWrapperMeet>
                      <AudioControl />
                      <VideoControl />
                    </PermissionWrapperMeet>
                    <AudioOutput />
                  </PermissionContainer>
                ) : (
                  <>
                    <AudioControl />
                    <VideoControl />
                    <AudioOutput />
                  </>
                )}
                <span data-tip="End Call">
                  <ControlBarButtonCustomS {...hangUpButtonProps} />
                </span>
                <ReactTooltip place="top" type="dark" effect="solid" className="toolTip" />
              </ControlBarWrap>
            </BarControlDesktop>
          </FullScreen>
        </RealitimeSubscribeStateProvider>
      </MeetVideoWrap>
    </>
  );
}
const AudioOutput = styled(AudioOutputControl)`
  color: white;
  span:nth-child(2) {
    margin-left: 4rem;
  }
`;
const AudioControl = styled(AudioInputControl)`
  color: white;
  span:nth-child(2) {
    margin-left: 4rem;
  }
`;
const VideoControl = styled(VideoInputControl)`
  color: white;
  span:nth-child(2) {
    margin-left: 4rem;
  }
`;

export const ControlBarButtonCustomS = styled(ControlBarButtonCustom)`
  margin-left: 1rem;
  .ch-control-bar-item-iconButton {
    margin-right: 0;
  }
`;
