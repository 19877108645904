import { createSelector } from 'reselect';
import { State } from './types';

export const selectAllBiims = createSelector(
  (state: State) => state.biims,
  (biims) => biims
);

export const selectAllAdminBiims = createSelector(
  (state: State) => state.adminBiims,
  (biims) => biims
);

export const selectAllSearchedAdminBiims = createSelector(
  (state: State, searchText: string) =>
    state.adminBiims.filter((biim) =>
      `${biim.title} ${biim.creatorName}`.toLowerCase().includes(searchText.toLowerCase())
    ),
  (biim) => biim
);

export const selectRelatedBiims = createSelector(
  (state: State, relatedProducts: number[]) =>
    relatedProducts.map((related) => state.biims.find((biim) => biim.wooProductId === related)),
  (related) => related
);

export const selectLiveBiim = createSelector(
  (state: State) => state.live.biim,
  (biim) => biim
);

export const selectAttendeeId = createSelector(
  (state: State) => state.live.attendeeId,
  (attendeeId) => attendeeId
);
